/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

/* eslint eqeqeq: 0 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SAGA_ACTIONS } from '../../../config/config'
import { toastr } from 'react-redux-toastr'
import RouteMap from '../routeMap'
import AskQuestion from '../askQuestion'
import moment from 'moment'
import { Carousel, CarouselItem, CarouselControl, Card, CardBody, ModalHeader, Modal, ModalBody } from 'reactstrap';
import { phoneFormat, rawMask } from '../../../common/form-elements/elements'
import Tracking from './tracking'
import BidModal from './bidModal'
import InfoModal from '../../infoModal'
import PublicChat from '../publicChat'
import { transport_option, setOther, is_item } from '../../../config/fieldsConfig'

class UpdateBid extends Component {
   state = {
      shipment: {},
      bidderId: '',
      bid_status: '',
      isChecked: '',
      formValues: {},
      transport_option,
      questionModal: false,
      activeIndex: 0,
      sliderImage: [],
      tracking: false,
      bidFormModal: false,
      rebid: false,
      infoModal: false,
      bid: {},
      otherDetails: [],
      catIds: [5, 6, 14, 15, 16],
      isWatching: false
   }

   componentDidMount() {
      this.editBidDetails()
   }

   getData = () => {
      return new Promise((resolve, reject) => {
         this.props.dispatch({
            type: SAGA_ACTIONS.CARRIER.EDIT_BID,
            payload: { id: this.props.match.params.id },
            callbackSuccess: res => {
               return resolve(res)
            },
            callbackError: err => {
               return reject(false)
            }
         })
      })
   }


   editBidDetails = () => {
      this.getData().then(res => {
         let tempFormValues = {
            bids: res.data.data.bids,
            expiration_type: res.data.data.expiration_type,
            note: res.data.data.note,
         }
         if (res.data.data.expiration_type == 2) {
            tempFormValues['expiration_date'] = new Date(res.data.data.expiration_date)
         }

         tempFormValues['pickup_within_day'] = res.data.data.pickup_within_day
         tempFormValues['delivery_within_day'] = res.data.data.delivery_within_day

         let tempBid = { ...res.data.data }
         let sliderImage = res.data.data && res.data.data.shipment && res.data.data.shipment.media_size_vehicle_small_image_url
         let other_details = res.data.data && res.data.data.shipment ? res.data.data.shipment.other_details : {}
         let shipment = res.data.data.shipment
         delete shipment[res.data.data.shipment.media_size_vehicle_small_image_url]
         delete shipment[res.data.data.shipment.other_details]
         delete tempBid['shipment']

         this.setState({
            shipment,
            sliderImage,
            bidderId: res.data.data.user_id,
            bid_status: res.data.data.status,
            bid: tempBid,
            ids: { shipment_id: res.data.data.shipment_id, bid_id: res.data.data.id },
            formValues: tempFormValues,
            isWatching: (res.data.data.shipment.watch) ? true : false
         })
         if (other_details) {
            setOther(other_details, res.data.data.category_id).then(res => {
               this.setState({ otherDetails: res })
            })
         }
      })
   }

   submitBid = (values) => {
      let payload = { ...values }

      if (values.expiration_date) {
         payload['expiration_date'] = moment(values.expiration_date).format('YYYY-MM-DD')
      }
      if (!this.state.rebid) {
         payload['id'] = this.state.ids.bid_id
      }
      payload['shipment_id'] = this.state.ids.shipment_id

      this.props.dispatch({
         type: this.state.rebid ? SAGA_ACTIONS.CARRIER.SUBMIT_BID : SAGA_ACTIONS.CARRIER.UPDATE_BID,
         payload,
         callbackSuccess: res => {
            toastr.success('Success', res.data.msg);
            this.setState({ bidFormModal: false })
            if (this.state.rebid) {
               setTimeout(() => {
                  this.props.history.replace(`/update-bid/${res.data.data.unique_id}`)
                  this.editBidDetails()
               }, 1000);
            }
            else {
               this.editBidDetails()
            }
         },
         callbackError: err => {
            toastr.error('Error', err.data.msg)
         }
      })
   }

   handleChange = (e) => {
      this.setState({
         formValues: { ...this.state.formValues, [e.target.name]: e.target.value }
      })
   }

   handleChecked = (e) => {
      this.setState({
         isChecked: e.target.value, formValues: { ...this.state.formValues, expiration: '' }
      })
   }

   questionModal = (type) => {
      this.setState({ questionModal: type })
   }

   formModal = (type) => {
      this.setState({ bidFormModal: type })
   }

   trackingModal = (value) => {
      this.setState({ tracking: value })
   }

   publicChatModal = () => {
      this.setState({ publicChatModal: !this.state.publicChatModal })
   }

   next = () => {
      const nextIndex = this.state.activeIndex === this.state.sliderImage.length - 1 ? 0 : this.state.activeIndex + 1;
      this.setState({ activeIndex: nextIndex });
   }

   previous = () => {
      const nextIndex = this.state.activeIndex === 0 ? this.state.sliderImage.length - 1 : this.state.activeIndex - 1;
      this.setState({ activeIndex: nextIndex });
   }

   acceptRejectQuote = (e) => {
      this.props.dispatch({
         type: SAGA_ACTIONS.CARRIER.ACC_REJ_QUOTE,
         payload: { shipment_id: this.state.shipment.id, status: e.target.value },
         callbackSuccess: res => {
            toastr.success('Success', res.data.msg)
         },
         callbackError: err => {
            toastr.error('Error', err.data.msg)
         }
      })
   }

   setBidType = (type) => {
      this.setState({
         bidFormModal: true,
         rebid: type
      })
   }

   goToTracking = () => {
      this.props.history.push(`/tracking/${this.state.shipment.unique_id}`)
   }

   getInfo = (type) => {
      this.setState({ infoModal: type })
   }

   showSlideImage = () => {
      this.setState({ showSlideModal: true })
   }

   saveWatch = (id) => {
      this.props.dispatch({
         type: SAGA_ACTIONS.CARRIER.SAVE_WATCH,
         payload: { shipment_id: id },
         callbackSuccess: res => {
            toastr.success('Success', res.data.msg)
            this.setState({ isWatching: true })
         },
         callbackError: err => {
            toastr.error('Error', err.data.msg)
         }
      })
   }

   render() {
      const { shipment, bid } = this.state
      return (
         <div>
            <div className="log-bg" />
            <div className="box-log pt-3">
               <div className="accordion custom-accordion" id="accordionExample">
                  <h5 className='white-text'>{shipment.category && shipment.category.name}</h5>
                  <div className="info-btn text-right">
                     <button className="btn text-white watch pull-left" style={this.state.isWatching ? { cursor: 'not-allowed' } : {}} onClick={() => (!this.state.isWatching) ? this.saveWatch(shipment.id) : ''} type="button">{!this.state.isWatching ? 'Watch Shipment' : 'Watched'} {this.state.isWatching ? <img src="/images/tick.png" alt="Tick" /> : ''}</button>
                     <button className="btn white-text" onClick={() => this.getInfo(true)} type="button"><i className="fa fa-question-circle" /></button></div>
                  <div className="card">
                     <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                           <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              Routing &amp; Locations
                           </button>
                        </h2>
                     </div>

                     <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                           {(bid.status != 0 && (this.state.bid_status != 2 && this.state.bidderId == this.props.userId)) &&
                              <div className="row-bids">
                                 <span className="orange-text">Origin: </span> <h5>{shipment.pickup_address}</h5>
                                 <span className="orange-text">Origin Type: </span><h5>{shipment.pickup && shipment.pickup.location}</h5>
                                 <span className="orange-text">Zip Code: </span><h5>{shipment.origin_zipcode}</h5>
                              </div>
                           }
                           {(bid.status == 0 || (this.state.bid_status == 2 && this.state.bidderId == this.props.userId)) &&
                              <div className="row-bids">
                                 <span className="orange-text">Origin: </span><h5>{shipment.pickup_city}, {shipment.pickup_state}</h5>
                                 <span className="orange-text">Origin Type: </span><h5>{shipment.pickup && shipment.pickup.location}</h5>
                                 {/* Zip Code: <h5>{shipment.origin_zipcode}</h5> */}
                              </div>
                           }
                           <hr />
                        
                           {(bid.status != 0 && (this.state.bid_status != 2 && this.state.bidderId == this.props.userId)) &&
                              <div className="row-bids">
                                 <span className="blue-text">Destination: </span><h5>{shipment.destination_address}</h5>
                                 <span className="blue-text">Destination Type: </span><h5>{shipment.destination && shipment.destination.location}</h5>
                                 <span className="blue-text">Zip Code: </span><h5>{shipment.delivery_zipcode}</h5>
                              </div>
                           }
                           {(bid.status == 0 || (this.state.bid_status == 2 && this.state.bidderId == this.props.userId)) &&
                              <div className="row-bids">
                                 <span className="blue-text">Destination: </span><h5>{shipment.destination_city}, {shipment.destination_state}</h5>
                                 <span className="blue-text">Destination Type: </span><h5>{shipment.destination && shipment.destination.location}</h5>
                                 {/* Zip Code: <h5>{shipment.delivery_zipcode}</h5> */}
                              </div>
                           }
                           <hr />
                           <div className="row-bids">
                              Distance: <h5>{shipment.distance} mi</h5>
                           </div>
                           <div className="row-bids">
                              <div className="route-map-wrap">
                                 <RouteMap origin={{ lat: shipment.pickup_address_latitude, lng: shipment.pickup_address_longitude }}
                                    destination={{ lat: shipment.destination_address_latitude, lng: shipment.destination_address_longitude }}
                                    delivery_zipcode={{ delivery_zipcode: shipment.delivery_zipcode }}
                                    origin_zipcode={{ origin_zipcode: shipment.origin_zipcode }}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Shipment Listing
                           </button>
                        </h2>
                     </div>
                     <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div className="card-body">
                           <div className="row-bids">
                              <p><strong>Shipment Id:</strong> #{shipment.master_code}</p>
                           </div>
                           <div className="row-bids">
                              <p><strong>Date Posted:</strong> {moment(shipment.created_at).format('MM-DD-YYYY')}</p>
                           </div>
                           <div className="row-bids">
                              <p><strong>Ready Date:</strong> {moment(shipment.ready_dt).format('MM-DD-YYYY')}</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-header" id="headingThree">
                        <h2 className="mb-0">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              Shipment Pictures
                           </button>
                        </h2>
                     </div>
                     <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                        <div className="card-body">
                           <div className="row-bids slider">
                              {this.state.sliderImage.length ?
                                 <Carousel
                                    activeIndex={this.state.activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                    interval={false}
                                 >
                                    {this.state.sliderImage.length ? this.state.sliderImage.map((slide, i) => <CarouselItem
                                       key={i}
                                    >
                                       <div className="carousel-image-wrap" onClick={this.showSlideImage}>
                                          <img src={slide} alt={'carousel-image'} />
                                       </div>
                                    </CarouselItem>) : ''}
                                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                 </Carousel> : <strong>No image to show</strong>}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-header" id="headingFour">
                        <h2 className="mb-0">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                              Shipment Information : {' '}
                              {bid.status == 1 && shipment.status == 4 && <span>Booked</span>}
                              {bid.status == 1 && shipment.status == 5 && <span> Completed</span>}
                              {shipment.status == 6 && <span>Cancelled</span>}
                              {bid.status == 1 && shipment.status == 7 && <span>Issue Arised</span>}
                              {(shipment.status == 3 && moment(shipment.expire_on).diff(new Date(), 'days') < 0) && <span>Expired</span>}
                           </button>
                        </h2>
                     </div>
                     <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                        <div className="card-body">
                           <div className="row-bids-other">
                              {this.state.otherDetails.length ? this.state.otherDetails.map((item, i) => <div key={i}>
                                 <h5><span>{item.name}</span> <em>{item.value}</em></h5>
                              </div>) : null}
                              {shipment.dimension_weight && shipment.dimension_weight ? shipment.dimension_weight.map((obj, i) => <div key={i}>
                                 {shipment.category && (this.state.catIds.includes(shipment.category.id)) && <h5><span className="font-weight-bold">Package #{i + 1}</span> <em></em></h5>}
                                 {obj.title && <h5><span>Title</span><em>{obj.title}</em></h5>}
                                 {obj.item && <h5><span>Is your item</span><em>{is_item.find(temp => temp.id == obj.item).name}</em></h5>}
                                 {obj.length && <h5><span>Length (inches)</span><em>{obj.length}</em></h5>}
                                 {obj.width && <h5><span>Width (inches)</span><em>{obj.width}</em></h5>}
                                 {obj.height && <h5><span>Height (inches)</span><em>{obj.height}</em></h5>}
                                 {obj.weight && <h5><span>Weight (lbs)</span><em>{obj.weight}</em></h5>}
                                 {obj.inner_item_count && <h5><span>How many items?</span><em>{obj.inner_item_count}</em></h5>}
                                 {obj.note && <h5><span>Note</span><em>{obj.note}</em></h5>}
                              </div>) : null}
                           </div>
                           <div className="d-flex flex-column text-left">
                              <span className="spl-ins">Special Instructions:</span>
                              <h6 className="mt-1">{shipment.special_instruction ? shipment.special_instruction : 'No special instructions'}</h6>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               {shipment.shipment_type == 1 && this.state.bid_status == 2 && this.state.bidderId == this.props.userId &&
                  <div className="className=step02 trans-box transbox-margin text-white remove-min-max text-center">
                     <h5>Bid Rejected</h5>
                  </div>}

               {((shipment.status === 1 || shipment.status === 2) && moment(shipment.expire_on).diff(new Date(), 'days') >= 0) && shipment.shipment_type == 2 &&
                  <div className="className=step02 trans-box transbox-margin text-white remove-min-max">
                     {this.state.shipment.shipment_type == 1 && <h4>Accept Rate: <span> ${shipment.shipment_payment.quotes_cost}</span></h4>}
                     <h3 className="text-center">
                        {this.state.bid_status == 2 && this.state.bidderId == this.props.userId && 'Bid Rejected'}</h3>
                     <div className="text-center">
                        {this.state.bid_status == 0 && <button className="btn btn-primary" onClick={() => this.setBidType(false)} style={{ width: '40%' }}>Update Bid</button>}
                        {this.state.bid_status == 2 && <button className="btn btn-primary" onClick={() => this.setBidType(true)} style={{ width: '40%' }}>Rebid</button>}
                     </div>
                  </div>}
               {shipment.shipment_type == 2 && <div className="btns-wrapper-bid-details">
                  <button className="btn btn-motherload w-100" onClick={() => this.questionModal(true)}>Ask a Question</button>
               </div>}

               {(shipment.status == 4 || shipment.status == 5 || shipment.status == 7) && (this.state.bidderId == this.props.userId) && bid.status == 1 &&
                  <div className="current-bids-wrap">
                     <Card style={{ margin: '15px 0 15px 0' }}>
                        <CardBody>
                           <div className="bid-wrapper d-flex justify-content-between">
                              <h6>Bid</h6>
                              <h6>${shipment.shipment_type == 1 ? (parseFloat(shipment.shipment_payment.quotes_cost) + parseFloat(shipment.shipment_payment.upcharge_cost)).toFixed(2) : bid.bids}</h6>
                           </div>
                           <div className="bid-wrapper d-flex justify-content-between">
                              <div>
                                 <h6>Name</h6>
                                 <h6>{`${shipment.user.first_name} ${shipment.user.last_name}`}</h6>
                              </div>
                              <img src={shipment.user && shipment.user.media_size_image_url ? shipment.user.media_size_image_url : '/images/avatar.png'} height={'60px'} alt="transporter profile pic" />
                           </div>
                           <div className="bid-wrapper d-flex justify-content-between">
                              <h6>Phone</h6>
                              <a href={`tel:${shipment.user.phone}`} className="phone-link">{rawMask(shipment.user.phone.toString(), shipment.user.country_details.phone_code_format)}</a>
                           </div>
                           <div className="bid-wrapper">
                              <h6>Email</h6>
                              <h6>{shipment.user.email}</h6>
                           </div>
                           {bid.expiration_type == 1 && <div className="bid-wrapper d-flex justify-content-between">
                              <h6>Cancel at load expiration</h6>
                              <h6>{moment(shipment.expire_on).format('MM-DD-YYYY')}</h6>
                           </div>}
                           {bid.expiration_type == 2 && <div>
                              <div className="bid-wrapper d-flex justify-content-between">
                                 <h6>Cancel on date</h6>
                                 <h6>{moment(bid.expiration_date).format('MM-DD-YYYY')}</h6>
                              </div>
                              <div className="bid-wrapper d-flex justify-content-between">
                                 <h6>Pickup within (days)</h6>
                                 <h6>{bid.pickup_within_day}</h6>
                              </div>
                              <div className="bid-wrapper d-flex justify-content-between">
                                 <h6>Deliver within (days)</h6>
                                 <h6>{bid.delivery_within_day}</h6>
                              </div>
                           </div>}

                           {bid.shipment_type == 1 && <div className="bid-wrapper d-flex justify-content-between">
                              <h6>Accept Rate:</h6>
                              <h6>$ {shipment.shipment_payment.quotes_cost}</h6>
                           </div>}
                           <div className="row mt-2">
                              {shipment.shipment_type == 1 && <div className="col-sm-6">
                                 <button className="btn btn-motherload w-100 mt-1" onClick={() => this.questionModal(true)}>Ask a Question</button>
                              </div>}
                              <div className={shipment.shipment_type == 1 ? "col-sm-6" : "col-sm-12"}>
                                 <button className="btn btn-dark w-100 mt-1" onClick={this.goToTracking} style={{ width: 'auto' }}>
                                    {(shipment.status == 5 || shipment.status == 7) ? 'Delivered' : 'Update Shipment Location'}
                                 </button>
                              </div>
                              {this.state.tracking && <Tracking close={() => this.trackingModal(false)} initialValues={{ radio1: '0' }} />}
                           </div>
                        </CardBody>
                     </Card>
                  </div>}
            </div>
            {this.state.bidFormModal && <BidModal isOpen={true} initialValues={this.state.formValues} close={() => this.setState({ bidFormModal: false })} onSubmit={this.submitBid} />}
            <InfoModal isOpen={this.state.infoModal} close={() => this.getInfo(false)} id={3} />
            {this.state.questionModal && <AskQuestion close={() => this.questionModal(false)} ids={this.state.ids} />}
            {this.state.publicChatModal && <PublicChat close={() => this.publicChatModal(false)} ids={this.state.ids} />}
            {this.state.showSlideModal && <SliderImg img={shipment && shipment.media_size_vehicle_image_url[this.state.activeIndex]} open={true} close={() => this.setState({ showSlideModal: false })} />}
         </div>
      )
   }
}

const mapStateToProps = state => {
   return {
      userId: state.user && state.user.user.id
   }
}

export default connect(mapStateToProps)(UpdateBid)

const SliderImg = (props) =>
   <Modal isOpen={props.open} centered size="md">
      <ModalHeader toggle={() => props.close()} className="text-white modal-cross">
         &nbsp;
   </ModalHeader>
      <ModalBody className="text-center">
         <div className="modal-img-wrap">
            <img src={props.img} />
         </div>
      </ModalBody>
   </Modal>