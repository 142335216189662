/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import { ACTIONS } from '../../config/config';

const initialState = {
   filterValues: {},
   filter: false,
   tabName: ''
};

const shipmentReducer = (state = initialState, action) => {
   switch (action.type) {
      case ACTIONS.CARRIER.TOGGLE_FILTER:
         return {
            ...state,
            filter: action.payload
         }
      case ACTIONS.CARRIER.FILTER_VALUES:
         return {
            ...state,
            filterValues: action.payload
         }
      case ACTIONS.CARRIER.SET_TAB_NAME:
         return {
            ...state,
            tabName: action.payload
         }
      default:
         return state
   }
}


export default shipmentReducer