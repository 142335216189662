/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Page404 = () => (
   <Redirect to={{pathname: '/login'}} />
)

export default Page404