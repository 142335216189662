/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import NotFound from './pages/_common/page404';
import Login from './pages/auth/login';
import ForgotPassword from './pages/auth/forget-password';
import ResetpasswordForm from './pages/auth/reset-password'

import ScrollIntoView from './common/ScrollIntoView'
import Loader from './common/loader/loader';
import BidDetails from './pages/dashboards/carrier dashboard/bidDetails';
import ShipmentDetails from './pages/dashboards/shipper dashboard/shipmentDetails';
import PrivateRoute from './common/hoc/ProtectedRoute';
import PublicRoute from './common/hoc/PublicRoute';
import ContactUs from './pages/user/contactUs'
import UpdateBid from './pages/dashboards/carrier dashboard/updateBid';
import Tracking from './pages/dashboards/carrier dashboard/tracking';
import MyFeedback from './pages/dashboards/carrier dashboard/myFeedback';
import ShipRegistration from './pages/auth/registration/shipper-registration';
import CarRegForm from './pages/auth/registration/registration';
import Notifications from './pages/dashboards/carrier dashboard/notifcations';

const RegistrationMain = Loadable({
   loader: () => import('./pages/auth/registration/registrationMain'),
   loading: () => ''
});

const ShipmentMain = Loadable({
   loader: () => import('./pages/shipment-form/shipmentMain'),
   loading: () => ''
});

const EditProfileMain = Loadable({
   loader: () => import('./pages/user/edit-profile/editProfileMain'),
   loading: () => ''
});

const Dashboard = Loadable({
   loader: () => import('./pages/dashboards/dashboard'),
   loading: () => ''
});

const OrderHistory = Loadable({
   loader: () => import('./pages/user/orderHistory'),
   loading: () => ''
});

const UploadShipments = Loadable({
   loader: () => import('./pages/uploadShipments'),
   loading: () => ''
});

class App extends Component {

   render() {
      let authed = this.props.authenticated
      let user = this.props.user
      return (
         <Router>
            <div className="App height-full">
               <Loader />
               <>
                  <ScrollIntoView>
                     <Switch>
                        <Route exact path="/" component={Login} />
                        <Route path="/login" component={PublicRoute(Login)} />
                        <Route path="/dashboard" component={PrivateRoute(Dashboard, 'dashboard')} />
                        <Route path="/edit-profile" render={() => authed ? <EditProfileMain /> : <Redirect to="/login" />} />
                        {user && user.user_type == 2 && <Route path="/create-shipment" component={PrivateRoute(ShipmentMain, 'Add Shipment')} />}
                        {user && user.user_type == 1 && <Route path="/bid-details/:id" component={PrivateRoute(BidDetails, 'Bid Details')} />}
                        {user && user.user_type == 1 && <Route path="/bid-details/:id/:shipment_title" component={PrivateRoute(BidDetails, 'Bid Details')} />}
                        {user && user.user_type == 2 && <Route path="/shipment-details/:id" component={PrivateRoute(ShipmentDetails, 'Shipment Details')} />}
                        {user && user.user_type == 2 && <Route path="/shipment-details/:id/:shipment_title" component={PrivateRoute(ShipmentDetails, 'Shipment Details')} />}
                        {user && user.user_type == 2 && <Route path="/order-history" component={PrivateRoute(OrderHistory, 'Order History')} />}
                        {user && user.user_type == 2 && <Route path="/upload-shipments" component={PrivateRoute(UploadShipments, 'Upload Shipments')} />}
                        <Route path="/contact-us" component={PrivateRoute(ContactUs, 'Contact Us')} />
                        {user && user.user_type == 1 && <Route path="/update-bid/:id" component={PrivateRoute(UpdateBid, 'Update Bid')} />}
                        {user && user.user_type == 1 && <Route path="/update-bid/:id/:shipment_title" component={PrivateRoute(UpdateBid, 'Update Bid')} />}
                        {user && user.user_type == 1 && <Route path="/feedback" component={PrivateRoute(MyFeedback, 'Feedback')} />}
                        {user && user.user_type == 1 && <Route path="/notifications" component={PrivateRoute(Notifications, 'Notifications')} />}
                        <Route path="/tracking/:id" component={PrivateRoute(Tracking, 'Tracking')} />
                        <Route path="/forgot-password" component={PublicRoute(ForgotPassword)} />
                        <Route path="/register" component={PublicRoute(RegistrationMain)} />
                        <Route path="/shipper-register" component={PublicRoute(ShipRegistration)} />
                        <Route path="/transporter-register" component={PublicRoute(CarRegForm)} />
                        
                        <Route path="/reset-password/:token" component={PublicRoute(ResetpasswordForm)} />
                        <Route path="*" component={NotFound} />
                     </Switch>
                  </ScrollIntoView>
               </>
            </div>
         </Router>
      )
   }
}
const mapStateToProps = state => {
   return {
      user: state.user && state.user.user,
      authenticated: state.user.isAuthenticated,
      isProfileComplete: state.user.isProfileComplete,
   };
};

export default connect(mapStateToProps)(App);
