/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { InputField } from '../../common/form-elements/elements';
import { required, emailValidator } from '../../common/validators/form-validators';
import { SAGA_ACTIONS } from '../../config/config';
import { toastr } from 'react-redux-toastr';

class Forgetpassword extends Component {

   handleForget = (values) => {
      return new Promise((resolve, reject) => {
         this.props.dispatch({
            type: SAGA_ACTIONS.USER.FORGOT_PASSWORD,
            payload: values,
            callbackSuccess: res => { 
               resolve(toastr.success('Email Sent', res.data.msg))
            },
            callbackError: err => {
               reject(toastr.error('Error', err.data.msg))
            }
         })
      })
   }

   render() {
      const { handleSubmit, submitting } = this.props;
      return (
         <div>
            <div className="log-bg" />
            <div className="container-fluid">
               <h1 className="logo"><img src="images/logo.png" alt="Logo" /></h1>
               <div className="box-log">
                  <div className="title-wrap ">
                     <h2 className="title">Forgot Password</h2>
                  </div>
                  <form onSubmit={handleSubmit(this.handleForget)} name="forgetpasswordForm">
                     <div className="form-group">
                        <label>Email</label>
                        <Field type="email" name="email" component={InputField} validate={[required, emailValidator]} />
                     </div>
                     <div className="form-group forgot-pass-wrap">
                        <div className="row">
                           <div className="col-12 text-right">
                              <button type="submit" className="btn btn-primary" disabled={submitting}>{submitting ? 'Submitting' : 'Submit'}</button>
                           </div>
                        </div>
                     </div>
                     <div className="register-link">
                        Have an account? <Link to="/login">Login</Link>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      )
   }
}

const ForgetpasswordForm = reduxForm({
   form: 'forgetpasswordForm',
   destroyOnUnmount: true,
   enableReinitialize: true,
})(Forgetpassword);

const mapStateToProps = state => {
   return {};
};
export default connect(mapStateToProps)(ForgetpasswordForm);