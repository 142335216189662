/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

export const STORAGE = 'eh-ui-auth-key';
//export const BASE_URL = 'http://127.0.0.1:8000/api/v1';
// export const BASE_URL = 'https://motherload-backend.glohtesting.com/api/v1';
export const BASE_URL = 'https://motherload-backend.dreamztesting.com/api/v1';
//export const BASE_URL = 'http://10.25.28.19/motherload/public/api/v1';
//export const BASE_URL = "https://my-backend.motherload.app/api/v1"

export const APIS = {
   USER: {
      LOGIN: `${BASE_URL}/login`,
      ME: `${BASE_URL}/profile-details`,
      LOGOUT: `${BASE_URL}/logout`,
      REGISTER: {
         REGISTER_USER: `${BASE_URL}/register`,
         GET_OTP: `${BASE_URL}/register-otp`,
         GET_COUNTRY: `${BASE_URL}/get-country`,
         GET_STATE: `${BASE_URL}/get-state`,
         GET_CITY: `${BASE_URL}/get-city`,
      },
      FORGOT_PASSWORD: `${BASE_URL}/forgot-password`,
      RESET_PASSWORD: `${BASE_URL}/reset-password`,
      CHECK_RESET_LINK: `${BASE_URL}/verify-token`,
      UPDATE_PROFILE: `${BASE_URL}/profile-details`,
      CARDS: {
         CARD_LIST: `${BASE_URL}/card-list`,
         CARD_DETAILS: `${BASE_URL}/card-details?id=82`,
         CARD_SAVE: `${BASE_URL}/card-save`,
         CARD_REMOVE: `${BASE_URL}/card-remove`,
         CARD_UPDATE: `${BASE_URL}/card-update`,
      },
      ORDER_HISTORY: `${BASE_URL}/my-order-history`,
      CONTACT_US: `${BASE_URL}/contact-request`,
      TRACKING_LIST: `${BASE_URL}/shipment-tracking-list`,
      SEND_TRACKING_ADD: `${BASE_URL}/shipment-tracking-add`,
      CONFIRM_STATUS: `${BASE_URL}/shipper-tracking-update`,
      FEEDBACK_SEND: `${BASE_URL}/feedback-send`,
      ACTIVE_PLAN: `${BASE_URL}/fetch-active-plan`,
      PURCHASE_PLAN: `${BASE_URL}/purchase-plan`
   },
   BOOKING: {
      LOCATION_TYPES: `${BASE_URL}/locations`,
      TIME_FRAMES: `${BASE_URL}/timeframes`,
      CATEGORIES: `${BASE_URL}/categories`,
      VEHICLE_YEAR: `${BASE_URL}/get-vehicle-year`,
      VEHICLE_MAKE: `${BASE_URL}/get-vehicle-make`,
      VEHICLE_MODEL: `${BASE_URL}/get-vehicle-model`,
      CREATE_SHIPMENT: `${BASE_URL}/shipment-post`,
      GET_COUNTRY_PHONE_FORMAT: `${BASE_URL}/get-country-phone-format`,
   },
   CARRIER: {
      LIST: `${BASE_URL}/shipment-list`,
      AVAILABLE_BIDS: `${BASE_URL}/available-shipment-in-carrier`,
      MY_BIDS: `${BASE_URL}/my-shipment-in-carrier`,
      WATCHED_LISTS: `${BASE_URL}/watched-shipment-list-in-carrier`,
      COMPLETED_BIDS: `${BASE_URL}/completed-shipment-list-in-carrier`,
      REJECTED_BIDS: `${BASE_URL}/rejected-shipment-list-in-carrier`,
      BID_DETAILS: `${BASE_URL}/shipment-details`,
      SUBMIT_BID: `${BASE_URL}/carrier-to-bid`,
      SAVE_WATCH: `${BASE_URL}/save-watch`,
      EDIT_BID: `${BASE_URL}/biding-details`,
      UPDATE_BID: `${BASE_URL}/bid-details-update`,
      ACC_REJ_QUOTE: `${BASE_URL}/carrier-accept-reject-booking`,
      CLUSTER_MAP: {
         AVAILABLE_BIDS: `${BASE_URL}/available-shipment-map-in-carrier`,
         MY_BIDS: `${BASE_URL}/my-shipment-map-in-carrier`,
         WATCHED_LISTS: `${BASE_URL}/watched-shipment-map-in-carrier`,
         COMPLETED_BIDS: `${BASE_URL}/completed-shipment-map-in-carrier`,
         REJECTED_BIDS: `${BASE_URL}/rejected-shipment-map-in-carrier`,
      },
      MULTI_SELECT: {
         SAVE_SETTINGS: `${BASE_URL}/save-user-preferences`,
         GET_SETTINGS: `${BASE_URL}/get-user-preferences`
      },
      SAVE_CARRIER_PREFERENCES: `${BASE_URL}/save-carrier-preferences`,
   },
   SHIPPER: {
      CARRIER_BIDS: `${BASE_URL}/carrier-bids-in-shipper`,
      CURRENT_SHIPMENT: `${BASE_URL}/current-shipment-in-shipper`,
      COMPLETED_SHIPMENTS: `${BASE_URL}/completed-shipment-list-in-shipper`,
      SHIPMENT_DETAILS: `${BASE_URL}/shipment-details`,
      SHIPMENT_BIDS: `${BASE_URL}/shipper-biding-list`,
      ACCEPT_REJECT_BID: `${BASE_URL}/bid-status-change`,
      MILE_QUOTATION: `${BASE_URL}/shipment-mile-quotation`,
      CARRIER_REVIEW: `${BASE_URL}/feedback-list`,
      LOG_INTO_APP: `${BASE_URL}/status-change-for-out-side-app`,
      CANCEL_SHIPMENT: `${BASE_URL}/shipment-cancel`,
      CLUSTER_MAP: {
         CARRIER_BIDS: `${BASE_URL}/carrier-bids-map-in-shipper`,
         CURRENT_SHIPMENT: `${BASE_URL}/current-shipment-map-in-shipper`,
         COMPLETED_SHIPMENTS: `${BASE_URL}/completed-shipment-map-list-in-shipper`,
      },
      GET_TEMP_LOAD_DATA_FROM_CSV: `${BASE_URL}/temp-load-data-from-csv`,
      TEMP_TO_LOAD_CRON:  `${BASE_URL}/temp-to-load-cron`,
   },
   QUESTION: {
      POST_QUESTION: `${BASE_URL}/send-bid-reviews`,
      GET_RESPONSES: `${BASE_URL}/receive-bid-reviews`,
      SEND_COMMON_MSG: `${BASE_URL}/send-common-questions`,
      GET_COMMON_MSG: `${BASE_URL}/fetch-common-questions`,
   },
   INFO: {
      BLOCK_INFO: `${BASE_URL}/block-information`,
      TERMS: `${BASE_URL}/cms-details`,
   }
};

export const ACTIONS = {
   USER: {
      LOGIN: 'ACTION/USER/LOGIN',
      ME: 'ACTION/USER/ME',
      LOGOUT: 'ACTION/USER/LOGOUT',
      REGISTER: {
         REGISTER_USER: 'ACTION/USER/REGISTER/REGISTER_USER',
         STEP: 'ACTION/USER/REGISTER/CHANGE_STEP',
         SET_COUNTRY: 'ACTION/USER/REGISTER/SET_COUNTRY',
         SET_STATE: 'ACTION/USER/REGISTER/SET_STATE',
         SET_CITY: 'ACTION/USER/REGISTER/SET_CITY',
      },
      HAS_CARD: 'ACTION/USER/REGISTER/HAS_CARD',
      FORGOT_PASSWORD: 'ACTION/USER/FORGOT_PASSWORD',
      RESET_PASSWORD: 'ACTION/USER/RESET_PASSWORD'
   },
   LOADER: {
      SHOW_LOADER: 'ACTION/LOADER/SHOW_LOADER',
      HIDE_LOADER: 'ACTION/LOADER/HIDE_LOADER'
   },
   CARRIER: {
      AVAILABLE_BIDS: 'ACTION/CARRIER/AVAILABLE_BIDS',
      TOGGLE_FILTER: 'ACTION/CARRIER/TOGGLE_FILTER',
      FILTER_VALUES: 'ACTION/CARRIER/FILTER_VALUES',
      SET_TAB_NAME: 'ACTION/CARRIER/SET_TAB_NAME',
   }
}

export const SAGA_ACTIONS = {
   USER: {
      LOGIN: 'SAGA_ACTION/USER/LOGIN',
      ME: 'SAGA_ACTION/USER/ME',
      LOGOUT: 'SAGA_ACTION/USER/LOGOUT',
      REGISTER: {
         REGISTER_USER: 'SAGA_ACTION/USER/REGISTER/REGISTER_USER',
         GET_OTP: 'SAGA_ACTION/USER/REGISTER/GET_OTP',
         GET_COUNTRY: 'SAGA_ACTION/USER/REGISTER/GET_COUNTRY',
         GET_STATE: 'SAGA_ACTION/USER/REGISTER/GET_STATE',
         GET_CITY: 'SAGA_ACTION/USER/REGISTER/GET_CITY',
      },
      FORGOT_PASSWORD: 'SAGA_ACTION/USER/FORGOT_PASSWORD',
      RESET_PASSWORD: 'SAGA_ACTION/USER/RESET_PASSWORD',
      CHECK_RESET_LINK: 'SAGA_ACTION/USER/CHECK_RESET_LINK',
      UPDATE_PROFILE: 'SAGA_ACTION/USER/UPDATE_PROFILE',
      CARDS: {
         CARD_LIST: 'SAGA_ACTION/USER/CARDS/CARD_LIST',
         CARD_DETAILS: 'SAGA_ACTION/USER/CARDS/CARD_DETAILS',
         CARD_SAVE: 'SAGA_ACTION/USER/CARDS/CARD_SAVE',
         CARD_REMOVE: 'SAGA_ACTION/USER/CARDS/CARD_REMOVE',
         CARD_UPDATE: 'SAGA_ACTION/USER/CARDS/CARD_UPDATE',
      },
      ORDER_HISTORY: 'SAGA_ACTION/USER/ORDER_HISTORY',
      CONTACT_US: 'SAGA_ACTION/USER/CONTACT_US',
      TRACKING_LIST: 'SAGA_ACTION/USER/TRACKING_LIST',
      SEND_TRACKING_ADD: 'SAGA_ACTION/USER/SEND_TRACKING_ADD',
      CONFIRM_STATUS: 'SAGA_ACTION/USER/CONFIRM_STATUS',
      FEEDBACK_SEND: 'SAGA_ACTION/USER/FEEDBACK_SEND',
      ACTIVE_PLAN: 'SAGA_ACTIONS/USER/ACTIVE_PLAN',
      PURCHASE_PLAN: 'SAGA_ACTIONS/USER/PURCHASE_PLAN'
   },
   BOOKING: {
      LOCATION_TYPES: 'SAGA_ACTION/BOOKING/LOCATION_TYPES',
      TIME_FRAMES: 'SAGA_ACTION/BOOKING/TIME_FRAMES',
      CATEGORIES: 'SAGA_ACTION/BOOKING/CATEGORIES',
      VEHICLE_YEAR: 'SAGA_ACTION/BOOKING/VEHICLE_YEAR',
      VEHICLE_MAKE: 'SAGA_ACTION/BOOKING/VEHICLE_MAKE',
      VEHICLE_MODEL: 'SAGA_ACTION/BOOKING/VEHICLE_MODEL',
      CREATE_SHIPMENT: 'SAGA_ACTION/BOOKING/CREATE_SHIPMENT',
      GET_COUNTRY_PHONE_FORMAT: 'SAGA_ACTION/BOOKING/GET_COUNTRY_PHONE_FORMAT',
   },
   CARRIER: {
      LIST: 'SAGA_ACTION/CARRIER/LIST',
      AVAILABLE_BIDS: 'SAGA_ACTION/CARRIER/AVAILABLE_BIDS',
      MY_BIDS: 'SAGA_ACTION/CARRIER/MY_BIDS',
      WATCHED_LISTS: 'SAGA_ACTION/CARRIER/WATCHED_LISTS',
      COMPLETED_BIDS: 'SAGA_ACTION/CARRIER/COMPLETED_BIDS',
      REJECTED_BIDS: 'SAGA_ACTION/CARRIER/REJECTED_BIDS',
      BID_DETAILS: 'SAGA_ACTION/CARRIER/BID_DETAILS',
      SUBMIT_BID: 'SAGA_ACTION/CARRIER/SUBMIT_BID',
      SAVE_WATCH: 'SAGA_ACTION/CARRIER/SAVE_WATCH',
      EDIT_BID: 'SAGA_ACTION/CARRIER/EDIT_BID',
      UPDATE_BID: 'SAGA_ACTION/CARRIER/UPDATE_BID',
      ACC_REJ_QUOTE: 'SAGA_ACTION/CARRIER/ACC_REJ_QUOTE',
      CLUSTER_MAP: {
         AVAILABLE_BIDS: 'SAGA_ACTION/CARRIER/CLUSTER_MAP/AVAILABLE_BIDS',
         MY_BIDS: 'SAGA_ACTION/CARRIER/CLUSTER_MAP/MY_BIDS',
         WATCHED_LISTS: 'SAGA_ACTION/CARRIER/CLUSTER_MAP/WATCHED_LISTS',
         COMPLETED_BIDS: 'SAGA_ACTION/CARRIER/CLUSTER_MAP/COMPLETED_BIDS',
         REJECTED_BIDS: 'SAGA_ACTION/CARRIER/CLUSTER_MAP/REJECTED_BIDS',
      },
      MULTI_SELECT: {
         SAVE_SETTINGS: 'SAGA_ACTIONS/CARRIER/MULTI_SELECT/SAVE_SETTINGS',
         GET_SETTINGS: 'SAGA_ACTIONS/CARRIER/MULTI_SELECT/GET_SETTINGS'
      },
      SAVE_CARRIER_PREFERENCES:  'SAGA_ACTIONS/CARRIER/SAVE_CARRIER_PREFERENCES',
   },
   SHIPPER: {
      CARRIER_BIDS: 'SAGA_ACTIONS/SHIPPER/CARRIER_BIDS',
      CURRENT_SHIPMENT: 'SAGA_ACTIONS/SHIPPER/CURRENT_SHIPMENT',
      COMPLETED_SHIPMENTS: 'SAGA_ACTIONS/SHIPPER/COMPLETED_SHIPMENTS',
      SHIPMENT_DETAILS: 'SAGA_ACTIONS/SHIPPER/SHIPMENT_DETAILS',
      SHIPMENT_BIDS: 'SAGA_ACTIONS/SHIPPER/SHIPMENT_BIDS',
      ACCEPT_REJECT_BID: 'SAGA_ACTIONS/SHIPPER/ACCEPT_REJECT_BID',
      MILE_QUOTATION: 'SAGA_ACTIONS/SHIPPER/MILE_QUOTATION',
      CARRIER_REVIEW: 'SAGA_ACTIONS/SHIPPER/CARRIER_REVIEW',
      CANCEL_SHIPMENT: 'SAGA_ACTIONS/SHIPPER/CANCEL_SHIPMENT',
      LOG_INTO_APP: 'SAGA_ACTIONS/SHIPPER/LOG_INTO_APP',
      CLUSTER_MAP: {
         CARRIER_BIDS: 'SAGA_ACTIONS/SHIPPER/CLUSTER_MAP/CARRIER_BIDS',
         CURRENT_SHIPMENT: 'SAGA_ACTIONS/SHIPPER/CLUSTER_MAP/CURRENT_SHIPMENT',
         COMPLETED_SHIPMENTS: 'SAGA_ACTIONS/SHIPPER/CLUSTER_MAP/COMPLETED_SHIPMENTS',
      },
      GET_TEMP_LOAD_DATA_FROM_CSV: 'SAGA_ACTION/SHIPPER/GET_TEMP_LOAD_DATA_FROM_CSV',
      TEMP_TO_LOAD_CRON: 'SAGA_ACTION/SHIPPER/TEMP_TO_LOAD_CRON',
   },
   QUESTION: {
      POST_QUESTION: 'SAGA_ACTIONS/QUESTION/POST_QUESTION',
      GET_RESPONSES: 'SAGA_ACTIONS/QUESTION/GET_RESPONSES',
      SEND_COMMON_MSG: 'SAGA_ACTIONS/QUESTION/SEND_COMMON_MSG',
      GET_COMMON_MSG: 'SAGA_ACTIONS/QUESTION/GET_COMMON_MSG'
   },
   INFO: {
      BLOCK_INFO: 'SAGA_ACTIONS/INFO/BLOCK_INFO',
      TERMS: 'SAGA_ACTIONS/INFO/TERMS',
   }
}

export const TAB_NAMES = {
   SHIPPER: {
      CARRIER_BIDS_TAB: 'shipper-carrier-bids-tab',
      CURRENT_SHIPMENTS_TAB: 'shipper-current-tab',
      COMPLETED_TAB: 'shipper-completed-tab',
   },
   CARRIER: {
      AVAILABLE_TAB: 'carrier-avaialable-tab',
      MY_TAB: 'carrier-my-tab',
      WATCHED_TAB: 'carrier-watched-tab',
      CARRIER_COMPLETE_TAB: 'carrier-complete-tab',
      REJECTED_TAB: 'carrier-rejected-tab'
   }
}