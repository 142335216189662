/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React, { Component } from 'react'
import { Form, FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux'
import { reduxForm, Field, change } from 'redux-form'
import { InputField, DatepickerField, RadioField } from '../../../common/form-elements/elements'
import { required, requiredDate, numbersGrt1, numbersOnly, noteCheckForForm } from '../../../common/validators/form-validators'

class BidModalForm extends Component {
   state = {
      checkValue: ''
   }

   setChecked = (e) => {
      this.setState({ checkValue: e.target.value })
   }

   componentDidMount() {
      if (this.props.initialValues) {
         this.setState({ checkValue: this.props.initialValues.expiration_type })
      }
      else {
         this.props.dispatch(change('bidForm', 'expiration_type', '1'))
      }
   }

   render() {
      const { handleSubmit } = this.props
      return (
         <Modal isOpen={this.props.isOpen} className="bid-modal" centered>
            <ModalHeader toggle={this.props.close} className="text-white modal-cross">
               Bid Form
               </ModalHeader>
            <ModalBody>
               <Form name="bidForm" onSubmit={handleSubmit}>
                  <FormGroup className="text-white">
                  All quotes submitted must be "all in" rates. You cannot add fees after bid acceptance unless shipment criteria is different than the original posting
                  </FormGroup>
                  <FormGroup>
                     <Label>Your Quote ($)</Label>
                     <Field type="text" name="bids" component={InputField} validate={[required, numbersOnly, numbersGrt1]} />
                  </FormGroup>
                  <FormGroup tag="fieldset" className="mb-2">
                     <legend className="text-white">Expiration</legend>
                     <FormGroup>
                        <Label check className="container-radio-btn">
                           <Field name="expiration_type" component={RadioField} value="1" props={{ value: '1' }} onChange={this.setChecked} />
                           Cancel at load expiration
                           <span className="checkmark"></span>
                        </Label>
                     </FormGroup>
                     <FormGroup className="mb-3">
                        <Label check className="container-radio-btn">
                           <Field name="expiration_type" component={RadioField} value="2" props={{ value: '2' }} onChange={this.setChecked} />
                                    Cancel on Date
                                    <span className="checkmark"></span>
                        </Label>
                        {this.state.checkValue == 2 && <Field component={DatepickerField} name="expiration_date" validate={[requiredDate]} minDate={new Date()} />}
                     </FormGroup>
                     <div className="mt-3">
                        <h5 className="text-white mb-3" style={{fontWeight: 300}}>Number of days I will</h5>
                        <div className="d-flex justify-content-between">
                           <FormGroup className="mr-2">
                              <Label>Pick up within (days)
                              <Field type="text" component={InputField} name="pickup_within_day" validate={[required, numbersOnly, numbersGrt1]} />
                              </Label>
                           </FormGroup>
                           <FormGroup className="ml-2">
                              <Label>Deliver within (days)
                              <Field type="text" component={InputField} name="delivery_within_day" validate={[required, numbersOnly, numbersGrt1]} />
                              </Label>
                           </FormGroup>
                        </div>
                     </div>
                     
                  </FormGroup>
                  <FormGroup>
                     <Label>Note</Label>
                     <Field type="textarea" component={InputField} name="note" validate={[noteCheckForForm]}/>
                  </FormGroup>
                  <small className="text-white mb-2">
                     <b>Note must NOT</b> <br />
                           1. Contain any numbers <br />
                           2. Contain any special characters<br />
                           3. Contain any email addresses <br />
                           4. Contain any website url <br />
                  </small><br />
                  <div className="float-right mt-2">
                     <button type="submit" className="btn btn-primary">Submit Bid</button>
                  </div>
               </Form>
            </ModalBody>
         </Modal>
      )
   }
}

const BidModal = reduxForm({
   form: 'bidForm',
   destroyOnUnmount: true,
   enableReinitialize: true,
})(BidModalForm);

export default connect()(BidModal)