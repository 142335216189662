/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr'
import axios from 'axios';


import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { STORAGE, APIS, ACTIONS } from './config/config';
//import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

const token = localStorage.getItem(STORAGE);

const setTheme = () => {
   let theme = localStorage.getItem('theme')
   let x = [...document.styleSheets]
   let index = (x.findIndex(item=>item.title == 'dark'))
   if(theme == null || theme == 'light'){
      document.styleSheets[index].disabled = true
      localStorage.setItem('theme', 'light')
   }
   else {
      document.styleSheets[index].disabled = false
      localStorage.setItem('theme', 'dark')
   }
}

function render() {
   setTheme()
   ReactDOM.render(
      <Provider store={store}>
         <div className="height-full">
            <ReduxToastr
               timeOut={4000}
               newestOnTop={false}
               preventDuplicates
               position="top-center"
               getState={(state) => state.toastr} // This is the default
               transitionIn="fadeIn"
               transitionOut="fadeOut"
               progressBar
               closeOnToastrClick />
            <App />
         </div>
      </Provider>,
      document.getElementById('root'),
   );
}

if (token) {
   try {
      axios({
         method: 'GET',
         url: APIS.USER.ME,
         headers: {
            Authorization: `Bearer ${token}`,
         },
      })
         .then(success => {
            store.dispatch({
               type: ACTIONS.USER.LOGIN,
               payload: {
                  token,
               },
            });
            store.dispatch({
               type: ACTIONS.USER.ME,
               payload: success.data.data,
            });
            render();
         })
         .catch(error => {
           /* localStorage.removeItem(STORAGE);
            store.dispatch({
               type: ACTIONS.USER.LOGOUT,
            }); */
            render();
         });
   } catch (e) {
      //localStorage.removeItem(STORAGE);
      render();
   }
} else {
   render();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
