/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS } from '../config/config';

function* setLoader(status) {
   yield put({
      type: status ? ACTIONS.LOADER.SHOW_LOADER : ACTIONS.LOADER.HIDE_LOADER,
   });
}

export function* createShipment(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.BOOKING.CREATE_SHIPMENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* locationTypes(action) {
   try {
      const resp = yield call(CallApi.GET, APIS.BOOKING.LOCATION_TYPES, null, true);
      action && action.callbackSuccess && action.callbackSuccess(resp);
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* timeframes(action) {
   try {
      const resp = yield call(CallApi.GET, APIS.BOOKING.TIME_FRAMES, null, true);
      action && action.callbackSuccess && action.callbackSuccess(resp);
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* categories(action) {
   try {
      const resp = yield call(CallApi.GET, APIS.BOOKING.CATEGORIES, null, true);
      action && action.callbackSuccess && action.callbackSuccess(resp);
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* vehicleYear(action) {
   try {
      yield setLoader(true);
      const resp = yield call(CallApi.GET, APIS.BOOKING.VEHICLE_YEAR, null, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getCountryPhoneFormat(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.BOOKING.GET_COUNTRY_PHONE_FORMAT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* vehicleMake(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.BOOKING.VEHICLE_MAKE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* vehicleModel(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.BOOKING.VEHICLE_MODEL, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* shipmentList(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.CARRIER.LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* archiveBids(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.CARRIER.ARCHIVE_BIDS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
            yield setLoader(false);
            action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* bidDetails(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.CARRIER.BID_DETAILS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* submitBid(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.CARRIER.SUBMIT_BID, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* saveWatch(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.CARRIER.SAVE_WATCH, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* editBid(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.CARRIER.EDIT_BID, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateBid(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.CARRIER.UPDATE_BID, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* accRejQuote(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.CARRIER.ACC_REJ_QUOTE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* availableBids(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.CARRIER.AVAILABLE_BIDS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* availableClusterMap(action) {
   try {
      yield setLoader(true);
      const resp = yield call(CallApi.GET, APIS.CARRIER.CLUSTER_MAP.AVAILABLE_BIDS, null, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* myBids(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.CARRIER.MY_BIDS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* myBidsClusterMap(action) {
   try {
      yield setLoader(true);
      const resp = yield call(CallApi.GET, APIS.CARRIER.CLUSTER_MAP.MY_BIDS, null, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* watchedLists(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.CARRIER.WATCHED_LISTS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* watchedListsClusterMap(action) {
   try {
      yield setLoader(true);
      const resp = yield call(CallApi.GET, APIS.CARRIER.CLUSTER_MAP.WATCHED_LISTS, null, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* completedBids(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.CARRIER.COMPLETED_BIDS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* completedBidsClusterMap(action) {
   try {
      yield setLoader(true);
      const resp = yield call(CallApi.GET, APIS.CARRIER.CLUSTER_MAP.COMPLETED_BIDS, null, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* rejectedBids(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.CARRIER.REJECTED_BIDS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* rejectedBidsClusterMap(action) {
   try {
      yield setLoader(true);
      const resp = yield call(CallApi.GET, APIS.CARRIER.CLUSTER_MAP.REJECTED_BIDS, null, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* saveMultiSettings(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.CARRIER.MULTI_SELECT.SAVE_SETTINGS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getMultiSettings(action) {
   try {
      yield setLoader(true);
      const resp = yield call(CallApi.GET, APIS.CARRIER.MULTI_SELECT.GET_SETTINGS, null, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* saveCarrierPreferences(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.CARRIER.SAVE_CARRIER_PREFERENCES, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}