/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS } from '../config/config';

function* setLoader(status) {
   yield put({
      type: status ? ACTIONS.LOADER.SHOW_LOADER : ACTIONS.LOADER.HIDE_LOADER,
   });
}

export function* terms(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.INFO.TERMS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* blockInformation(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.INFO.BLOCK_INFO, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}
