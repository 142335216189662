/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label } from 'reactstrap';
import { reduxForm, Field, change, untouch } from 'redux-form';
import { InputField, phoneFormat, getOriginalPhone, RadioField } from '../../../common/form-elements/elements';
import { TestSelect } from '../../../common/form-elements/elements';
import { required, emailValidator, textOnly, phoneValidator, passwordStrength, passwordsMatch, usZipCodeValidator } from '../../../common/validators/form-validators';
import { SAGA_ACTIONS, ACTIONS } from '../../../config/config';
import { toastr } from 'react-redux-toastr';
import { Link, withRouter } from 'react-router-dom'
import OTP from './otp';
import { createTextMask } from 'redux-form-input-masks';

class ShipReg extends Component {
   formRef = React.createRef()

   state = {
      country_list: [],
      state_list: [],
      city_list: [],
      zone_code: '',
      showOTPModal: false,
      checkValue: '1',
      ph_format: '(999) 999-9999'
   }

   componentDidMount() {
      this.getCounrty()
   }

   componentDidUpdate(prevProps) {
      if (this.props.submitFailed) {
         this.formRef.current.scrollTo(0, 0)
      }
   }

   getCounrty = () => {
      this.props.dispatch({
         type: SAGA_ACTIONS.USER.REGISTER.GET_COUNTRY,
         callbackSuccess: res => {
            this.setState({
               country_list: res.data.data
            })
         }
      })
   }

   handleDropdown = (e, dropdown) => {
      if (dropdown === 'country') {
         let cont_dets = this.state.country_list.find(country => country.value == e.value)
         this.setState({
            state_list: [],
            city_list: [],
            zone_code: cont_dets.phonecode,
            ph_format: cont_dets.phone_code_format
         }, () => this.changehandleFunc())
      }
      this.props.dispatch({
         type: dropdown === 'country' ? SAGA_ACTIONS.USER.REGISTER.GET_STATE : SAGA_ACTIONS.USER.REGISTER.GET_CITY,
         payload: { id: e.value },
         callbackSuccess: res => {
            dropdown === 'country' ? this.setState({ state_list: res.data.data }) : this.setState({ city_list: res.data.data })
         }
      })
   }

   changehandleFunc() {
      this.props.dispatch(change('registrationForm', 'state_id', ''));
      this.props.dispatch(change('registrationForm', 'city_id', ''));
      this.props.dispatch(change('registrationForm', 'zone_code', this.state.zone_code));
      this.props.dispatch(change('registrationForm', 'phone', ''));
      this.props.dispatch(untouch('registrationForm', 'phone'));
   }

   handleRegister = (values) => {
      /* if (this.props.userType == 1 && !values.mc && !this.state.doc_file) {
         toastr.error('Error', 'Either MC# or Authority Packet is required')
      }
      else { */
      return new Promise((resolve, reject) => {
         let payload = { ...values }
         payload['user_type'] = 2
         this.props.dispatch({
            type: ACTIONS.USER.REGISTER.REGISTER_USER,
            payload
         })
         let otpPayload = {}
         if (this.state.checkValue == 1) {
            otpPayload['otp_type'] = 1;
            otpPayload['phone'] = values.phone;
            otpPayload['zone_code'] = this.state.zone_code;
         }
         else {
            otpPayload['otp_type'] = 2;
            otpPayload['email'] = values.email;
            otpPayload['first_name'] = values.first_name
         }
         this.props.dispatch({
            type: SAGA_ACTIONS.USER.REGISTER.GET_OTP,
            payload: otpPayload,
            callbackSuccess: res => {
               toastr.success('Success', res.data.msg)
               this.setState({ showOTPModal: true })
               resolve(true)
            },
            callbackError: err => {
               //otp error
               toastr.error('Eror', err.data.msg)
               reject(false)
            }
         })
      })
      //}
   }

   goBack = () => {
      this.props.dispatch({
         type: ACTIONS.USER.REGISTER.STEP,
         payload: 'step1'
      })
   }

   handleOTPModal = () => {
      this.setState({ showOTPModal: false })
   }

   setChecked = (e) => {
      this.setState({ checkValue: e.target.value }, () => console.log(this.state))
   }

   render() {
      const { handleSubmit, step, userType, submitting } = this.props;
      return (
         <div>
            <div className="log-bg" />
            <div className="box-log pt-3 extra-orange">
               <div className="title-wrap">
                  <h2 className="title">Signup</h2>
                  <div className="logo-small-right"><img src="images/logo.png" alt="Logo" onClick={() => this.props.history.push('/')} /></div>
               </div>
               <div className="step02 trans-box" ref={this.formRef}>
                  <Form name="registrationForm" onSubmit={handleSubmit(this.handleRegister)}>
                     <div className="row">
                        <div className="col-md-6">
                           <FormGroup>
                              <Label>First Name</Label>
                              <Field component={InputField} name="first_name" type="text" validate={[required, textOnly]} />
                           </FormGroup>
                        </div>
                        <div className="col-md-6">
                           <FormGroup>
                              <Label>Last Name</Label>
                              <Field component={InputField} name="last_name" type="text" validate={[required, textOnly]} />
                           </FormGroup>
                        </div>
                     </div>

                     <FormGroup>
                        <Label>Email</Label>
                        <Field component={InputField} name="email" type="text" validate={[required, emailValidator]} />
                     </FormGroup>
                     <div className="row">
                        <div className="col-md-4">
                           <FormGroup>
                              <Label>Country</Label>
                              <Field name="country_id" type="select" component={TestSelect} validate={[required]} options={this.state.country_list} onChange={(e) => this.handleDropdown(e, 'country')} />
                           </FormGroup>
                        </div>
                        <div className="col-md-4">
                           <FormGroup>
                              <Label>State</Label>
                              <Field name="state_id" type="select" component={TestSelect} validate={[required]} options={this.state.state_list} onChange={this.handleDropdown} />
                           </FormGroup>
                        </div>
                        <div className="col-md-4">
                           <FormGroup>
                              <Label>City</Label>
                              <Field name="city_id" type="select" component={TestSelect} validate={[required]} options={this.state.city_list} />
                           </FormGroup>
                        </div>
                     </div>

                     <div className="row mb-0">
                        <div className="col-sm-12">
                           <FormGroup>
                              <Label>Phone No.</Label>
                              <Field component={InputField} name="phone" type="text" validate={[required, phoneValidator]}
                                 {...createTextMask({
                                    pattern: this.state.ph_format, allowEmpty: true,
                                    guide: false
                                 })}
                              />
                           </FormGroup>
                        </div>
                     </div>
                     <FormGroup>
                        <Label>Address</Label>
                        <Field type="textarea" name="street_address" component={InputField} validate={[required]} style={{ height: '60px !important' }} />
                     </FormGroup>
                     <FormGroup>
                        <Label>Zip Code</Label>
                        <Field component={InputField} name="postal_code" type="text" validate={[required, usZipCodeValidator]} maxLength={7} />
                     </FormGroup>
                     <div className="row">
                        <div className="col-sm-6">
                           <FormGroup>
                              <Label>Password</Label>
                              <Field type="password" name="password" component={InputField} validate={[required, passwordStrength]} />
                           </FormGroup>
                        </div>
                        <div className="col-sm-6">
                           <FormGroup>
                              <Label>Confirm Password</Label>
                              <Field type="password" name="confirm_password" component={InputField} validate={[required, passwordStrength, passwordsMatch]} />
                           </FormGroup>
                        </div>
                     </div>

                     <div className="hints">
                        <small className="text-white">
                           <b>Password must</b> <br />
                           1. Be more than 6 characters long <br />
                           2. Contain at least one Uppercase letter<br />
                           3. Contain at least one Lower case letter <br />
                           4. Contain at least one numeric value <br />
                           5. Contain at least one special character
                        </small>
                     </div>

                     <FormGroup className="mt-3">
                        <Label check className="container-radio-btn">
                           <Field name="otp_type" component={RadioField} value={'1'} props={{ value: '1' }} onChange={this.setChecked} />
                                    Get OTP on phone
                                    <span className="checkmark"></span>
                        </Label>
                        <Label check className="container-radio-btn">
                           <Field name="otp_type" component={RadioField} value={'2'} props={{ value: '2' }} onChange={this.setChecked} />
                                    Get OTP on email
                                    <span className="checkmark"></span>
                        </Label>
                     </FormGroup>

                     <div className="row mt-5">
                        <div className="col-6">
                           <Link to="/login" className="forgot-pass black-text" onClick={this.goBack}>Back to Login</Link>
                        </div>
                        <div className="col-6 text-right">
                           <button type="submit" className="btn btn-primary with-ico-right" disabled={submitting}>Next <i className="fa fa-angle-right"></i></button>
                        </div>
                     </div>
                  </Form>
               </div>
            </div>
            {this.state.showOTPModal && <OTP close={this.handleOTPModal} />}
         </div>
      )
   }
}

const ShipRegistration = reduxForm({
   form: 'registrationForm',
   destroyOnUnmount: true,
   enableReinitialize: true,
})(ShipReg);

const mapStateToProps = state => {
   const step = state.user && state.user.registrationStep ? state.user.registrationStep : null
   const userType = state.user && state.user.registrationValues ? state.user.registrationValues.user_type : 2
   return {
      step,
      userType,
      initialValues: { otp_type: 1 }
   };
};

export default connect(mapStateToProps)(withRouter(ShipRegistration))