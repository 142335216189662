/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React, { Component } from "react";

class RouteMap extends Component {
   googleMapRef = React.createRef()

   state = {
      origin: {},
      destination: {},
      origin_zipcode: '',
      delivery_zipcode: '',
      flag: 0
   }

   shouldComponentUpdate(nextProps, nextState) {
      if(nextState.flag == 1) {
         return false
      }
      return true
   }

   componentDidUpdate(prevProps) {
      if ((prevProps.origin !== this.props.origin) || (prevProps.destination !== this.props.destination)) {
         this.setState({
            origin: this.props.origin,
            destination: this.props.destination,
            origin_zipcode: this.props.origin_zipcode,
            delivery_zipcode: this.props.delivery_zipcode,
            flag: 1
         }, () => this.setMap())
      }
   }

   setMap = () => {
      let directionsService = new window.google.maps.DirectionsService();
      let directionsRenderer = new window.google.maps.DirectionsRenderer();

      let map = new window.google.maps.Map(this.googleMapRef.current, {
         zoom: 7,
         center: {
            lat: parseFloat(this.state.origin.lat),
            lng: parseFloat(this.state.origin.lng),
         },
         streetViewControl: false
      })
      directionsRenderer.setMap(map);
      this.calculateAndDisplayRoute(directionsService, directionsRenderer, this.state.origin_zipcode.origin_zipcode, this.state.delivery_zipcode.delivery_zipcode)
   }

   calculateAndDisplayRoute = (directionsService, directionsRenderer, origin_zipcode, delivery_zipcode) => {
      directionsService.route(
         {
            origin: new window.google.maps.LatLng(this.state.origin.lat, this.state.origin.lng),
            destination: new window.google.maps.LatLng(this.state.destination.lat, this.state.destination.lng),
            travelMode: window.google.maps.TravelMode.DRIVING,
         },
         function (response, status) {
            if (status === 'OK') {
               directionsRenderer.setDirections(response);
               console.log(origin_zipcode, delivery_zipcode);
               response.routes[0].legs[0].start_address = origin_zipcode;
               response.routes[0].legs[0].end_address = delivery_zipcode;
               console.log();
            }
         });
   }

   render() {
      return (
         <div>
            <div
               id="google-map"
               ref={this.googleMapRef}
               style={{ width: '100%', height: '300px' }}
            />
         </div>
      )
   }
}

export default RouteMap