/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Navbar, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { SAGA_ACTIONS } from '../../config/config';
import ReactStars from 'react-rating-stars-component'
import './sidenav.css';
import { toastr } from 'react-redux-toastr';

class SideNav extends React.Component {
   state = {
      isVisible: false,
      filterModal: false,
      isLight: false,
      thememode: localStorage.getItem('theme')
   };

   updateModal = (isVisible) => {
      this.setState({ isVisible });
   }

   handleClickOnMenu = () => {
      this.setState({ isVisible: false });
   }

   handleLogout = () => {
      return new Promise((resolve, reject) => {
         this.props.dispatch({
            type: SAGA_ACTIONS.USER.LOGOUT,
            callbackSuccess: (resp) => {
               localStorage.removeItem("category");
               this.props.history.replace('/login');
               toastr.success('success', resp.data.msg);
            },
            callbackError: e => {
               console.log('error', e);
               // this.showToaster('error', 'Error', e.data.msg);
            },
         });
         return resolve(true);
      });
   }

   changeTheme = () => {
      let theme = localStorage.getItem('theme')
      let x = [...document.styleSheets]
      let index = (x.findIndex(item => item.title == 'dark'))
      if (theme == 'light') {
         document.styleSheets[index].disabled = false
         localStorage.setItem('theme', 'dark')
         this.setState({ thememode: 'dark' });
      }
      else {
         document.styleSheets[index].disabled = true
         localStorage.setItem('theme', 'light')
         this.setState({ thememode: 'light' });
      }
   }

   render() {
      const { user } = this.props
      return (
         <Navbar fixed="top">
            <div className="top-header">
               <div className="left-part">
                  <button onClick={() => this.updateModal(true)} className="bars-btn"><img src="/images/menu-icon.png" alt='menu' /></button>

                  {((localStorage.getItem('route') === 'Shipment Details' || localStorage.getItem('route') === 'Bid Details' || localStorage.getItem('route') === 'Update Bid') || localStorage.getItem('route') === 'Tracking' || localStorage.getItem('route') === 'Edit Profile') && <span className="back-btn" onClick={() => this.props.history.goBack()}><img src="/images/back.png" /></span>}

                  <h3 style={{ marginTop: '8px', textTransform: 'capitalize' }}>{localStorage.getItem('route') || ''}</h3>
                  {localStorage.getItem('route') !== 'dashboard' && <Link className="back-arrow" to='/dashboard'><img src="/images/logo-white.png" alt="Logo" /></Link>}
               </div>
            </div>
            <Modal className='menu-sidebar left' isOpen={this.state.isVisible} onClosed={() => this.updateModal(false)} autoFocus keyboard>
               <ModalHeader toggle={() => this.updateModal(false)}>
                  <div className="left-menu-wrapper">
                     <div className="logo-wrap">
                        <img src="/images/logo.png" alt="Logo" />
                     </div>
                     <div className="top-part-menu">
                        <div className="user-img-wrap">
                           <img src={user && user.media_size_image_url ? user.media_size_image_url : "/images/avatar.png"} />
                        </div>
                        <div style={{ wordBreak: "break-all" }}>
                           <h5>{user ? user.first_name : ''} {user ? user.last_name : ''}</h5>
                           <h6>{user ? user.email : ''}</h6>
                        </div>
                        {user && user.user_type == 1 &&
                           <div>
                              <div className="rating d-flex">
                                 <ReactStars
                                    count={5}
                                    half={true}
                                    value={user.rating}
                                    edit={false}
                                 /> {`(${user.rating}/5)`}
                                 <Link to="/feedback" onClick={this.handleClickOnMenu} className="text-white ml-3"><u>Reviews</u></Link>
                              </div>
                           </div>}
                        <h6 className="mt-2">{user && user.user_type == 1 ? 'Transporter Account' : 'Shipper Account'}</h6>
                        <div className="theme-toggle">
                           <button onClick={this.changeTheme}>
                              {(this.state.thememode == null || this.state.thememode == 'light') && 'Enable Dark Theme'}
                              {this.state.thememode == 'dark' && 'Enable Light Theme'}
                           </button></div>
                     </div>
                  </div>
               </ModalHeader>
               <ModalBody>
                  <ul>
                     <li><Link to="/dashboard" onClick={this.handleClickOnMenu}>Dashboard</Link></li>
                  </ul>
                  <div className='divider'></div>
                  <ul>
                     <li><Link to="/edit-profile" onClick={this.handleClickOnMenu}>My Profile</Link></li>
                     {user && user.user_type == 1 && <li><Link to="/notifications" onClick={this.handleClickOnMenu}>Notifications</Link></li>}
                     {user && user.user_type == 2 && <><li>
                        <Link to="/order-history" onClick={this.handleClickOnMenu}>Order History</Link>
                     </li><li>
                        <Link to="/upload-shipments" onClick={this.handleClickOnMenu}>Upload Shipments</Link>
                     </li></>}
                     <li><Link to="/contact-us">Contact Support</Link></li>
                     <li><a href="https://www.motherload.app/" target="_blank">Visit Website</a></li>
                     <li><span onClick={this.handleLogout} className="logout-link">Log Out</span></li>
                  </ul>
               </ModalBody>
            </Modal>
         </Navbar>
      )
   }
}

const mapStateToProps = state => {
   const user = state.user ? state.user.user : null;
   return {
      user
   };
};
export default connect(mapStateToProps)(withRouter(SideNav));