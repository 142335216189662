/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

/* eslint eqeqeq: 0 */

import React, { Component, PureComponent } from 'react'
import { Form, FormGroup, Label, Modal, ModalHeader, Input, ModalBody, Row, Col, Button } from 'reactstrap'
import LocationSearchInput from '../../../common/form-elements/address'
import { Field, reduxForm, reset, change } from 'redux-form';
import { required } from '../../../common/validators/form-validators';
import { connect } from 'react-redux';
import { InputField, RadioField } from '../../../common/form-elements/elements';
import './tracking.css'
import { SAGA_ACTIONS, ACTIONS } from '../../../config/config';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import ReactStars from 'react-rating-stars-component'
import InfoModal from '../../infoModal';

class TrackingForm extends Component {
   state = {
      checkValue: '1',
      showForm: false,
      addressList: [],
      pickup: {},
      destination: {},
      shipment: {},
      latLng: {},
      address: '',
      shipperModal: false,
      showFormBtn: false,
      showMap: false,
      geoPermission: true,
      infoModal: false
   }

   componentDidMount() {
      this.getShipment();
   }

   checkShipper = () => {
      const { has_feedback, has_complain, shipment } = this.state
      if (this.props.userType == 2 && this.state.destination) {
         if (shipment.status == 4 || (shipment.status == 5 && !has_feedback) || (shipment.status == 7 && !has_complain)) {
            this.setState({ shipperModal: true, showFormBtn: true })
         }
         else {
            this.setState({ shipperModal: false, showFormBtn: false })
         }
      }
   }

   getShipment = () => {
      this.props.dispatch({
         type: SAGA_ACTIONS.SHIPPER.SHIPMENT_DETAILS,
         payload: { id: this.props.match.params.id },
         callbackSuccess: res => {
            this.setState({ shipment: res.data.data })
            this.getAddresses()
         }
      })
   }

   getAddresses = () => {
      this.props.dispatch({
         type: SAGA_ACTIONS.USER.TRACKING_LIST,
         payload: { shipment_id: this.state.shipment.unique_id },
         callbackSuccess: res => {
            if (res.data.data.track.find(item => item.states == 1)) {
               this.props.dispatch(change('trackingForm', 'states', '2'))
               this.setState({ checkValue: '2' })
            }
            this.setState({
               pickup: res.data.data.track ? res.data.data.track.find(item => item.states == 1) : {},
               destination: res.data.data.track ? res.data.data.track.find(item => item.states == 3) : {},
               addressList: res.data.data.track ? res.data.data.track.filter(item => item.states == 2) : [],
               has_feedback: res.data.data.feedback ? true : false,
               has_complain: res.data.data.issue ? true : false
            }, () => this.checkShipper())
         }
      })
   }

   setChecked = (e) => {
      this.setState({ checkValue: e.target.value })
   }

   handleTracking = (values) => {
      let payload = {
         shipment_id: this.state.shipment.id,
         latitude: this.state.latLng.lat,
         longitude: this.state.latLng.lng,
         ...values
      }

      if (this.state.showMap && this.state.geoPermission) {
         payload['address'] = this.state.address
      }

      if (this.state.checkValue == 2 && (!this.state.latLng.lat || !this.state.latLng.lng)) {
         toastr.error('Could not get location coordinates')
      }
      else {
         this.props.dispatch({
            type: SAGA_ACTIONS.USER.SEND_TRACKING_ADD,
            payload,
            callbackSuccess: res => {
               toastr.success('Success', res.data.msg)
               this.props.dispatch(reset('trackingForm'))
               this.getAddresses()
               this.setState({ showForm: false, showMap: false, latLng: {} })
               if (this.state.checkValue == 3) {
                  this.getShipment()
               }
            },
            callbackError: err => {
               console.log('err', err)
            }
         })
      } 
   }

   toggleForm = () => {
      this.setState({ showForm: !this.state.showForm, showMap: false })
   }

   setLatLong = (e) => {
      this.props.dispatch({
         type: ACTIONS.LOADER.SHOW_LOADER
      })
      geocodeByAddress(e)
         .then(results => getLatLng(results[0]))
         .then(latLng => {
            this.props.dispatch({
               type: ACTIONS.LOADER.HIDE_LOADER
            })
            this.setState({ latLng })
         }).catch(error => {
            this.props.dispatch({
               type: ACTIONS.LOADER.HIDE_LOADER
            })
            toastr.error('Error', 'Could not get coordinates of location');
            this.setState({ latLng: {} })
         })
   }

   //shipper clicked yes
   confirmRecd = () => {
      this.props.dispatch({
         type: SAGA_ACTIONS.USER.CONFIRM_STATUS,
         payload: {
            status: 1,
            shipment_id: this.state.shipment.id
         },
         callbackSuccess: res => {
            this.getShipment();
            toastr.success('Success', res.data.msg)
         },
         callbackError: err => {
            toastr.error(err.data.msg)
         }
      })
   }

   //shipper clicked no and complained
   confirmReject = (message) => {
      this.props.dispatch({
         type: SAGA_ACTIONS.USER.CONFIRM_STATUS,
         payload: {
            status: 2,
            shipment_id: this.state.shipment.id,
            message
         },
         callbackSuccess: res => {
            this.getShipment();
            this.setState({ shipperModal: false })
            toastr.success('Success', res.data.msg)
         },
         callbackError: err => {
            toastr.error(err.data.msg)
         }
      })
   }

   sendFeedBack = (additional) => {
      this.props.dispatch({
         type: SAGA_ACTIONS.USER.FEEDBACK_SEND,
         payload: {
            shipment_id: this.state.shipment.id,
            ...additional
         },
         callbackSuccess: res => {
            this.setState({ shipperModal: false })
            this.getShipment()
            toastr.success('Success', res.data.msg)
         },
         callbackError: err => {
            toastr.error(err.data.msg)
         }
      })
   }

   getInfo = (type) => {
      this.setState({ infoModal: type })
   }

   handleCurrentLocation = () => {
      if (navigator.geolocation) {
         navigator.geolocation.getCurrentPosition(this.setLocation)
      }
      navigator.permissions.query({ name: 'geolocation' }).then(permission => {
         if (permission.state === 'denied') {
            this.setState({ geoPermission: false, showMap: true })
         }
      })
   }

   setLocation = (position) => {
      let location = { lat: position.coords.latitude, lng: position.coords.longitude }
      this.setState({ latLng: location, showMap: true})

      let geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ 'location': location }, (results, status) => {
         if (status === 'OK') {
            if (results[0]) {
               this.setState({ showMap: true, address: results[0].formatted_address })
            } else {
               toastr.error('Could not get location');
            }
         }
      });
   }

   cancelCurrLocation = () => {
      this.setState({showMap: false, latLng: {}})
      this.props.dispatch(change('trackingForm','address',''))
   }

   render() {
      const { handleSubmit } = this.props
      const { addressList, shipment } = this.state
      return (
         <div>
            <div className="log-bg" />
            <div className="box-log pt-3">
               <div className="trans-box transbox-margin">
                  <div className="btn-info-wrap">
                     {this.props.userType == 1 && !this.state.destination && <button className="btn btn-primary" onClick={this.toggleForm}>Update Shipment Location</button>}
                     {this.state.showFormBtn && <button className="btn btn-primary" onClick={() => this.setState({ shipperModal: true })}>Feedback</button>}
                     <div className="info-btn text-right"><button className="btn text-white" onClick={() => this.getInfo(true)} type="button"><i className="fa fa-question-circle" /></button></div>
                  </div>
                  <div className="tracking-wrap">
                     <ul className="timeline">
                        <li>
                           <h3 className="black-text">{this.state.pickup ? `${moment.utc(this.state.pickup.created_at).local().format('Do MMMM YYYY h:mm a')} (Picked Up)` : 'Not yet picked up'}</h3>
                           <p className="black-text">{shipment.pickup_address}</p>
                           {this.state.pickup && <div className="text-white">
                              <h6 className="black-text">Message:</h6>
                              <p className="black-text">{this.state.pickup.message}</p>
                           </div>}
                        </li>
                        {addressList.length ? addressList.map((item, i) => <li key={i}>
                           <h3 className="black-text">{moment.utc(item.created_at).local().format('Do MMMM YYYY h:mm a')}</h3>
                           <p className="black-text">{item.address}</p>
                           <div className="inline-map mb-2">
                              <CurrLocation location={{ lat: item.latitude, lng: item.longitude }} />
                           </div>
                           <h6 className="text-white">Message:</h6>
                           <p className="black-text">{item.message}</p>
                        </li>) : null}
                        <li className="last-time">
                           <h3 className="black-text">{this.state.destination ? `${moment.utc(this.state.destination.created_at).local().format('Do MMMM YYYY h:mm a')} (Delivered)` : 'Not yet delivered'}</h3>
                           <p className="black-text">{shipment.destination_address}</p>
                           <div className="status-note text-white">
                              <h6>{shipment.status == 4 && this.state.destination && 'Shipment delivered, awaiting shipper confirmation'}</h6>
                              <h6>{shipment.status == 5 && this.state.destination && 'Shipment Delivered'}</h6>
                              <h6>{shipment.status == 7 && this.state.destination && 'Issue Arised by shipper, please contact admin'}</h6>
                           </div>
                        </li>
                     </ul>
                  </div>

                  <Modal isOpen={this.state.showForm} centered>
                     <ModalHeader toggle={this.toggleForm} className="text-white modal-cross">
                        Track your shipment
                     </ModalHeader>
                     <ModalBody>
                        {this.state.showForm &&
                           <Form name="trackingForm" onSubmit={handleSubmit(this.handleTracking)}>
                              <FormGroup tag="fieldset" className="mb-2">
                                 {!this.state.pickup && <FormGroup check>
                                    <Label check className="container-radio-btn">
                                       <Field name="states" component={RadioField} value="1" props={{ value: '1' }} onChange={this.setChecked} />
                           Pickup
                           <span className="checkmark"></span>
                                    </Label>
                                 </FormGroup>}
                                 {this.state.pickup ? <FormGroup check className="mb-3">
                                    <Label check className="container-radio-btn">
                                       <Field name="states" component={RadioField} value="2" props={{ value: '2' }} onChange={this.setChecked} />
                                    On the way
                                    <span className="checkmark"></span>
                                    </Label>
                                 </FormGroup> : null}
                                 {this.state.pickup ? <FormGroup check>
                                    <Label check className="container-radio-btn">
                                       <Field name="states" component={RadioField} value="3" props={{ value: '3' }} onChange={this.setChecked} />
                                   Completed
                                    <span className="checkmark"></span>
                                    </Label>
                                 </FormGroup> : null}
                              </FormGroup>

                              {this.state.checkValue != 3 &&
                                 <div>
                                    {(this.state.pickup && !this.state.destination) && <FormGroup>
                                       <Label>Address</Label>
                                       {(!this.state.geoPermission || !this.state.showMap) && <div><div className="d-flex track-address">
                                          <Field component={LocationSearchInput} name="address" validate={[required]} getCoords={(add) => this.setLatLong(add)} />
                                          <i className="fa fa-map-marker location-icon" onClick={this.handleCurrentLocation} />
                                       </div>
                                       {this.state.latLng.lat && <div className="mt-3">
                                             <CurrLocation location={this.state.latLng} />
                                    </div>}
                                       </div>}

                                       {this.state.showMap && <div>
                                          {this.state.geoPermission ? <div>
                                             <div className="d-flex mb-3">
                                                <input type="text" value={this.state.address} readOnly={this.state.showMap} className="form-control" />
                                                <button className="btn btn-default pr-0" onClick={this.cancelCurrLocation}><i className="fa fa-times text-white" /></button>
                                             </div>
                                             <CurrLocation location={this.state.latLng} />
                                          </div> : <div><h6 className="location-error">Location not enabled in settings</h6>
                                          <p className="text-white">Please refresh if you change the settings</p>
                                          </div>}
                                       </div>}
                                    </FormGroup>}

                                    {!this.state.destination && <FormGroup>
                                       <Label>Message</Label>
                                       <Field component={InputField} name="message" validate={[required]} type="textarea" />
                                    </FormGroup>}
                                 </div>}
                              <div className="text-right">
                                 <button type="submit" className="btn btn-primary">Submit</button>
                              </div>
                           </Form>}
                     </ModalBody>
                  </Modal>
               </div>
            </div>
            <InfoModal isOpen={this.state.infoModal} close={() => this.getInfo(false)} id={4} />

            {this.state.shipperModal &&
               <ShipperModal
                  status={shipment.status}
                  isOpen={true} close={() => this.setState({ shipperModal: false })}
                  has_complain={this.state.has_complain}
                  has_feeback={this.state.has_feeback}
                  recdShipment={this.confirmRecd}
                  confirmReject={this.confirmReject}
                  sendFeedBack={this.sendFeedBack}
               />}
         </div>
      )
   }
}

const Tracking = reduxForm({
   form: 'trackingForm',
   destroyOnUnmount: true,
   forceUnregisterOnUnmount: true
})(TrackingForm);

const mapStateToProps = state => {
   return {
      userType: state.user && state.user.user.user_type,
      initialValues: { states: '1' }
   }
}

export default connect(mapStateToProps)(Tracking)


class ShipperModal extends Component {
   state = {
      showFeedback: false,
      showComplain: false,
      showYesNo: false,
      rating: 0,
      feedback: '',
      complain: '',
      has_feeback: false,
      has_complain: false
   }

   componentDidMount() {
      this.checkStatus()
   }

   componentDidUpdate(prevProps) {
      if (prevProps.status !== this.props.status) {
         this.checkStatus()
      }
   }

   checkStatus = () => {
      const { status, has_complain, has_feeback } = this.props
      if (status == 4) {
         this.setState({ showYesNo: true })
      }
      else if (status == 7 && !has_complain) {
         this.setState({ showComplain: true, showFeedback: false })
      }
      else if (status == 5 && !has_feeback) {
         this.setState({ showFeedback: true, showComplain: false })
      }
   }

   toggleComplain = () => {
      this.setState({
         showComplain: true, showYesNo: false
      })
   }

   handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value })
   }

   handleKeyPress = (e) => {
      if (e.target.name == 'complain' && this.state.complain.length >= 255){
      e.preventDefault()
      e.stopPropagation()
      this.setState({complain: this.state.complain.substr(0, 255)})
      }
   }

   ratingChanged = (newRating) => {
      this.setState({ rating: newRating })
   }

   handleRecd = () => {
      this.setState({
         showYesNo: false,
         // showFeedback: true
      })
      this.props.recdShipment()
   }

   submitFeedback = (e) => {
      e.preventDefault()
      if (!this.state.feedback) {
         toastr.error('Please give feedback')
      }
      else if (this.state.rating == 0) {
         toastr.error('Please give a rating')
      }
      else {
         this.props.sendFeedBack({ rating: this.state.rating, message: this.state.feedback })
      }
   }

   submitComplain = (e) => {
      e.preventDefault()
      if (!this.state.complain) {
         toastr.error('Please write a complain')
      }
      else {
         this.props.confirmReject(this.state.complain)
      }
   }

   getInfo = () => {
      this.setState({ infoModal: true })
      this.props.dispatch({
         type: SAGA_ACTIONS.INFO.UPDATE_BID,
         callbackSuccess: res => {
            this.setState({ info: res.data.data.info, infoModal: true })
         }
      })
   }

   render() {
      return (
         <div>
            <Modal isOpen={this.props.isOpen} centered>
               <ModalHeader className="text-white modal-cross" toggle={this.props.close}>
                  {this.state.showFeedback ? 'Rate your carrier' : 'Shipment Status'}
               </ModalHeader>
               <ModalBody>
                  {this.state.showYesNo &&
                     <Row className="text-center">
                        <Col xs={12}>
                           <h3 className="white-text">Shipment Delivered?</h3>
                        </Col>
                        <Col xs={6} className="mt-5 text-right">
                           <Button onClick={this.handleRecd} color="success" className="btn-lg">Confirm</Button>
                        </Col>
                        <Col xs={6} className="mt-5 text-left">
                           <Button onClick={this.toggleComplain} color="danger" className="btn-lg">Not yet</Button>
                        </Col>
                     </Row>}

                  {this.state.showFeedback &&
                     <Form onSubmit={this.submitFeedback}>
                        <FormGroup>
                           <ReactStars
                              count={5}
                              onChange={this.ratingChanged}
                              value={this.state.rating}
                              size={30}
                              half={true}
                              emptyIcon={<i className='far fa-star'></i>}
                              halfIcon={<i className='fa fa-star-half-alt'></i>}
                              fullIcon={<i className='fa fa-star'></i>}
                              color2={'#ffd700'} />
                        </FormGroup>
                        <FormGroup>
                           <Label>Feedback</Label>
                           <Input type="textarea" name='feedback' value={this.state.feedbackForm} onChange={this.handleChange} />
                        </FormGroup>

                        <div className="text-right">
                           <button className="btn btn-primary" type="submit">Submit</button>
                        </div>
                     </Form>}

                  {this.state.showComplain &&
                     <Form onSubmit={this.submitComplain}>
                        <FormGroup>
                           <Label>Complain</Label>
                           <Input type="textarea" name='complain' value={this.state.complain} onChange={this.handleChange} onKeyPress={this.handleKeyPress} onPaste={this.handleKeyPress}/>
                        </FormGroup>
                        <small className="text-white mt-2">
                           Character limit 255 characters
                        </small>
                        <div className="text-right">
                           <button className="btn btn-primary" type="submit">Submit</button>
                        </div>
                     </Form>}
               </ModalBody>
            </Modal>
         </div>
      )
   }
}

class CurrLocation extends PureComponent {
   googleMapRef = React.createRef()

   componentDidMount() {
      this.setMap()
   }

   componentDidUpdate(prevProps){
      if(prevProps.location !== this.props.location){
         this.setMap()
      }
   }

   setMap = () => {
      let map = new window.google.maps.Map(this.googleMapRef.current, {
         zoom: 17,
         center: {
            lat: parseFloat(this.props.location.lat),
            lng: parseFloat(this.props.location.lng),
         },
         disableDefaultUI: true
      })
      let marker = new window.google.maps.Marker({
         position: { lat: parseFloat(this.props.location.lat), lng: parseFloat(this.props.location.lng) },
         map: map
      })
   }

   render() {
      return (
         <div>
            <div
               id="google-map"
               className="google-map"
               ref={this.googleMapRef}
            />
         </div>
      )
   }
}