/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

/* eslint eqeqeq: 0 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SAGA_ACTIONS } from '../../../config/config'
import Select from 'react-select'
import { toastr } from 'react-redux-toastr'
import { FormGroup } from 'reactstrap'

class Notifications extends Component {
   state = {
      cateogries: [],
      states: [],
      multiValue: []
   }

   componentDidMount() {
      this.getSettings()
   }

   getSettings = () => {
      return new Promise((resolve, reject) => {
         this.props.dispatch({
            type: SAGA_ACTIONS.CARRIER.MULTI_SELECT.GET_SETTINGS,
            callbackSuccess: res => {
               this.getCategories(res.data.data.categories)
               this.getStates(res.data.data.states)
               resolve(true)
            },
            callbackError: err => {
               toastr.error(err.data.msg)
               reject(false)
            }
         })
      })
   }

   getStates = (userStates) => {
      this.props.dispatch({
         type: SAGA_ACTIONS.USER.REGISTER.GET_STATE,
         callbackSuccess: res => {
            let selected = []
            for (let i = 0; i < res.data.data.length; i++) {
               if (userStates.findIndex(item => item == res.data.data[i].value) != -1) {
                  selected.push(res.data.data[i])
               }
            }
            this.setState({ states: res.data.data, multiValue: selected })
         },
         callbackError: err => {
            toastr.error('Error getting states')
         }
      })
   }

   getCategories = (userCats) => {
      this.props.dispatch({
         type: SAGA_ACTIONS.BOOKING.CATEGORIES,
         callbackSuccess: res => {
            let tempArr = []
            for (let i = 0; i < res.data.data.length; i++) {
               let tempObj = {}
               tempObj['id'] = res.data.data[i].id;
               tempObj['name'] = res.data.data[i].name;
               if (userCats.findIndex(item => item == res.data.data[i].id) != -1) {
                  tempObj['checked'] = true
               } else {
                  tempObj['checked'] = false
               }
               tempArr.push(tempObj)
            }
            this.setState({ cateogries: tempArr })
         },
         callbackError: err => {
            toastr.error('Error getting categories')
         }
      })
   }

   handleChange = (id) => {
      let opts = [...this.state.cateogries]
      let index = opts.findIndex(item => item.id == id);
      opts[index].checked = !opts[index].checked
      this.setState({ cateogries: opts })
   }

   handleMultiChange = (option) => {
      this.setState(state => {
         return {
            multiValue: option
         };
      });
   }

   saveSettings = () => {
      let cats = []
      let states = []

      if (this.state.cateogries.length) {
         for (let i = 0; i < this.state.cateogries.length; i++) {
            if (this.state.cateogries[i].checked == true) {
               cats.push(this.state.cateogries[i].id)
            }
         }
      }
      if (this.state.multiValue && this.state.multiValue.length) {
         for (let i = 0; i < this.state.multiValue.length; i++) {
            states.push(this.state.multiValue[i].value)
         }
      }

      let payload = { categories: cats, states }
      this.props.dispatch({
         type: SAGA_ACTIONS.CARRIER.MULTI_SELECT.SAVE_SETTINGS,
         payload,
         callbackSuccess: res => {
            toastr.success('Success', res.data.msg)
         },
         callbackError: err => {
            toastr.error(err.data.msg)
         }
      })
   }

   render() {
      return (
         <div>
            <div className="log-bg" />
            <div className="box-log pt-3">
               <div className="step02 trans-box transbox-margin" style={{ minHeight: 0 }}>
                  <h4 className="text-white mb-3">Categories</h4>
                  <div className="row">
                     {this.state.cateogries.length ? this.state.cateogries.map((item, i) =>
                        <div className="col-lg-6" key={i}>
                           <label className="container-checkbox black-text">{item.name}
                              <input type="checkbox" onChange={() => this.handleChange(item.id)} checked={item.checked} />
                              <span className="checkmark"></span>
                           </label>
                        </div>
                     ) : null}
                  </div>
                  <div>
                     <h4 className="text-white mt-1">States</h4>
                     <Select
                        styles={{
                           multiValueLabel : (base) => ({...base,color: 'black'}),
                           multiValueRemove: (base) => ({...base,color: 'black'}) 
                        }}
                        name="states"
                        placeholder="States"
                        value={this.state.multiValue}
                        options={this.state.states}
                        onChange={this.handleMultiChange}
                        isMulti
                        isSearchable
                     />
                  </div>
                  <div className="text-right mt-2">
                     <button onClick={this.saveSettings} className="btn btn-primary">Save</button>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}

export default connect()(Notifications)