/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

/* eslint no-unused-vars: 0 */

import React from 'react';
import { Input, FormFeedback } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import { toastr } from 'react-redux-toastr';
/* import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css' */

export const formErrorStyle = {
   fontSize: '12px',
   color: '#f00',
   display: 'inline-block',
};

export const InputField = ({
   input,
   className,
   name,
   maxLength,
   id,
   readOnly,
   label,
   placeholder,
   value,
   type,
   style,
   autoComplete,
   disabled,
   checked,
   meta: { touched, error, warning },
   ...rest
}) => (
      <>
         <Input
            value={value}
            {...input}
            type={type}
            id={id}
            style={style}
            placeholder={placeholder}
            className={className}
            readOnly={readOnly}
            autoComplete={autoComplete}
            disabled={disabled}
            checked={checked}
            invalid={error && touched}
            maxLength={maxLength}
         />

         {touched &&
            (error && (
               <FormFeedback>
                  {error}
               </FormFeedback>
            ))}
      </>
   );

export const CheckboxField = ({
   input,
   className,
   maxLength,
   id,
   readOnly,
   placeholder,
   value,
   style,
   autoComplete,
   disabled,
   checked,
   meta: { touched, error, warning },
   ...props
}) => (
      <>
         <div className="book-terms black-text">
            <label className="container-checkbox">
               <Input
                  value={value}
                  {...input}
                  type='checkbox'
                  id={id}
                  style={style}
                  placeholder={placeholder}
                  className={className}
                  readOnly={readOnly}
                  autoComplete={autoComplete}
                  disabled={disabled}
                  checked={checked}
                  invalid={error && touched}
                  maxLength={maxLength}
               />

               <span className="checkmark"></span>
            </label>
         I agree to the <span onClick={props.customMethod} style={{ textDecoration: 'underline' }}>Terms and Conditions</span>
         </div>

         {touched &&
            (error && (
               <p className="text-white">
                  {error}
               </p>
            ))}
      </>
   );

export const RadioField = ({ input, meta, ...rest }) => (
   <Input type="radio" {...input} {...rest} checked={input.value == rest.value} />
)

export const DropDownSelect = ({ input, options, meta: { touched, error } }) => {
   const renderSelectOptions = (option, i) => (
      <option key={i} value={option.id}>{option.name}</option>
   )
   return (
      <>
         <Input type="select" {...input} className={touched && error ? "is-invalid" : ''}>
            <option value="">Select</option>
            {options.length && options.map(renderSelectOptions)}
         </Input>

         {touched && error && <FormFeedback>{error}</FormFeedback>}

      </>
   );
}

export class TestSelect extends React.Component {
   constructor(props) {
      super(props);
      this.inputChange = this.inputChange.bind(this);
   }

   inputChange(evt) {
      this.props.input.onChange(evt);
   }

   render() {
      const {
         input: { value, onChange, onKeyDown },
         label,
         placeholder,
         options,
         nameField,
         idField,
         meta: { touched, error, warning },
         defaultVal,
         isMulti,
         custom_style
      } = this.props;
      return (
         <>
            <Select
               styles={{
                  input: (base) => ({ ...base, color: 'black' }),
                  singleValue: (base) => ({ ...base, color: 'black' }),
                  placeholder: (base) => ({ ...base, color: custom_style && custom_style.placeholder ? custom_style.placeholder : 'black' }),
                  menuList: (base) => ({ ...base, color: custom_style && custom_style.menuList ? custom_style.menuList : 'black' }),
                  multiValueLabel: (base) => ({ ...base, color: 'black' }),
                  multiValueRemove: (base) => ({ ...base, color: 'black' })
               }}
               value={value}
               onChange={this.inputChange}
               onKeyDown={this.props.onKeyDown}
               options={options}
               isSearchable
               isClearable={this.props.clearable}
               placeholder={placeholder}
               isMulti={this.props.isMulti ? true : false}
            />
            {touched &&
               (error && (
                  <p className="error-msg">{error}</p>
               ))}
         </>
      );
   }
}


export class FieldFileInput extends React.Component {
   constructor(props) {
      super(props)
      this.onChange = this.onChange.bind(this)
   }

   onChange(e) {
      const { input: { onChange } } = this.props
      onChange(e.target.files[0])
   }

   render() {
      const { input: { value }, accept, multiple } = this.props
      return (
         <div>
            <div>
               <Input className="form-control"
                  id={this.props.id ? this.props.id : ''}
                  type='file'
                  accept={accept}
                  onChange={this.onChange}
                  multiple={multiple}
               />
            </div>
            {this.props.meta.touched && this.props.meta.error &&
               <p className="text-danger">
                  This field is required!
            </p>}
         </div>
      )
   }
}

export class MultiFileInput extends React.Component {
   handleChange = (e) => {
      const { input: { onChange } } = this.props
      if (e.target.files.length > 4) {
         toastr.error('Error', 'Upto 4 Images Allowed')
         e.target.value = null;
      }
      else {
         onChange(e.target.files)
      }
   }

   render() {
      const { input: { value }, accept, multiple } = this.props
      return (
         <div>
            <div>
               <Input className="form-control"
                  type='file'
                  accept={accept}
                  onChange={this.handleChange}
                  multiple={multiple}
               />
            </div>
            {/*this.props.meta.touched && this.props.meta.error &&
               <p className="text-danger">
                  {this.props.meta.error}
            </p>*/}
         </div>
      )
   }
}

export class DatepickerField extends React.Component {
   render() {

      const { input: { value, onChange, name }, placeholder, disabled, readOnly, meta: { touched, error } } = this.props
      return (
         <>
            <DatePicker
               utcOffset={0}
               selected={value}
               showYearDropdown
               showMonthDropdown
               placeholderText={placeholder}
               className="form-control"
               disabled={disabled}
               minDate={this.props.minDate || ''}
               readOnly={readOnly}
               onChangeRaw={(e) => e.preventDefault()}
               onChange={(date) => this.props.input.onChange(date)}
            />
            {touched &&
               (error && (
                  <p className="error-msg">{error}</p>
               ))}
         </>
      )
   }
}

export const normalizeCard = (value) => {
   if (!value) {
      return value
   }
   let onlyNums = value.replace(/[^\d]/g, '')

   onlyNums = `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(8, 12)} ${onlyNums.slice(12, 16)} ${onlyNums.slice(16, 19)}`

   return onlyNums.trim()
}

export const phoneFormat = (value) => {
   if (!value) {
      return value
   }

   const onlyNums = value.replace(/[^\d]/g, '')
   if (onlyNums.length <= 3) {
      return onlyNums
   }
   if (onlyNums.length <= 7) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
   }
   return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10
   )}`
}

export const usPhoneFormat = (value) => {
   if (!value) {
      return value
   }

   const onlyNums = value.replace(/[^\d]/g, '')
   if (onlyNums.length <= 3) {
      return onlyNums
   }
   if (onlyNums.length <= 7) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
   }
   return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10
   )}`
}

export const getOriginalPhone = (value) => {
   if (!value) {
      return value
   }
   else {
      return value.replace(' ', '').replace('-', '')
   }
}

export const USgetOriginalPhone = (value) => {
   if (!value) {
      return value
   }
   else {
      return value.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')
   }
}

export const twoDP = (value) => {
   if (!value) {
      return value
   }

   const onlyNums = value.replace(/[^\d.]/g, '')

   return onlyNums.slice(0, onlyNums.indexOf('.') >= 0 ? onlyNums.indexOf('.') + 3 : undefined)
}

/* export const Phone = ({ input: { onChange, value }, shortName, meta: { form }, ...rest }) => {
   const handleOnChange = (value, data) => {
      console.log('val', value, 'data', data)
      onChange(value)
   }

   return (
      <PhoneInput
         country={shortName}
         disableDropdown={false}
         enableTerritories
         placeholder="Enter phone number"
         value={value}
         onChange={handleOnChange}
         countryCodeEditable={false}
      />
   )
} */

export const rawMask = (num, mask) => {
   let count = 0
   let newmask = ''

   while (count < num.length) {
      for (let i = 0; i < mask.length; i++) {
         if (mask[i] == 9) {
            newmask = newmask + (num[count] ? num[count] : '')
            count += 1
         }
         else {
            newmask = newmask + mask[i]
         }
      }
   }

   return newmask
}