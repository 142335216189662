/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

/* eslint eqeqeq: 0 */

export const create_palette = [{ name: 'Yes Crated', id: 1 }, { name: 'Yes Palletized', id: 2 }, { name: 'No Crate/Pallet', id: 3 }]

export const new_used = [{ name: 'New', id: 1 }, { name: 'Used', id: 2 }]

export const packaging_type = [{ name: 'Pallet', id: 1 }, { name: 'Crate', id: 2 }, { name: 'Box', id: 3 }, { name: 'Loose', id: 4 }, { name: 'Bundle', id: 5 }, { name: 'Can', id: 6 }, { name: 'Carton', id: 7 }, { name: 'Coil', id: 8 }, { name: 'Cylinder', id: 9 }, { name: 'Drum', id: 10 }, { name: 'Pail', id: 11 }, { name: 'Roll', id: 12 }, { name: 'Other', id: 13 }]

export const destination_services = [{ label: 'Liftgate Delivery', value: 1 }, { label: 'Inside Delivery', value: 2 }, { label: 'Appointment Delivery', value: 3 }, { label: 'Protect from Freeze', value: 4 }, { label: 'Sort and Segrate', value: 5 }, { label: 'Blind Shipping', value: 6 }]

export const shipping_destination_services = [{ label: 'Unload from truck', value: 1 }, { label: 'Yes', value: 2 }, { label: 'No', value: 3 }]

export const ship_pick_servies = [{ label: 'Liftgate Pick Up', value: 1 }, { label: 'Inside Pick Up', value: 2 }]

export const type_shipment = [{ name: 'Full Truckload', id: 1 }, { name: 'Partial /Volume', id: 2 }, { name: 'LTL', id: 3 }]

export const trailer_type = [{ name: 'Container Hauler', id: 1 }, { name: 'Flatbed', id: 2 }, { name: 'Stepdeck', id: 3 }, { name: 'Container Chassis', id: 4 }]

export const na_yes = [{ name: 'Yes', id: 1 }, { name: 'No', id: 0 }, { name: 'Not Applicable', id: 2 }]

export const yes_no = [{ name: 'Yes', id: 1 }, { name: 'No', id: 0 }]

export const type_boat = [{ name: 'Power', id: 1 }, { name: 'Sail', id: 2 }, { name: 'Other', id: 3 }]

export const temp_req = [{ name: 'Frozen', id: 1 }, { name: 'Cooler', id: 2 }]

export const degree_unit = [{ name: 'Farenheit', id: 1 }, { name: 'Celcius', id: 2 }]

export const trailer_type_requested = [{ name: 'Flatbed', id: 1 }, { name: 'Flatbed with Ramps', id: 2 }, { name: 'RGN', id: 3 }, { name: 'Step Deck', id: 4 }, { name: 'Lowboy & Double Drop', id: 5 }, { name: 'Extendable Trailer', id: 6 }, { name: '4 axles or more trailer', id: 7 }, { name: 'Other/Specialty', id: 8 }]

export const well = [{ name: 'Yes', id: 1 }, { name: 'Not Applicable', id: 2 }]

export const gender = [{ name: 'Mare', id: 1 }, { name: 'Gelding', id: 2 }, { name: 'Stallion', id: 3 }, { name: 'Filly', id: 4 }, { name: 'Colt', id: 5 }, { name: 'Mare with foal', id: 6 }, {name: 'Not Applicable', id: 7}]

export const livestock = [{ name: 'Cattle', id: 1 }, { name: 'Sheep', id: 2 }, { name: 'Swine', id: 3 }, { name: 'Goats', id: 4 }, { name: 'Poultry', id: 5 }, { name: 'Other Livestock', id: 6 }, { name: 'Horse', id: 7 }]

export const purpose = [{ name: 'Breeding', id: 1 }, { name: 'Feeding', id: 2 }, { name: 'Exhibtion/Fair', id: 3 }, { name: 'Rodeo', id: 4 }, { name: 'Racetrack', id: 5 }, { name: 'Relocation', id: 6 }, { name: 'Ownership', id: 7 }, { name: 'Slaughter/Market', id: 8 }, {name: 'Not Applicable', id: 9}]

export const animal_age = [{ name: 'Weanling', id: 1 }, { name: 'Yearling', id: 2 }, { name: 'Two Year Old', id: 3 }, { name: 'Mature Horse', id: 4 }, { name: 'Senior Horse', id: 5 }, { name: 'Over 25 Y.O.', id: 6 }, {name: 'Not Applicable', id: 7}]

export const height = [{ name: 'Mini', id: 1 }, { name: '10h - 12.2h', id: 2 }, { name: '12.3h - 14.1h', id: 3 }, { name: '14.2h - 15.3h', id: 4 }, { name: '16h - 17.1h', id: 5 }, { name: '17.2h +', id: 6 }, {name: 'Not Applicable', id: 7}]

export const transport = [{ name: 'Owner Trailer Provided', id: 1 }, { name: 'Shipper Trailer Provided', id: 2 }]

export const rv_type = [{ name: 'Class A Motorhome', id: 1 }, { name: 'Class B Motorhome', id: 2 }, { name: 'Class C Motorhome', id: 3 }, { name: 'Fifth Wheel Campers', id: 4 }, { name: 'Travel Trailer', id: 5 }, { name: 'Pop-Up Campers', id: 6 }, { name: 'Teardrop/Tiny Trailers', id: 7 }, { name: 'Other', id: 8 }]

export const mobile_type = [{ name: 'Single Wide Mobile Home', id: 1 }, { name: 'Double Wide Mobile Home', id: 2 }, { name: 'Small Home (a.k.a. tiny house)', id: 3 }, { name: 'Commercial Travel Trailers', id: 4 }, { name: 'Workshops/Garages', id: 5 }, { name: 'Modular Homes', id: 6 }, { name: 'Mobile offices (construction site, etc)', id: 7 }, {name: 'Other', id: 8}]

export const transport_option = [{ id: 'O', name: 'Open' }, { id: 'TL', name: 'Open-Top Loaded' }, { id: 'ET', name: 'Enclosed Transport' }]

export const towed_trailered = [{ name: 'Towed', id: 1 }, { name: 'Trailered', id: 2 }]

export const pet_type = [{name: 'Dog', id: 1}, {name: 'Cat', id: 2}, {name: 'Other', id: 3}]

export const physical_size = [{name: 'Small', id: 1}, {name: 'Medium', id: 2}, {name: 'Large', id: 3}]

export const is_item = [{name: 'Loose', id: 1}, {name: 'Palletized', id: 2}, {name: 'Crated', id: 3}, {name: 'Stackable', id: 4}]

export const home_type = [{name: 'Storage Facility', id: 1}, {name: '1 Bedroom Apartment', id: 2}, {name: '2-3 Bedroom Apartment', id: 3}, {name: '4+ Bedroom Apartment', id: 4}, {name: '1 Bedroom House', id: 5}, {name: '2-3 Bedroom House', id: 6}, {name: '4+ Bedroom House', id: 7}]

export const floorLoop = () => {
   let temp = []
   for(let i= 1; i< 61;i++){
      let name = 'floor ' + i
      temp.push({name, id: i})
   }
   return temp
}

export const floors = floorLoop()

export const fields = [
   { key: 'vehicle_year_text', keyName: 'Vehicle Year', type: 'text' },
   { key: 'vehicle_make_text', keyName: 'Vehicle Make', type: 'text' },
   { key: 'vehicle_model_text', keyName: 'Vehicle Model', type: 'text' },
   { key: 'vehicle_run', keyName: 'Vehicle Run', type: 'array', reference: yes_no },
   { key: 'modified', keyName: 'Modified', type: 'array', reference: yes_no },
   { key: 'convertible', keyName: 'Convertible', type: 'array', reference: yes_no },
   { key: 'transport_option', keyName: 'Transport Option', type: 'array', reference: transport_option },
   { key: 'crated_palletized', keyName: 'Crated/Palletized', type: 'array', reference: create_palette },
   { key: 'side_car', keyName: 'Sidecar', type: 'array', reference: yes_no },
   { key: 'trike', keyName: 'Trike', type: 'array', reference: yes_no },
   { key: 'is_trailer', keyName: 'On a trailer?', type: 'array', reference: yes_no },
   { key: 'type_of_shipment', keyName: 'Type of Shipment', type: 'array', reference: type_shipment },
   { key: 'new_used', keyName: 'New or Used', type: 'array', reference: new_used },
   { key: 'packaging_type', keyName: 'Packaging Type', type: 'array', reference: packaging_type },
   { key: 'stackable', keyName: 'Stackable', type: 'array', reference: yes_no },
   { key: 'hazardous', keyName: 'Hazardous', type: 'array', reference: yes_no },
   { key: 'shipping_services', keyName: 'Shipping Service', type: 'array', reference: ship_pick_servies },
   { key: 'pickup_services', keyName: 'Pick Up Service', type: 'array', reference: ship_pick_servies },
   { key: 'destination_services', keyName: 'Destination Service', type: 'array', reference: destination_services },
   { key: 'origin_can_load', keyName: 'Origin Can Load', type: 'array', reference: yes_no },
   { key: 'delivery_can_unload', keyName: 'Delivery Can Unload', type: 'array', reference: yes_no },
   { key: 'temperature_requirement', keyName: 'Temperature Requirement', type: 'array', reference: temp_req },
   { key: 'shipping_temperature_unit', keyName: 'Degree Unit', type: 'array', reference: degree_unit },
   { key: 'shipping_temperature', keyName: 'Shipping Temperature', type: 'text' },
   { key: 'vehicle_starts_moves_under_its', keyName: 'Starts and moves under own power', type: 'array', reference: na_yes },
   { key: 'trailer_type_requested', keyName: 'Trailer Type Requested', type: 'array', reference: trailer_type_requested },
   { key: 'tarps', keyName: 'Tarps', type: 'array', reference: yes_no },
   { key: 'well', keyName: 'Well', type: 'array', reference: well },
   { key: 'well_length', keyName: 'Well Length', type: 'text' },
   { key: 'permits_needed', keyName: 'Permits Needed', type: 'array', reference: yes_no },
   { key: 'rv_type', keyName: 'RV Type', type: 'array', reference: rv_type },
   { key: 'drive_away', keyName: 'Drive Away', type: 'array', reference: yes_no },
   { key: 'towed_trailered', keyName: 'Towed or Trailered', type: 'array', reference: towed_trailered },
   { key: 'mobile_building_type', keyName: 'Type of Mobile Building', type: 'array', reference: mobile_type },
   { key: 'utilities_disconnected', keyName: 'Utilities Disconnected', type: 'array', reference: yes_no },
   { key: 'skirt_removed', keyName: 'Skirt Removed', type: 'array', reference: yes_no },
   { key: 'hitch_attached', keyName: 'Hitch Attached', type: 'array', reference: yes_no },
   { key: 'tires_rims_attached', keyName: 'Tires/Rims Attached', type: 'array', reference: yes_no },
   { key: 'tires_rims_supplied_by_owner', keyName: 'Tires/Rims Supplied By Transporter', type: 'array', reference: yes_no },
   { key: 'is_trailer_registered', keyName: 'Is trailer registered?', type: 'array', reference: yes_no },
   { key: 'boat_on_trailer', keyName: 'Is your boat on a trailer?', type: 'array', reference: yes_no },
   { key: 'beam', keyName: 'Beam (inches)', type: 'text' },
   { key: 'boat_type', keyName: 'Type of Boat', type: 'array', reference: type_boat },
   { key: 'draft', keyName: 'Draft', type: 'text' },
   { key: 'is_mast', keyName: 'Mast', type: 'array', reference: well },
   { key: 'mast', keyName: 'Mast Length', type: 'text' },
   { key: 'livestock', keyName: 'Livestock', type: 'array', reference: livestock },
   { key: 'horse_livestock_gender', keyName: 'Gender', type: 'array', reference: gender },
   { key: 'horse_livestock_shipping_purpose', keyName: 'Purpose for Shipping', type: 'array', reference: purpose },
   { key: 'horse_livestock_age', keyName: 'Age', type: 'array', reference: animal_age },
   { key: 'how_many_shipment', keyName: 'How many in shipment?', type: 'text' },
   { key: 'horse_livestock_height', keyName: 'Height', type: 'array', reference: height },
   { key: 'mode_of_transporter', keyName: 'Mode of Transport', type: 'array', reference: transport },
   { key: 'medical_records_uptodate', keyName: 'Medical records up to date?', type: 'array', reference: na_yes },
   {key: 'animal_type', keyName: 'Animal Type', type: 'array', reference: pet_type},
   {key: 'animal_breed', keyName: 'Animal Breed', type: 'text'},
   {key: 'animal_name', keyName: 'Animal Name', type: 'text'},
   {key: 'physical_size', keyName: 'Physical Size', type: 'array', reference: physical_size},
   {key: 'item_number', keyName: 'Number of Items', type: 'text'},
   {key: 'elevator', keyName: 'Elevator', type: 'array', reference: yes_no},
   {key: 'apartment_home_move', keyName: 'Apartment/Home Type', type: 'array',reference: home_type},
   {key: 'pickup_on_floor', keyName: 'Pick up on floor', type: 'text'},
   {key: 'delivery_on_floor', keyName: 'Delivery floor', type: 'text'},
   {key: 'cubicles_disassembled', keyName: 'Will cubicles and other items be disassembled?', type: 'array', reference: yes_no},
   {key: 'require_disassembled', keyName: 'Do you require disassembly?', type: 'array', reference: yes_no},
   {key: 'require_reassembly', keyName: 'Do you require reassembly?', type: 'array', reference: yes_no},
   {key: 'trailer_type', keyName: 'Trailer Type', type: 'array', reference: trailer_type},
   {key: 'unload_from_truck', keyName: 'Unload From Truck', type: 'array', reference: yes_no},
]

export const setOther = (data, categoryId) => {
   return new Promise((resolve, reject) => {
      let temp = []
      Object.keys(data).map((key, i) => {
         fields.find(item => {
            if (item.key == key && item.type == 'text' && data[item.key] !== null) {
               temp.push({
                  name: item.keyName,
                  value: data[item.key] ? data[item.key] : ''
               })
            }

            else if (item.key == key && item.type == 'array' && data[item.key] !== null) {
               if (item.key == 'pickup_services' || item.key == 'destination_services' || item.key == 'shipping_services') {
                  let tempStrings = (data[item.key]).split(',')
                  let value = ''
                  for (let k = 0; k < tempStrings.length; k++) {
                     value = value + item.reference.find(obj => obj.value == tempStrings[k]).label + ', '
                  }
                  value = value.substring(0, value.length - 2)
                  let name = item.keyName;
                  if(categoryId == 5){
                     if(item.key == 'pickup_services'){
                        name = 'Origin Options'
                     }
                  }
      
                  temp.push({
                     name,
                     value
                  })
               }
               else {
                  let value = ''
                  if (item.reference.find(obj => obj.id == data[item.key]) !== undefined) {
                     value = item.reference.find(obj => obj.id == data[item.key]).name
                  }
                  temp.push({
                     name: item.keyName,
                     value
                  })
               }
            }
         })
      })
      return resolve(temp)
   })
}