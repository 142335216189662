/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS } from '../config/config';

function* setLoader(status) {
   yield put({
      type: status ? ACTIONS.LOADER.SHOW_LOADER : ACTIONS.LOADER.HIDE_LOADER,
   });
}

export function* postQuestion(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.QUESTION.POST_QUESTION, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getResponses(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.QUESTION.GET_RESPONSES, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* shipmentDetails(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.SHIPPER.SHIPMENT_DETAILS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* shipmentBids(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.SHIPPER.SHIPMENT_BIDS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* acceptRejectBid(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.SHIPPER.ACCEPT_REJECT_BID, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* mileQuotation(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.SHIPPER.MILE_QUOTATION, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   }
   catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

//carrier bids shipment list
export function* carrierBids(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.SHIPPER.CARRIER_BIDS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}
//carrier bids cluster map
export function* carrierBidsClusterMap(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.SHIPPER.CLUSTER_MAP.CARRIER_BIDS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

//Current shipment list
export function* currentShipments(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.SHIPPER.CURRENT_SHIPMENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}
//current shipment cluster map
export function* currentShipmentsClusterMap(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.SHIPPER.CLUSTER_MAP.CURRENT_SHIPMENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

//completed shipment list
export function* completedShipments(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.SHIPPER.COMPLETED_SHIPMENTS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}
//completed shipment cluster map
export function* completedShipmentClusterMap(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.SHIPPER.CLUSTER_MAP.COMPLETED_SHIPMENTS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}


//rejected shipment list
export function* rejectedShipments(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.SHIPPER.REJECTED_SHIPMENTS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}
 
export function* carrierReviewInBid(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.SHIPPER.CARRIER_REVIEW, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* logIntoApp(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.SHIPPER.LOG_INTO_APP, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* cancelShipment(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.SHIPPER.CANCEL_SHIPMENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}


export function* getTempLoadDataFromCsv(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.SHIPPER.GET_TEMP_LOAD_DATA_FROM_CSV, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* tempToLoadCron(action) {
   try {
      yield setLoader(true);
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.SHIPPER.TEMP_TO_LOAD_CRON, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

/* getDistance = ({ origin, destination }) => {
   let service = new window.google.maps.DistanceMatrixService;
   service.getDistanceMatrix({
      origins: origin,
      destinations: destination,
      travelMode: 'DRIVING',
      unitSystem: window.google.maps.UnitSystem.IMPERIAL,
      avoidHighways: false,
      avoidTolls: false
   }, function (response, status) {
      if (status !== 'OK') {
         toastr.error('Could not calculate distance')
      } else {
         let distance = response.rows[0].elements[0].distance.value
         console.log(distance)
         return distance
      }
   })
} */