/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React from 'react'
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap'

const ConfirmBox = (props) => (
   <Modal isOpen={props.isOpen} centered>
      <ModalBody>
         <Row className="text-center">
            <Col xs={12}>
               <div className="text-white">
                  <h3>{props.message}</h3>
               </div>
            </Col>
         </Row>
         <Row className="text-center">
            <Col xs={6} className="mt-4 text-right">
               <Button onClick={props.accept} color="success" className="btn-lg">Yes</Button>
            </Col>
            <Col xs={6} className="mt-4 text-left">
               <Button onClick={props.close} color="danger" className="btn-lg">No</Button>
            </Col>
         </Row>
      </ModalBody>
   </Modal>)

export default ConfirmBox