/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React from 'react'
import { connect } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap';

const Loader = (props) =>  {
      return (
         <div>
            {props.isLoading && <Modal isOpen={true} className="loader-content">
               <ModalBody>
                  <div className="loader"></div>
               </ModalBody>
            </Modal>}
         </div>

      )
   }

const mapStateToProps = (state) => {
   return {
      isLoading: state.loader.isLoading
   }
}

export default connect(mapStateToProps)(Loader)