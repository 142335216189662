/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import { reduxForm, Field, reset } from 'redux-form';
import { SAGA_ACTIONS } from '../../config/config';
import { toastr } from 'react-redux-toastr';
import { InputField, phoneFormat, getOriginalPhone } from '../../common/form-elements/elements';
import { textOnly, required, emailValidator, phoneValidator } from '../../common/validators/form-validators';

class ContactUsForm extends Component {

   submitContactForm = (values) => {
      let payload = { ...values }
      payload['phone'] = getOriginalPhone(values.phone)
      this.props.dispatch({
         type: SAGA_ACTIONS.USER.CONTACT_US,
         payload,
         callbackSuccess: res => {
            toastr.success('Success', res.data.msg)
            this.props.dispatch(reset('contactForm'))
         },
         callbackError: err => {
            toastr.error('Error', err.data.msg)
         }
      })
   }

   render() {
      const { handleSubmit } = this.props
      return (
         <div>
            <div className="log-bg" />
            <div className="box-log pt-3">
               <div className="step02 trans-box transbox-margin" style={{ minHeight: 0 }}>
                  <Form name="contactForm" onSubmit={handleSubmit(this.submitContactForm)}>
                     <div className="row">
                        <Col xs={6}>
                           <FormGroup>
                              <Label>First Name</Label>
                              <Field component={InputField} name="first_name" type="text" validate={[required, textOnly]} />
                           </FormGroup>
                        </Col>
                        <Col xs={6}>
                           <FormGroup>
                              <Label>Last Name</Label>
                              <Field component={InputField} name="last_name" type="text" validate={[required, textOnly]} />
                           </FormGroup>
                        </Col>
                     </div>
                     <div className="row">
                        <Col xs={6}>
                           <FormGroup>
                              <Label>Email</Label>
                              <Field component={InputField} name="email" type="text" validate={[required, emailValidator]} />
                           </FormGroup>
                        </Col>
                        <Col xs={6}>
                           <FormGroup>
                              <Label>Phone</Label>
                              <Field component={InputField} name="phone" type="text" validate={[required, phoneValidator]} normalize={phoneFormat} />
                           </FormGroup>
                        </Col>
                     </div>

                     <FormGroup>
                        <Label>Message</Label>
                        <Field component={InputField} name="message" type="textarea" validate={[required]} />
                     </FormGroup>
                     <div className="text-right mt-3">
                        <button type="submit" className="btn btn-primary">Submit</button>
                     </div>
                  </Form>
               </div>
            </div>
         </div>
      )
   }
}

const ContactUs = reduxForm({
   form: 'contactForm',
   destroyOnUnmount: true,
})(ContactUsForm);

export default connect()(ContactUs)