/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

function* setLoader(status) {
   yield put({
      type: status ? ACTIONS.LOADER.SHOW_LOADER : ACTIONS.LOADER.HIDE_LOADER,
   });
}

export function* loginUser(action) {
   try {
      yield setLoader(true)
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.LOGIN, data);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         localStorage.setItem(STORAGE, resp.data.token);
         yield put({
            type: ACTIONS.USER.LOGIN,
            payload: resp.data.data,
         });
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         yield setLoader(false)
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getMe(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.USER.ME, data, true);
      yield put({
         type: ACTIONS.USER.ME,
         payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp);
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}


export function* logout(action) {
   try {
      yield setLoader(true)
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.LOGOUT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         localStorage.removeItem(STORAGE);
         yield put({
            type: ACTIONS.USER.LOGOUT,
            payload: resp.data,
         });
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else{
         yield setLoader(false)
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* registerUser(action) {
   try {
      yield setLoader(true);
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.REGISTER.REGISTER_USER, data);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false);
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getOTP(action) {
   try {
      yield setLoader(true)
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.REGISTER.GET_OTP, data);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* forgotPassword(action) {
   try {
      yield setLoader(true)
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.FORGOT_PASSWORD, data);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* resetPassword(action) {
   try {
      yield setLoader(true)
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.RESET_PASSWORD, data);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* checkToken(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.CHECK_RESET_LINK, data);
      action && action.callbackSuccess && action.callbackSuccess(resp);
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getCountry(action) {
   try {
      yield setLoader(true)
      const resp = yield call(CallApi.GET, APIS.USER.REGISTER.GET_COUNTRY, null, false);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getState(action) {
   try {
      yield setLoader(true)
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.USER.REGISTER.GET_STATE, data, false);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getCity(action) {
   try {
      yield setLoader(true)
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.USER.REGISTER.GET_CITY, data, false);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateProfile(action) {
   try {
      yield setLoader(true)
      const data = action.payload;
      const resp = yield call(CallApi.POST_FORM_DATA, APIS.USER.UPDATE_PROFILE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* cardList(action) {
   try {
      const resp = yield call(CallApi.GET, APIS.USER.CARDS.CARD_LIST, null, true);
      action && action.callbackSuccess && action.callbackSuccess(resp);
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* cardRemove(action) {
   try {
      yield setLoader(true)
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.USER.CARDS.CARD_REMOVE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* cardSave(action) {
   try {
      yield setLoader(true)
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.USER.CARDS.CARD_SAVE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* cardDetails(action) {
   try {
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.USER.CARDS.CARD_DETAILS, data, true);
      action && action.callbackSuccess && action.callbackSuccess(resp);
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* cardUpdate(action) {
   try {
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.USER.CARDS.CARD_UPDATE, data, true);
      action && action.callbackSuccess && action.callbackSuccess(resp);
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* orderHistory(action) {
   try {
      yield setLoader(true)
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.USER.ORDER_HISTORY, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* contactUs(action) {
   try {
      yield setLoader(true)
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.USER.CONTACT_US, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* trackingHistory(action) {
   try {
      yield setLoader(true)
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.USER.TRACKING_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* sendLocation(action) {
   try {
      yield setLoader(true)
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.USER.SEND_TRACKING_ADD, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* confirmShipperTrack(action) {
   try {
      yield setLoader(true)
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.USER.CONFIRM_STATUS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* sendFeedback(action) {
   try {
      yield setLoader(true)
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.USER.FEEDBACK_SEND, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* sendCommonQues(action) {
   try {
      yield setLoader(true)
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.QUESTION.SEND_COMMON_MSG, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getCommonQues(action) {
   try {
      yield setLoader(true)
      const data = action.payload
      const resp = yield call(CallApi.GET, APIS.QUESTION.GET_COMMON_MSG, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* activePlan(action) {
   try {
      yield setLoader(true)
      const resp = yield call(CallApi.GET, APIS.USER.ACTIVE_PLAN, null, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}

export function* purchasePlan(action) {
   try {
      yield setLoader(true)
      const data = action.payload
      const resp = yield call(CallApi.POST, APIS.USER.PURCHASE_PLAN, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         yield setLoader(false)
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      yield setLoader(false)
      action && action.callbackError && action.callbackError(e);
   }
}
