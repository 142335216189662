/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React, { Component } from 'react'
import { SAGA_ACTIONS, ACTIONS } from '../../config/config';
import { InputField } from '../../common/form-elements/elements';
import { required, passwordMinLength6 } from '../../common/validators/form-validators';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom'

class Login extends Component {

   state = {
      ios: false,
   }

   componentDidMount() {
      if (this.props.authenticated) {
         this.props.history.push('/dashboard')
      }
      if (navigator.platform.includes('iPhone' || 'iPad' || 'iPod')) {
         this.setState({ ios: true })
      }
   }

   handleLogin = (values) => {
      return new Promise((resolve, reject) => {
         this.props.dispatch({
            type: SAGA_ACTIONS.USER.LOGIN,
            payload: values,
            callbackSuccess: res => {
               this.props.dispatch({
                  type: SAGA_ACTIONS.USER.ME,
                  callbackSuccess: (resp) => {
                     if (resp.data.data.user_type == 2) {
                        window.location.replace('/create-shipment');
                     } else {
                        window.location.replace('/dashboard');
                     }
                     //user details
                     toastr.success('Login', 'Login success');
                     //
                  },
                  callbackError: (err) => {
                     toastr.error('Error', 'Could not get user details');
                  }
               })
               resolve()
            },
            callbackError: err => {
               //login error
               toastr.error('Error', err && err.data && err.data.msg ? err.data.msg : 'Error while login')
               reject()
            }
         })
      })
   }

   setStep = () => {
      this.props.dispatch({
         type: ACTIONS.USER.REGISTER.STEP,
         payload: 'step1'
      })
   }

   render() {
      const { handleSubmit, submitting } = this.props;
      return (
         <div>
            <div className="log-bg" />
            <div className="container-fluid">
               <h1 className="logo"><img src="images/logo.png" alt="motherload logo" /></h1>
               <div className="box-log">
                  <div className="title-wrap ">
                     <h2 className="title">Login</h2>
                  </div>
                  <form onSubmit={handleSubmit(this.handleLogin)}>
                     <div className="form-group">
                        <label className="white-text">Email or Phone Number</label>
                        <Field component={InputField} name="email" type="text" validate={[required]} />
                     </div>
                     <div className="form-group">
                        <label className="white-text">Password</label>
                        <Field type="password" name="password" component={InputField} validate={[required, passwordMinLength6]} />
                     </div>
                     <div className="form-group forgot-pass-wrap">
                        <div className="row">
                           <div className="col-6 pr-0">
                              <Link to="/forgot-password" className="forgot-pass">Forgot password ?</Link>
                           </div>
                           <div className="col-6 text-right">
                              <button type="submit" className="btn btn-primary" disabled={submitting}>{submitting ? 'Submitting' : 'Submit'}</button>
                           </div>
                        </div>
                     </div>
                     <div className="register-link">
                        You don’t have an account? <Link to="/register" onClick={this.setStep}>Register now</Link>
                     </div>
                  </form>
               </div>
               {this.state.ios && <div className="box-log pwa-msg text-center mt-4 p-3">
                  <p>To add this app to the home screen, <br />Tap <img src="/images/ios-share.png" width="20px" height="20px"/> and then <strong>Add to home screen</strong></p>
               </div>}
            </div>
         </div>
      )
   }
}


const LoginForm = reduxForm({
   form: 'loginForm',
   destroyOnUnmount: true,
   enableReinitialize: true,
})(Login);

const mapStateToProps = state => {
   return {
      authenticated: state.user.isAuthenticated,
   };
}

export default connect(mapStateToProps)(LoginForm)