/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React from 'react'
import { Redirect } from 'react-router-dom'
import SideNav from '../../pages/_common/sidenav'
import { connect } from 'react-redux';

function PrivateRoute (Component, name) {
	class PrivateInnerClass extends React.Component {
		constructor(props) {
			super(props);

			localStorage.setItem('route', name)
		}
		componentDidUpdate(prevProps, prevState) {
			console.log('cdu', this.props)
		}
		render() {			
			/*if (this.props.authenticated && (!this.props.isProfileComplete)) {
				return <Redirect to="/edit-profile" />
			} else if (this.props.authenticated && (this.props.isProfileComplete)) {
				return <div><SideNav /><Component {...this.props} /></div>
			} else {
				return <Redirect to="/login" />
			}*/
			const { userType, isProfileComplete, authenticated, carrier_fees } = this.props;
			console.log(carrier_fees)
			if (authenticated) {
				if (!isProfileComplete && userType && userType == 1 && carrier_fees!=0) {
					return <Redirect to="/edit-profile" />
				} else {
					return <div><SideNav /><Component {...this.props} /></div>
				}				
			} else {
				return <Redirect to="/login" />
			}
		}
	}
	function mapStateToProps(state) {
    	return {
	      	authenticated: state.user.isAuthenticated,
			isProfileComplete: state.user.isProfileComplete,
			carrier_fees: state && state.user && state.user.user ? state.user.user.carrier_fees : 0,
			userType: state && state.user && state.user.user ? state.user.user.user_type : null
	    };
  	}

  	return connect(mapStateToProps)(PrivateInnerClass);
}

export default PrivateRoute