/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import moment from 'moment';

export const emailValidator = value =>
   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;

export const textOnly = value =>
   value && !/^[a-zA-Z ]*$/i.test(value)
      ? 'This field should contain alphabets only'
      : undefined;

export const companyCheck = value =>
   value && !/^[a-z0-9A-Z]+(([a-z0-9A-Z ])?[a-z0-9A-Z]*)*$/g.test(value)
      ? 'Invalid Name'
      : undefined;

export const numbersOnly = value =>
   value && !/^[0-9 ]*$/i.test(value)
      ? 'This field should contain numbers only'
      : undefined;

export const numbersGrt1 = value =>
   value && value <= 0
      ? 'This field should contain minimum value of 1'
      : undefined;

export const checkFloat = value =>
value && !/^(\d*\.)?\d+$/i.test(value) ? 'Please enter a valid number' : value && parseFloat(value) <=0 ? 'Number should be greater than 0' : undefined

export const checkTemp = value => value && !/([-]{1})?(\d*\.)?\d+$/i.test(value) ? 'Invalid Temperature' : undefined

export const noSpecialChars = value =>
   value && !/^[0-9a-zA-Z]*$/i.test(value)
      ? 'This field should not contain special characters'
      : undefined;

export const permittedLength = value =>
   value && !(value.length > 1 && value.length <= 255)
      ? 'Title should contain 255 characters max'
      : undefined;

export const required = value =>
   value && value != undefined && ((typeof value != 'string') || !!value.trim())
      ? undefined
      : 'This field is required';

export const required_select = value => (value && typeof (value) === 'string' && value.length ? undefined : 'This field is required');

export const imageIsRequired = value => (!value ? "This field is required" : undefined);

export const passwordMinLength6 = value =>
   value && value.length < 6 ? 'Password should be minimum 6 chars' : undefined;

export const passwordStrength = value => value && (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/).test(value) ? undefined : (!value) ? undefined : 'Password format invalid'

export const phoneValidator = value =>
  !/^[0-9]{6,13}$/g.test(value) ? 'Invalid phone number' : undefined;

export const USphoneValidator = value =>
  value[1] == 0 ? 'Phone cannot start with 0' : !/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/g.test(value) ? 'Invalid phone number' : undefined;

export const usZipCodeValidator = value =>
   !/^[a-zA-Z0-9]{3,7}$/g.test(value) ? 'Invalid Zip code' : undefined

export const passwordsMatch = (value, allValues) =>
   value !== allValues.password ? 'Passwords don\'t match' : undefined;

export const requiredDate = value => (value && moment(value).isValid() ? undefined : 'Valid Date required');

export const checkLen3 = value =>
   value && (value.length > 2 && value.length < 5) ? undefined : 'CVV should be 3 or 4 digits';

export const checkLen16 = value =>
   value && value.length <= 14 ? 'Card Number should be at least 14 digits' : undefined;

export const noteCheckForForm = value => {
   if(!value){
      return undefined
   }
   let emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g
   let numberRegex = /\d{8,}/g
   let websiteRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g
   let specialCharsRegex = /[@#$%^&*()":{}|<>]/g

   let numberCheck = value && value.search(numberRegex)
   let emailCheck = value && value.search(emailRegex)
   let websiteCheck = value && value.search(websiteRegex)
   let specialCharsCheck = value && value.search(specialCharsRegex)
   
   return (numberCheck === -1 && emailCheck === -1 && websiteCheck === -1 && specialCharsCheck === -1) ? undefined : 'This field cannot contain personal information'
}

export const noteValidate = value => {
   if(!value){
      return true
   }

   let emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g
   let numberRegex = /\d+/g
   let websiteRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g
   let specialCharsRegex = /[@#$%^&*()":{}|<>]/g

   let numberCheck = value && value.search(numberRegex)
   let emailCheck = value && value.search(emailRegex)
   let websiteCheck = value && value.search(websiteRegex)
   let specialCharsCheck = value && value.search(specialCharsRegex)

   return (numberCheck === -1 && emailCheck === -1 && websiteCheck === -1 && specialCharsCheck === -1) ? true : false
}

export const petWeight = (value) => parseFloat(value) <= 250 ? undefined : 'Weight should be less than 250 lbs'

export const lim50 = value => (parseInt(value) >= 1 && parseInt(value) <= 50) ? undefined : 'Between 1 and 50 items only'

export const lim60 = value => (parseInt(value) >= 1 && parseInt(value) <= 60) ? undefined : 'Between 1 and 60 floors only'

export const limChars = value => value.length > 255 ? 'Maximum 255 characters' : undefined