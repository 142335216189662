/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

/* eslint eqeqeq: 0 */

import React, { Component } from 'react'
import { SAGA_ACTIONS } from '../../../config/config'
import { connect } from 'react-redux'
import { Modal, ModalBody, Col, Button, Row, Card, CardBody, ModalHeader, Label, FormGroup, Form } from 'reactstrap'
import { reduxForm, Field, reset, change } from 'redux-form'
import AskQuestion from '../askQuestion'
import Pagination from "react-js-pagination";
import { toastr } from 'react-redux-toastr'
import moment from 'moment'
import RouteMap from '../routeMap'
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';
import { numbersOnly, required, checkLen3, checkLen16, textOnly, usZipCodeValidator, emailValidator } from '../../../common/validators/form-validators';
import { phoneFormat, InputField, DropDownSelect, TestSelect, normalizeCard, rawMask } from '../../../common/form-elements/elements'
import ReactStars from 'react-rating-stars-component'
import InfoModal from '../../infoModal'
import PublicChat from '../publicChat'
import { transport_option, setOther, is_item } from '../../../config/fieldsConfig'
import ConfirmBox from '../../_common/confirmBox'

class ShipmentDetails extends Component {
   state = {
      shipmentDetails: {},
      bidList: [],
      note: '',
      page: 1,
      total: 10,
      per_page: 2,
      last_page: 1,
      ids: {},
      confirmType: '',
      bidId: '',
      questionModal: false,
      openNotes: false,
      confirmModal: false,
      publicChatModal: false,
      infoModal: false,
      showSlideModal: false,
      transport_option,
      activeIndex: 0,
      sliderImage: [],
      bid_amount: '',
      carrierReviews: [],
      otherDetails: [],
      showReview: false,
      carrierId: '',
      reviewLastPage: '',
      cardList: [],
      cardFormModal: false,
      month_dropdown: [],
      country_list: [],
      state_list: [],
      city_list: [],
      year_dropdown: [],
      catIds: [5, 6, 14, 15, 16],
      card_added: false,
      showCreditCardForm: true,
      cancelBox: false,
      isLogged: false,
      showConfirmModal: false
   }

   componentDidMount() {
      this.setData()
   }

   setData = () => {
      this.getShipment().then(res => {
         let sliderImage = res.data.data && res.data.data.media_size_vehicle_small_image_url
         let other_details = res.data.data ? res.data.data.other_details : {}
         let shipmentDetails = res.data.data
         delete shipmentDetails[res.data.data.media_size_vehicle_small_image_url]
         delete shipmentDetails[res.data.data.other_details]

         this.setState({
            shipmentDetails,
            sliderImage,
            isLogged: (res.data.data.enable_for_outside) ? true : false
         })

         this.getBids();
         this.setExpiry();
         this.getCounrty();
         this.getCardList();
         if (other_details) {
            setOther(other_details, res.data.data.category_id).then(res => {
               this.setState({ otherDetails: res })
            })
         }
      })
   }

   setExpiry = () => {
      let tempMonth = []
      let tempYear = []

      for (let m = 1; m < 13; m++) {
         m = m.toString().length == 1 ? '0' + m.toString() : m
         tempMonth.push({ id: m, name: m })
      }

      for (let y = new Date().getFullYear(); y < new Date().getFullYear() + 10; y++) {
         tempYear.push({ id: y, name: y })
      }

      this.setState({
         month_dropdown: tempMonth,
         year_dropdown: tempYear
      })
   }

   getCounrty = () => {
      this.props.dispatch({
         type: SAGA_ACTIONS.USER.REGISTER.GET_COUNTRY,
         callbackSuccess: res => {
            this.setState({
               country_list: res.data.data
            })
         },
         callbackError: err => {
            console.log(err)
         }
      })
   }

   getCardList = () => {
      this.props.dispatch({
         type: SAGA_ACTIONS.USER.CARDS.CARD_LIST,
         callbackSuccess: res => {
            let card_list = [];
            for (let i = 0; i < res.data.data.length; i++) {
               card_list.push({ id: res.data.data[i].id, name: 'XXXX XXXX XXXX ' + res.data.data[i].last_four_digit })
            }
            this.setState({
               cardList: card_list
            })
         },
         callbackError: err => {
            toastr.error('Error', err.data.msg)
         }
      })
   }

   changehandleFunc() {
      this.props.dispatch(change('creditCardForm', 'state', ''));
      this.props.dispatch(change('creditCardForm', 'city', ''));
   }

   handleDropdown = (e, dropdown) => {
      if (dropdown === 'country') {
         this.setState({
            state_list: [],
            city_list: [],
            zone_code: this.state.country_list.filter(country => country.value == e.value)[0].zone_code
         }, () => this.changehandleFunc())
      }
      this.props.dispatch({
         type: dropdown === 'country' ? SAGA_ACTIONS.USER.REGISTER.GET_STATE : SAGA_ACTIONS.USER.REGISTER.GET_CITY,
         payload: { id: e.value },
         callbackSuccess: res => {
            dropdown === 'country' ? this.setState({ state_list: res.data.data }) : this.setState({ city_list: res.data.data })
         }
      })
   }

   saveCard = (values) => {
      return new Promise((resolve, reject) => {
         let payload = { ...values };

         //if (this.state && this.state.cardList && !this.state.cardList.length) {
         payload = { ...values, is_default: 1 }
         /*}
         else {
            payload = { ...values, is_default: 0 }
         }*/

         payload['country'] = payload.country.label
         payload['state'] = payload.state.label
         payload['city'] = payload.city.label
         payload['card_number'] = payload.card_number.split(' ').join('')

         this.props.dispatch({
            type: SAGA_ACTIONS.USER.CARDS.CARD_SAVE,
            payload,
            callbackSuccess: res => {
               toastr.success('Success', res.data.msg)
               this.setState({ cardFormModal: false, card_added: true })
               this.props.dispatch(reset('creditCardForm'));
               this.acceptBid();
               resolve(true)
            },
            callbackError: err => {
               toastr.error('Error', err.data.msg)
               reject(false)
            }
         })
      })
   }

   processShipment = (values) => {
      return new Promise((resolve, reject) => {
         let payload = { ...values };
         payload = { ...values, is_default: 1 }
         payload['id'] = payload.card_id
         this.props.dispatch({
            type: SAGA_ACTIONS.USER.CARDS.CARD_UPDATE,
            payload,
            callbackSuccess: res => {
               this.setState({ cardFormModal: false })
               this.acceptBid();
            },
            callbackError: err => {
               toastr.error('Error', err.data.msg)
               reject(false)
            }
         })
      })
   }

   getShipment = () => {
      return new Promise((resolve, reject) => {
         this.props.dispatch({
            type: SAGA_ACTIONS.SHIPPER.SHIPMENT_DETAILS,
            payload: { id: this.props.match.params.id },
            callbackSuccess: res => {
               return resolve(res)
            },
            callbackError: err => {
               return reject(err)
            }
         })
      })
   }

   getBids = () => {
      this.props.dispatch({
         type: SAGA_ACTIONS.SHIPPER.SHIPMENT_BIDS,
         payload: {
            shipment_id: this.state.shipmentDetails.id,
            page: this.state.page
         },
         callbackSuccess: res => {
            let { shipmentDetails } = this.state
            let tempBids = []
            if (shipmentDetails.status == 4 || shipmentDetails.status == 5 || shipmentDetails.status == 7) {
               tempBids = res.data.data.data.filter(bid => bid.status == 1)
            }
            else if ((shipmentDetails.status == 1 || shipmentDetails.status == 2) && moment(shipmentDetails.expire_on).diff(new Date(), 'days') >= 0) {
               tempBids = res.data.data.data.filter(bid => {
                  if (bid.expiration_type == 2) {
                     return bid.status == 0 && (moment(bid.expiration_date).diff(new Date(), 'days') >= 0)
                  }
                  else {
                     return bid.status == 0 && (moment(shipmentDetails.expiration_on).diff(new Date(), 'days') >= 0)
                  }
               })
            }

            this.setState({
               bidList: tempBids,
               per_page: parseInt(res.data.data.per_page),
               last_page: parseInt(res.data.data.last_page),
               total: res.data.data.total
            })
         },
         callbackError: err => {
            this.setState({ bidList: [] })
         }
      })
   }

   handlePageChange = (page) => {
      this.setState({ page }, () => this.getBids())
   }

   toggleModal = (name, type) => {
      this.setState({ [name]: type })
   }

   checkCard = () => {
      /*if(this.props.isProfileComplete) {
         this.acceptBid(); 
      } else if(this.state.card_added) {
         this.acceptBid(); 
      } else {*/
      this.setState({ cardFormModal: true })
      //}
   }

   acceptBid = () => {
      this.props.dispatch({
         type: SAGA_ACTIONS.SHIPPER.ACCEPT_REJECT_BID,
         payload: { id: this.state.bidId, status: this.state.confirmType },
         callbackSuccess: res => {
            toastr.success('Success', res.data.msg)
            this.setState({
               confirmModal: false,
               page: 1
            })
            this.setData()
         },
         callbackError: err => {
            toastr.error('Error', err.data.msg)
         }
      })
   }

   confirm = (type, bidId) => {
      this.setState({
         confirmType: type,
         confirmModal: true,
         bidId,
         bid_amount: this.state.bidList.find(item => item.id == bidId).bids,
         markup_amount: this.state.bidList.find(item => item.id == bidId).markup_calculation
      })
   }

   handleNotes = (noteId) => {
      let bid = this.state.bidList.find(bid => bid.id == noteId)
      this.setState({
         note: bid.note,
         openNotes: true
      })
   }

   askQuestion = (bid_id) => {
      let bid = this.state.bidList.find(bid => bid.id == bid_id)
      let ids = { shipment_id: bid.shipment_id, bid_id }
      this.setState({ ids, questionModal: true })
   }

   next = () => {
      const nextIndex = this.state.activeIndex === this.state.sliderImage.length - 1 ? 0 : this.state.activeIndex + 1;
      this.setState({ activeIndex: nextIndex });
   }

   previous = () => {
      const nextIndex = this.state.activeIndex === 0 ? this.state.sliderImage.length - 1 : this.state.activeIndex - 1;
      this.setState({ activeIndex: nextIndex });
   }

   getInfo = (type) => {
      this.setState({ infoModal: type })
   }

   goToTracking = () => {
      this.props.history.push(`/tracking/${this.state.shipmentDetails.unique_id}`)
   }

   publicChatModal = () => {
      this.setState({ publicChatModal: !this.state.publicChatModal })
   }

   cancelModal = () => {
      this.setState({ cancelBox: !this.state.cancelBox })
   }

   setReivewId = (id) => {
      this.setState({
         showReview: true,
         carrierId: id
      }, () => this.getReviews())
   }

   getReviews = (page = 1) => {
      this.props.dispatch({
         type: SAGA_ACTIONS.SHIPPER.CARRIER_REVIEW,
         payload: { carrier_id: this.state.carrierId, page },
         callbackSuccess: res => {
            this.setState({
               carrierReviews: res.data.data.data,
               reviewLastPage: res.data.data.last_page
            })
         },
         callbackError: err => {
            this.setState({
               carrierReviews: [],
               showReview: true
            })
         }
      })
   }

   showSlideImage = () => {
      this.setState({ showSlideModal: true })
   }

   saveLog = (id, status) => {
      this.props.dispatch({
         type: SAGA_ACTIONS.SHIPPER.LOG_INTO_APP,
         payload: { id: id, status: status },
         callbackSuccess: res => {
            toastr.success('Success', res.data.msg)
            if (status) {
               this.setState({ isLogged: true })
            } else {
               this.setState({ isLogged: false })
            }
            
         },
         callbackError: err => {
            toastr.error('Error', err.data.msg)
         }
      })
   }

   cancelShipment = () => {
      this.setState({ showConfirmModal: true })
   }

   callapi = (id) => {
      this.props.dispatch({
         payload: { id: id },
         type: SAGA_ACTIONS.SHIPPER.CANCEL_SHIPMENT,
         callbackSuccess: res => {
            toastr.success('Success', res.data.message)
            this.setState({ showConfirmModal: false })
            setTimeout(() => {
               this.setData()    
            }, 500)                 
         },
         callbackError: err => {
            toastr.error('Error', err.data.msg)
         }
      })
   }   

   render() {
      const { shipmentDetails } = this.state
      const { handleSubmit } = this.props
      {console.log(shipmentDetails);}
      return (
         <div>
            <div className="log-bg" />
            <div className="box-log pt-3">
               <div className="accordion custom-accordion" id="accordionExample">
                  <div className="d-flex justify-content-between">
                     <h5 className="white-text">{shipmentDetails.category && shipmentDetails.category.name} {((shipmentDetails.status == 1 || shipmentDetails.status == 2) && ((shipmentDetails.status == 1 || shipmentDetails.status == 2) && moment(shipmentDetails.expire_on).diff(new Date(), 'days') >= 0)) ?
                     <button type="button" className="btn text-white btn-danger btn-sm" onClick={() => this.cancelShipment()}>Cancel Shipment</button> : null}
                     </h5>                     
                  </div>
                  <div className="info-btn text-right">
                     <button className="btn text-white watch pull-left" onClick={() => (!this.state.isLogged) ? this.saveLog(shipmentDetails.id, 1) : this.saveLog(shipmentDetails.id, 0)} type="button">{!this.state.isLogged ? 'Log Into App' : 'Logged Into App'} {this.state.isLogged ? <img src="/images/tick.png" alt="Tick" /> : ''}</button>
                     <button className="btn white-text" onClick={() => this.getInfo(true)} type="button"><i className="fa fa-question-circle" /></button>
                  </div>
                  <div className="card">
                     <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                           <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              Routing &amp; Locations
                           </button>
                        </h2>
                     </div>

                     <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                           <div className="row-bids">
                              <span className="orange-text">Origin: </span><h5>{shipmentDetails.pickup_address}</h5>
                              <span className="orange-text">Origin Type:</span><h5>{shipmentDetails.pickup && shipmentDetails.pickup.location}</h5>
                              <span className="orange-text">Zip Code: </span><h5>{shipmentDetails.origin_zipcode}</h5>
                           </div>
                           <hr />
                           <div className="row-bids">
                              <span className="blue-text">Destination: </span><h5>{shipmentDetails.destination_address}</h5>
                              <span className="blue-text">Destination Type: </span><h5>{shipmentDetails.destination && shipmentDetails.destination.location}</h5>
                              <span className="blue-text">Zip Code: </span><h5>{shipmentDetails.delivery_zipcode}</h5>
                           </div>
                           <hr />
                           <div className="row-bids">
                              Distance: <h5>{shipmentDetails.distance} mi</h5>
                           </div>
                           <div className="row-bids">
                              <div className="route-map-wrap">
                                 <RouteMap origin={{ lat: shipmentDetails.pickup_address_latitude, lng: shipmentDetails.pickup_address_longitude }}
                                    destination={{ lat: shipmentDetails.destination_address_latitude, lng: shipmentDetails.destination_address_longitude }}
                                    delivery_zipcode={{ delivery_zipcode: shipmentDetails.delivery_zipcode }}
                                    origin_zipcode={{ origin_zipcode: shipmentDetails.origin_zipcode }}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Shipment Listing
                           </button>
                        </h2>
                     </div>
                     <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div className="card-body">
                           <div className="row-bids">
                              <p><strong>Shipment Id:</strong> #{shipmentDetails.master_code}</p>
                           </div>
                           <div className="row-bids">
                              <p><strong>Date Posted:</strong> {moment(shipmentDetails.created_at).format('MM-DD-YYYY')}</p>
                           </div>
                           <div className="row-bids">
                              <p><strong>Ready Date:</strong> {moment(shipmentDetails.ready_dt).format('MM-DD-YYYY')}</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-header" id="headingThree">
                        <h2 className="mb-0">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              Shipment Pictures
                           </button>
                        </h2>
                     </div>
                     <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                        <div className="card-body">
                           <div className="row-bids slider">
                              {this.state.sliderImage.length ?
                                 <Carousel
                                    activeIndex={this.state.activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                    interval={false}
                                 >
                                    {this.state.sliderImage.length ? this.state.sliderImage.map((slide, i) => <CarouselItem
                                       key={i}>
                                       <div className="carousel-image-wrap" onClick={this.showSlideImage}>
                                          <img src={slide} alt={'carousel-image'} />
                                       </div>
                                    </CarouselItem>) : ''}
                                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                 </Carousel> : <strong>No image to show</strong>}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-header" id="headingFour">
                        <h2 className="mb-0">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                              Shipment Information : {((shipmentDetails.status == 1 || shipmentDetails.status == 2) && ((shipmentDetails.status == 1 || shipmentDetails.status == 2) && moment(shipmentDetails.expire_on).diff(new Date(), 'days') >= 0)) ? null : <span> {(shipmentDetails.status == 3 || ((shipmentDetails.status == 1 || shipmentDetails.status == 2) && moment(shipmentDetails.expire_on).diff(new Date(), 'days') < 0)) ? 'Expired' : shipmentDetails.status == 4 ? 'Booked' : shipmentDetails.status == 5 ? 'Completed' : shipmentDetails.status == 6 ? 'Cancelled' : 'Issue Arised'}</span>}
                           </button>
                        </h2>
                     </div>
                     <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                        <div className="card-body">
                           <div className="row-bids-other">
                              {this.state.otherDetails.length ? this.state.otherDetails.map((item, i) => <div key={i}>
                                 <h5><span>{item.name}</span> <em>{item.value}</em></h5>
                              </div>) : null}
                              {shipmentDetails.dimension_weight && shipmentDetails.dimension_weight.length ? shipmentDetails.dimension_weight.map((obj, i) =>
                                 <div key={i}>
                                    {shipmentDetails.category && (this.state.catIds.includes(shipmentDetails.category.id)) && <h5><span className="font-weight-bold">Package #{i + 1} </span> <em></em></h5>}
                                    {obj.title && <h5><span>Title</span><em>{obj.title}</em></h5>}
                                    {obj.item && <h5><span>Is your item</span><em>{is_item.find(temp => temp.id == obj.item).name}</em></h5>}
                                    {obj.length && <h5><span>Length (inches)</span><em>{obj.length}</em></h5>}
                                    {obj.width && <h5><span>Width (inches)</span> <em>{obj.width}</em></h5>}
                                    {obj.height && <h5><span>Height (inches)</span> <em>{obj.height}</em></h5>}
                                    {obj.weight && <h5><span>Weight (lbs)</span><em>{obj.weight}</em></h5>}
                                    {obj.inner_item_count && <h5><span>How many items?</span><em>{obj.inner_item_count}</em></h5>}
                                    {obj.note && <h5><span>Note</span><em>{obj.note}</em></h5>}
                                 </div>) : null}
                           </div>
                           <div className="d-flex flex-column text-left">
                              <span className="spl-ins">Special Instructions:</span>
                              <h6 className="mt-1">{shipmentDetails.special_instruction ? shipmentDetails.special_instruction : 'No special instructions'}</h6>
                           </div>
                        </div>
                     </div>
                  </div>
                  {shipmentDetails.shipment_type == 1 && <div className="mt-2 ml-3 text-white">
                     <h6><span>Amount paid:</span> <span>${(parseFloat(shipmentDetails.shipment_payment && shipmentDetails.shipment_payment.markup_calculation) + parseFloat(shipmentDetails.shipment_payment && shipmentDetails.shipment_payment.shipping_insurance_cost)).toFixed(2)}</span></h6>
                     <h6><span>Amount to be paid to carrier:</span> <span>${(parseFloat(shipmentDetails.shipment_payment && shipmentDetails.shipment_payment.quotes_cost) + parseFloat(shipmentDetails.shipment_payment && shipmentDetails.shipment_payment.upcharge_cost)).toFixed(2)}</span></h6></div>}

                  {(shipmentDetails.status == 1 || shipmentDetails.status == 2) && <div className="text-center">
                     <button className="btn btn-motherload w-100 mb-2 mt-2" onClick={() => this.publicChatModal(true)}>Public Questions</button>
                     {/* <button className="btn btn-danger w-100 mb-2 mt-2" onClick={this.cancelModal}>Cancel Shipment</button> */}
                  </div>}
                  {this.state.bidList.length > 1 &&
                     <div className="card-bids-heading">List of bids from carriers</div>}
                  <div className="current-bids-wrap">
                     {this.state.bidList.length ? this.state.bidList.map((bid, i) =>
                        <Card key={i}>
                           <CardBody>
                              <div className="d-flex justify-content-between">
                                 <h5>Bid #{i + 1} <span>{(bid.user.number_of_shipment >= 0 && bid.user.number_of_shipment <= 5) ? 'Bronze' : (bid.user.number_of_shipment >= 6 && bid.user.number_of_shipment <= 20) ? 'Silver' : (bid.user.number_of_shipment >= 21 && bid.user.number_of_shipment <= 200) ? 'Gold' : 'Platinum'}</span>
                                    <span onClick={() => this.setReivewId(bid.user.id)} style={{ backgroundColor: '#e46400', cursor: 'pointer' }}>Reviews</span>
                                 </h5>
                                 <ReactStars
                                    count={5}
                                    half={true}
                                    value={bid.user.rating}
                                    edit={false}
                                 />
                              </div>
                              <div className="d-none">
                                 <h6>Schedule</h6>
                                 <div className="row" style={{ padding: '0 20px 0 20px' }}>
                                    <div className="col-xs-6 mr-5">
                                       <p><strong>Pickup Date:</strong></p>
                                       <p>{moment(new Date()).format('MM-DD-YYYY')}</p>
                                    </div>
                                    <div className="col-xs-6">
                                       <p><strong>Drop Date:</strong></p>
                                       <p>{this.state.shipmentDetails.request_delivery_dt}</p>
                                    </div>
                                 </div>
                              </div>
                              {shipmentDetails.shipment_type == 2 &&
                                 <div>
                                    <div className="bid-wrapper d-flex justify-content-between">
                                       <h6>Bid</h6>
                                       <h6>${(parseFloat(bid.bids) + parseFloat(bid.markup_calculation)).toFixed(2)}</h6>
                                    </div>
                                    <div className="bid-wrapper d-flex justify-content-between">
                                       <h6>Pickup within (days)</h6>
                                       <h6>{bid.pickup_within_day}</h6>
                                    </div>
                                    <div className="bid-wrapper d-flex justify-content-between">
                                       <h6>Deliver Within (days)</h6>
                                       <h6>{bid.delivery_within_day}</h6>
                                    </div>
                                    {bid.expiration_type == 1 &&
                                       <div className="bid-wrapper d-flex justify-content-between">
                                          <h6>Cancel at load expiration</h6>
                                          <h6 style={{ whiteSpace: 'nowrap' }}>{moment(shipmentDetails.expire_on).format('DD-MM-YYYY')}</h6>
                                       </div>}
                                    {bid.expiration_type == 2 &&
                                       <div className="bid-wrapper d-flex justify-content-between">
                                          <h6>Cancel on Date</h6>
                                          <h6>{moment(bid.expiration_date).format('MM-DD-YYYY')}</h6>
                                       </div>}
                                 </div>}
                              {(shipmentDetails.status == 4 || shipmentDetails.status == 5 || shipmentDetails.status == 7) &&
                                 <div>
                                    <div className="bid-wrapper d-flex justify-content-between">
                                       <div>
                                          <h6>Name</h6>
                                          <h6>{bid.user.first_name} {bid.user.last_name}</h6>
                                          <h6>{bid.user.carrier_type && (`(${bid.user.carrier_type == 1 ? 'Pro' : bid.user.carrier_type == 2 ? 'Broker' : 'Independent'})`)}</h6>
                                       </div>
                                       <img src={bid.user && bid.user.media_size_image_url ? bid.user.media_size_image_url : '/images/avatar.png'} alt="shipper profile pic" height={'60px'} className="mb-2" />
                                    </div>
                                    <div className="bid-wrapper d-flex justify-content-between">
                                       <h6>Phone</h6>
                                       <a href={`tel:${bid.user.phone}`} className="phone-link">{rawMask(bid.user.phone.toString(), bid.user.country_details.phone_code_format)}</a>
                                    </div>
                                    {<div className="bid-wrapper d-block">
                                       <h6>Email</h6>
                                       <h6 className="user-email w-100">{bid.user.email}</h6>
                                    </div>}
                                 </div>}

                              {this.state.shipmentDetails.shipment_type == 2 &&
                                 <div className="note-wrap">
                                    <div className="btns-wrapper">
                                       <div className="row">
                                          {(bid.status == 0 || bid.status == 2) && <div className="col-6 pr-1">
                                             <button className="btn btn-success w-100" onClick={() => this.confirm(1, bid.id)}>Accept <i className="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                          </div>}
                                          {bid.status == 0 && <div className="col-6 pl-1">
                                             <button className="btn btn-danger w-100" onClick={() => this.confirm(2, bid.id)}>Reject <i className="fa fa-times-circle-o" aria-hidden="true"></i></button>
                                          </div>}
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col pr-1">
                                          <button className="btn btn-motherload w-100" onClick={() => this.askQuestion(bid.id)}>Ask <i className="fa fa-question-circle-o"></i></button>
                                       </div>
                                       <div className="col pl-1">
                                          <button className="btn btn-dark w-100" onClick={() => this.handleNotes(bid.id)}>Notes <i className="fa fa-file-text-o"></i></button>
                                       </div>
                                    </div>
                                 </div>}

                              {(shipmentDetails.status == 4 || shipmentDetails.status == 5 || shipmentDetails.status == 7) &&
                                 <div className="row">
                                    {shipmentDetails.shipment_type == 1 && <div className="col-sm-6 pl-3 mt-1">
                                       <button className="btn btn-motherload w-100" onClick={() => this.askQuestion(bid.id)}>Ask <i className="fa fa-question-circle-o"></i></button>
                                    </div>}
                                    <div className={shipmentDetails.shipment_type == 1 ? "col-sm-6 pl-3 mt-1" : 'col-sm-12'}>
                                       <button className="btn btn-primary w-100" onClick={this.goToTracking}>Track <i className="fa fa-truck fa-flip-horizontal" /></button>
                                    </div>
                                 </div>}
                           </CardBody>
                        </Card>
                     ) : ''}

                     {shipmentDetails.shipment_type == 2 && !this.state.bidList.length &&
                        <div className="className=step02 trans-box transbox-margin text-white remove-min-max">
                           <h4 className="text-white no-info">Currently you have no bids on this shipment</h4>
                        </div>}

                     {shipmentDetails.shipment_type == 1 && (shipmentDetails.status == 1 || shipmentDetails.status == 2) &&
                        <div className="className=step02 trans-box transbox-margin text-white remove-min-max">
                           <h4 className="text-white no-info">No carrier has approved this shipment yet</h4>
                        </div>}
                     {this.state.last_page > 1 ? <div className="pagination">
                        <Pagination
                           activePage={this.state.page}
                           itemsCountPerPage={this.state.per_page}
                           totalItemsCount={this.state.total}
                           pageRangeDisplayed={5}
                           onChange={this.handlePageChange}
                           itemClass="page-item"
                           linkClass="page-link"
                        />
                     </div> : ''}
                  </div>
                  <ConfirmModal
                     open={this.state.confirmModal}
                     close={() => this.toggleModal('confirmModal', false)}
                     accept={(this.state.confirmType == 1) ? this.checkCard : this.acceptBid}
                     insurance={shipmentDetails && shipmentDetails.shipment_payment && shipmentDetails.shipment_payment.shipping_insurance_cost}
                     bid_amount={this.state.bid_amount}
                     markup_amount={this.state.markup_amount}
                     confirmType={this.state.confirmType}
                  />
                  {this.state.questionModal && <AskQuestion close={() => this.toggleModal('questionModal', false)} ids={this.state.ids} />}
                  < NotesModal open={this.state.openNotes} close={() => this.toggleModal('openNotes', false)} notes={this.state.note} />
                  <InfoModal isOpen={this.state.infoModal} close={() => this.getInfo(false)} id={7} />
                  <ConfirmBox isOpen={this.state.cancelBox} close={this.cancelModal} message={'Are you sure?'} accept={this.cancelShipment} />
                  {this.state.publicChatModal && <PublicChat close={() => this.publicChatModal(false)} ids={this.state.shipmentDetails.id} />}
                  {this.state.showReview && <ReviewsModal close={() => this.setState({ showReview: false })} reviewList={this.state.carrierReviews} getReview={this.getReviews} last_page={this.state.reviewLastPage} />}
                  {this.state.showSlideModal && <SliderImg img={shipmentDetails && shipmentDetails.media_size_vehicle_image_url[this.state.activeIndex]} open={true} close={() => this.setState({ showSlideModal: false })} />}
               </div>
            </div>
            <Modal isOpen={this.state.cardFormModal} size="md" centered>
               <ModalBody className="credit-card-modal">
                  {!this.state.showCreditCardForm &&
                     <Form name="creditCardForm" onSubmit={handleSubmit(this.saveCard)}>
                        <FormGroup>
                           <Label>Card Number</Label>
                           <Field component={InputField} name="card_number" type="tel" validate={[required, numbersOnly, checkLen16]} normalize={normalizeCard} />
                        </FormGroup>

                        <FormGroup>
                           <Label>Name on Card</Label>
                           <Field component={InputField} name="card_name" type="text" validate={[required, textOnly]} />
                        </FormGroup>
                        <Row>
                           <Col xs={4}>
                              <FormGroup>
                                 <Label>MM</Label>
                                 <Field name="expiry_month" type="select" component={DropDownSelect} validate={[required]} options={this.state.month_dropdown} />
                              </FormGroup>
                           </Col>
                           <Col xs={4}>
                              <FormGroup>
                                 <Label>YYYY</Label>
                                 <Field name="expiry_year" type="select" component={DropDownSelect} validate={[required]} options={this.state.year_dropdown} />
                              </FormGroup>
                           </Col>
                           <Col xs={4}>
                              <FormGroup>
                                 <Label>CVV</Label>
                                 <Field component={InputField} name="card_code" type="password" validate={[required, numbersOnly, checkLen3]} maxLength={4} />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col sm={12}>
                              <h5 className="text-white">Billing Details</h5>
                           </Col>
                        </Row>
                        <Row>
                           <Col sm={12}>
                              <FormGroup>
                                 <Label>Billing Email</Label>
                                 <Field component={InputField} name="billing_email" type="text" validate={[required, emailValidator]} />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col sm={6}>
                              <FormGroup>
                                 <Label>Country</Label>
                                 <Field name="country" type="select" component={TestSelect} validate={[required]} options={this.state.country_list} onChange={(e) => this.handleDropdown(e, 'country')} />
                              </FormGroup>
                           </Col>
                           <Col sm={6}>
                              <FormGroup>
                                 <Label>State</Label>
                                 <Field name="state" type="select" component={TestSelect} validate={[required]} options={this.state.state_list} onChange={this.handleDropdown} />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col sm={6}>
                              <FormGroup>
                                 <Label>City</Label>
                                 <Field name="city" type="select" component={TestSelect} validate={[required]} options={this.state.city_list} />
                              </FormGroup>
                           </Col>
                           <Col sm={6}>
                              <FormGroup>
                                 <Label>Postal Code</Label>
                                 <Field component={InputField} name="postal_code" type="text" validate={[required, usZipCodeValidator]} maxLength={7} />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col sm={12}>
                              <FormGroup>
                                 <Label>Address</Label>
                                 <Field type="textarea" name="address" component={InputField} validate={[required]} />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={6}>
                              <Button className="w-100" color="danger" onClick={() => this.toggleModal('showCreditCardForm', true)}>Cancel</Button>
                           </Col>
                           <Col xs={6}>
                              <Button className="w-100" color="primary" type="submit">Save Card</Button>
                           </Col>
                        </Row>
                     </Form>
                  }
                  {this.state.showCreditCardForm &&
                     <Form name="creditCardForm" onSubmit={handleSubmit(this.processShipment)}>
                        <FormGroup>
                           <Label>Select Your Card from the list Dropdown</Label>
                           <Field component={DropDownSelect} name="card_id" type="select" validate={[required]} options={this.state.cardList} />
                        </FormGroup>
                        <Row>
                           <Col sm={12}>
                              <h5 className="text-white text-center">Or </h5>
                              <Button className="w-100" color="primary" onClick={() => this.toggleModal('showCreditCardForm', false)}>Add New Card</Button>
                           </Col>
                        </Row>
                        <Row>
                           <Col xs={6}>
                              <Button className="w-100" color="danger" onClick={() => this.setState({ cardFormModal: false })}>Cancel</Button>
                           </Col>
                           <Col xs={6}>
                              <Button className="w-100" color="success" type="submit">Ok</Button>
                           </Col>
                        </Row>
                     </Form>
                  }
               </ModalBody>
            </Modal>
            <Modal isOpen={this.state.showConfirmModal} centered>
               <ModalBody>                  
                  <Row className="text-center">
                     <Col xs={12}>
                        <h3 className="white-text">Are you sure?</h3>
                     </Col>
                     <Col xs={6} className="mt-4 text-right">
                        <Button color="success" onClick={() => this.callapi(this.state.shipmentDetails.id)} className="btn-lg">Yes</Button>
                     </Col>
                     <Col xs={6} className="mt-4 text-left">
                        <Button onClick={() => this.setState({ showConfirmModal: false })} color="danger" className="btn-lg">No</Button>
                     </Col>
                  </Row>
               </ModalBody>
            </Modal>
         </div>
      )
   }
}

const Cards = reduxForm({
   form: 'creditCardForm',
   destroyOnUnmount: true,
   enableReinitialize: true,
})(ShipmentDetails);

function mapStateToProps(state) {
   return {
      isProfileComplete: state && state.user && state.user.isProfileComplete
   };
}

export default connect(mapStateToProps)(Cards)

const ConfirmModal = (props) =>
   <Modal isOpen={props.open} centered>
      <ModalBody>
         <Row className="text-center white-text">
            <Col xs={12}>
               <h6>Amount due now<span className="confirm-price"> ${(parseFloat(props.markup_amount) + parseFloat(props.insurance)).toFixed(2)}</span></h6>
               <h6>Amount due to Carrier<span className="confirm-price"> ${props.bid_amount}</span></h6>
            </Col>
            <Col xs={12} className="mt-3">
               <h3 className="white-text">{props.confirmType == 1 ? 'Accept' : 'Reject'} bid and continue?</h3>
            </Col>
            <Col xs={6} className="mt-4 text-right">
               <Button onClick={props.accept} color="success" className="btn-lg">Yes</Button>
            </Col>
            <Col xs={6} className="mt-4 text-left">
               <Button onClick={props.close} color="danger" className="btn-lg">No</Button>
            </Col>
         </Row>
      </ModalBody>
   </Modal>

class ReviewsModal extends Component {
   state = {
      showMoreBtn: false,
      page: 1,
      list: []
   }

   componentDidUpdate(prevProps) {
      if (prevProps.reviewList !== this.props.reviewList) {
         this.setState({ list: [...this.state.list, ...this.props.reviewList] })
      }
   }

   getMoreReview = () => {
      var objDiv = document.getElementById("reviews")
      objDiv.scrollTop = objDiv.scrollHeight - objDiv.offsetHeight - 80
      this.props.getReview(this.state.page + 1)
      this.setState({ page: this.state.page + 1 })
   }

   handleScroll = () => {
      var myElement = document.getElementById('reviews');
      var topPos = myElement.scrollTop;
      if (this.state.page !== this.props.last_page) {
         if (topPos === (myElement.scrollHeight - myElement.offsetHeight)) {
            this.setState({ showMoreBtn: true })
         }
      }
      else {
         this.setState({ showMoreBtn: false })
      }
   }

   render() {
      return (
         <Modal isOpen={true} centered>
            <ModalHeader toggle={this.props.close} className="text-white modal-cross">
               Reviews
            </ModalHeader>
            <ModalBody>
               <div id="reviews" className="white-text text-center" style={{ height: '50vh', overflowY: 'auto' }} onScroll={this.handleScroll}>
                  {this.state.list.length ? this.state.list.map((review, i) => <div className="single-review mb-4" key={i}>
                     {<h6>{review.message}</h6>}
                  </div>) : <h6>No Reviews</h6>}
                  {this.state.showMoreBtn && <button className="btn btn-primary mt-2" onClick={this.getMoreReview}>Show More Reviews</button>}
               </div>
            </ModalBody>
         </Modal>
      )
   }
}

const NotesModal = (props) =>
   <Modal isOpen={props.open} className="more-notes" centered>
      <ModalHeader toggle={() => props.close()} className="text-white modal-cross">
         Notes
      </ModalHeader>
      <ModalBody>
         <p>{props.notes ? props.notes : 'No notes written by transporter'}</p>
      </ModalBody>
   </Modal>

const SliderImg = (props) =>
   <Modal isOpen={props.open} centered size={'md'}>
      <ModalHeader toggle={() => props.close()} className="text-white modal-cross">
         &nbsp;
      </ModalHeader>
      <ModalBody className="text-center">
         <div className="modal-img-wrap">
            <img src={props.img} />
         </div>
      </ModalBody>
   </Modal>