/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';


function PublicRoute (Component, props) {
	class PublicInnerClass extends React.Component {
		constructor(props) {
			super(props);
		}
		componentDidUpdate(prevProps, prevState) {
		}
		render() {
			if (this.props.authenticated) {
				return <Redirect to="/dashboard" />
			} else {
				return <Component {...props} />
			}
		}
	}
	function mapStateToProps(state) {
    	return {
	      	authenticated: state.user.isAuthenticated,
	    };
  	}

  	return connect(mapStateToProps)(PublicInnerClass);
}

export default PublicRoute