/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux'
import { change } from 'redux-form';

class LocationSearchInput extends React.Component {
   constructor(props) {
      super(props);
      this.state = { address: '' };
   }

   handleChange = address => {
      const { input } = this.props;
      const { onChange } = input;
      this.setState({ address });
      onChange(address);
      if (this.props.type === 'pickup') {
         this.props.dispatch(change('instantShippingRates', 'pickup_coords', ''))
      }
      else if (this.props.type === 'destination') {
         this.props.dispatch(change('instantShippingRates', 'destination_coords', ''))
      }
   };

   handleSelect = address => {
      this.setState({ address })
      const { input } = this.props;
      const { onChange } = input;
      onChange(address);
      this.props.getCoords && this.props.getCoords(address);
   };

   render() {
      const {
         input: { value, onChange, onKeyDown },
         meta: { touched, error, warning },
      } = this.props
      return (
         <PlacesAutocomplete
            value={this.props.input.value}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            onError={() => toastr.error('Error', 'No results found')}
         >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
               <div className="add_input">
                  <div className="d-flex">
                     <input
                        {...getInputProps({
                           placeholder: 'Search Places',
                           className: 'form-control',
                        })}
                     />
                     {loading && <div className="text-white spinner-border ml-2" />}
                  </div>
                  {touched &&
                     (error && (
                        <p className="error-msg">{error}</p>
                     ))}
                  <div className="autocomplete-dropdown-container black-text">
                     {suggestions.map((suggestion, i) => {
                        const className = suggestion.active
                           ? 'suggestion-item--active'
                           : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                           ? { backgroundColor: 'rgb(38,132,255)', color: '#fff', cursor: 'pointer' }
                           : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                           <div key={i}
                              {...getSuggestionItemProps(suggestion, {
                                 className,
                                 style,
                              })}
                           >
                              <span>{suggestion.description}</span>
                           </div>
                        );
                     })}
                  </div>
               </div>
            )}
         </PlacesAutocomplete>
      );
   }
}

export default connect()(LocationSearchInput)