/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import { takeLatest, all } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../config/config';
import {
   loginUser,
   logout,
   getMe,
   registerUser,
   resetPassword,
   forgotPassword,
   getOTP,
   checkToken,
   getCountry,
   getState,
   getCity,
   updateProfile,
   cardList,
   cardSave,
   cardDetails,
   cardRemove,
   cardUpdate,
   orderHistory,
   contactUs,
   trackingHistory,
   sendLocation,
   confirmShipperTrack,
   sendFeedback,
   sendCommonQues,
   getCommonQues,
   activePlan,
   purchasePlan
} from './user.saga';

import {
   createShipment,
   locationTypes,
   timeframes,
   categories,
   vehicleYear,
   vehicleMake,
   vehicleModel,
   shipmentList,
   bidDetails,
   submitBid,
   saveWatch,
   updateBid,
   editBid,
   accRejQuote,
   availableBids,
   availableClusterMap,
   myBids,
   myBidsClusterMap,
   watchedLists,
   watchedListsClusterMap,
   completedBids,
   completedBidsClusterMap,
   rejectedBids,
   rejectedBidsClusterMap,
   saveMultiSettings,
   getMultiSettings,
   getCountryPhoneFormat,
   saveCarrierPreferences,
} from './carrier.saga'

import {
   postQuestion,
   getResponses,
   shipmentDetails,
   shipmentBids,
   acceptRejectBid,
   currentShipments,
   mileQuotation,
   currentShipmentsClusterMap,
   completedShipments,
   completedShipmentClusterMap,
   carrierBids,
   carrierBidsClusterMap,
   carrierReviewInBid,
   logIntoApp,
   cancelShipment,
   getTempLoadDataFromCsv,
   tempToLoadCron,
} from './shipper.saga'

import { terms, blockInformation } from './info.saga';

export function* sagas() {
   yield all([
      //Auth  
      takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
      takeLatest(SAGA_ACTIONS.USER.LOGOUT, logout),
      takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, resetPassword),
      takeLatest(SAGA_ACTIONS.USER.CHECK_RESET_LINK, checkToken),
      takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, forgotPassword),

      //Register
      takeLatest(SAGA_ACTIONS.USER.REGISTER.REGISTER_USER, registerUser),
      takeLatest(SAGA_ACTIONS.USER.REGISTER.GET_OTP, getOTP),
      takeLatest(SAGA_ACTIONS.USER.REGISTER.GET_COUNTRY, getCountry),
      takeLatest(SAGA_ACTIONS.USER.REGISTER.GET_STATE, getState),
      takeLatest(SAGA_ACTIONS.USER.REGISTER.GET_CITY, getCity),

      //Profile
      takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE, updateProfile),
      takeLatest(SAGA_ACTIONS.USER.ME, getMe),
      takeLatest(SAGA_ACTIONS.USER.ORDER_HISTORY, orderHistory),
      takeLatest(SAGA_ACTIONS.USER.CONTACT_US, contactUs),
      takeLatest(SAGA_ACTIONS.USER.ACTIVE_PLAN, activePlan),
      takeLatest(SAGA_ACTIONS.USER.PURCHASE_PLAN, purchasePlan),

      //tracking
      takeLatest(SAGA_ACTIONS.USER.TRACKING_LIST, trackingHistory),
      takeLatest(SAGA_ACTIONS.USER.SEND_TRACKING_ADD, sendLocation),
      takeLatest(SAGA_ACTIONS.USER.CONFIRM_STATUS, confirmShipperTrack),
      takeLatest(SAGA_ACTIONS.USER.FEEDBACK_SEND, sendFeedback),

      //User Credit Cards
      takeLatest(SAGA_ACTIONS.USER.CARDS.CARD_LIST, cardList),
      takeLatest(SAGA_ACTIONS.USER.CARDS.CARD_DETAILS, cardDetails),
      takeLatest(SAGA_ACTIONS.USER.CARDS.CARD_SAVE, cardSave),
      takeLatest(SAGA_ACTIONS.USER.CARDS.CARD_REMOVE, cardRemove),
      takeLatest(SAGA_ACTIONS.USER.CARDS.CARD_UPDATE, cardUpdate),

      //booking
      takeLatest(SAGA_ACTIONS.BOOKING.LOCATION_TYPES, locationTypes),
      takeLatest(SAGA_ACTIONS.BOOKING.TIME_FRAMES, timeframes),
      takeLatest(SAGA_ACTIONS.BOOKING.CATEGORIES, categories),

      //year make model
      takeLatest(SAGA_ACTIONS.BOOKING.VEHICLE_YEAR, vehicleYear),
      takeLatest(SAGA_ACTIONS.BOOKING.VEHICLE_MAKE, vehicleMake),
      takeLatest(SAGA_ACTIONS.BOOKING.VEHICLE_MODEL, vehicleModel),

      takeLatest(SAGA_ACTIONS.BOOKING.CREATE_SHIPMENT, createShipment),
      takeLatest(SAGA_ACTIONS.BOOKING.GET_COUNTRY_PHONE_FORMAT, getCountryPhoneFormat),

      //Carrier
      takeLatest(SAGA_ACTIONS.CARRIER.AVAILABLE_BIDS, availableBids),
      takeLatest(SAGA_ACTIONS.CARRIER.CLUSTER_MAP.AVAILABLE_BIDS, availableClusterMap),
      takeLatest(SAGA_ACTIONS.CARRIER.MY_BIDS, myBids),
      takeLatest(SAGA_ACTIONS.CARRIER.CLUSTER_MAP.MY_BIDS, myBidsClusterMap),
      takeLatest(SAGA_ACTIONS.CARRIER.WATCHED_LISTS, watchedLists),
      takeLatest(SAGA_ACTIONS.CARRIER.CLUSTER_MAP.WATCHED_LISTS, watchedListsClusterMap),
      takeLatest(SAGA_ACTIONS.CARRIER.COMPLETED_BIDS, completedBids),
      takeLatest(SAGA_ACTIONS.CARRIER.CLUSTER_MAP.COMPLETED_BIDS, completedBidsClusterMap),
      takeLatest(SAGA_ACTIONS.CARRIER.REJECTED_BIDS, rejectedBids),
      takeLatest(SAGA_ACTIONS.CARRIER.CLUSTER_MAP.REJECTED_BIDS, rejectedBidsClusterMap),

      takeLatest(SAGA_ACTIONS.CARRIER.LIST, shipmentList),
      takeLatest(SAGA_ACTIONS.CARRIER.BID_DETAILS, bidDetails),
      takeLatest(SAGA_ACTIONS.CARRIER.SUBMIT_BID, submitBid),
      takeLatest(SAGA_ACTIONS.CARRIER.SAVE_WATCH, saveWatch),
      takeLatest(SAGA_ACTIONS.CARRIER.EDIT_BID, editBid),
      takeLatest(SAGA_ACTIONS.CARRIER.UPDATE_BID, updateBid),
      takeLatest(SAGA_ACTIONS.CARRIER.ACC_REJ_QUOTE, accRejQuote),

      takeLatest(SAGA_ACTIONS.CARRIER.MULTI_SELECT.SAVE_SETTINGS, saveMultiSettings),
      takeLatest(SAGA_ACTIONS.CARRIER.MULTI_SELECT.GET_SETTINGS, getMultiSettings ),

      takeLatest(SAGA_ACTIONS.CARRIER.SAVE_CARRIER_PREFERENCES, saveCarrierPreferences),


      //Shipper
      takeLatest(SAGA_ACTIONS.SHIPPER.CARRIER_BIDS, carrierBids),
      takeLatest(SAGA_ACTIONS.SHIPPER.CLUSTER_MAP.CARRIER_BIDS, carrierBidsClusterMap),
      takeLatest(SAGA_ACTIONS.SHIPPER.CURRENT_SHIPMENT, currentShipments),
      takeLatest(SAGA_ACTIONS.SHIPPER.CLUSTER_MAP.CURRENT_SHIPMENT, currentShipmentsClusterMap),
      takeLatest(SAGA_ACTIONS.SHIPPER.COMPLETED_SHIPMENTS, completedShipments),
      takeLatest(SAGA_ACTIONS.SHIPPER.CLUSTER_MAP.COMPLETED_SHIPMENTS, completedShipmentClusterMap),
      takeLatest(SAGA_ACTIONS.SHIPPER.SHIPMENT_DETAILS, shipmentDetails),
      takeLatest(SAGA_ACTIONS.SHIPPER.SHIPMENT_BIDS, shipmentBids),
      takeLatest(SAGA_ACTIONS.SHIPPER.ACCEPT_REJECT_BID, acceptRejectBid),
      takeLatest(SAGA_ACTIONS.SHIPPER.MILE_QUOTATION, mileQuotation),
      takeLatest(SAGA_ACTIONS.SHIPPER.CARRIER_REVIEW, carrierReviewInBid),
      takeLatest(SAGA_ACTIONS.SHIPPER.LOG_INTO_APP, logIntoApp),
      takeLatest(SAGA_ACTIONS.SHIPPER.CANCEL_SHIPMENT, cancelShipment),
      takeLatest(SAGA_ACTIONS.SHIPPER.GET_TEMP_LOAD_DATA_FROM_CSV, getTempLoadDataFromCsv),
      takeLatest(SAGA_ACTIONS.SHIPPER.TEMP_TO_LOAD_CRON, tempToLoadCron),

      //questions
      takeLatest(SAGA_ACTIONS.QUESTION.POST_QUESTION, postQuestion),
      takeLatest(SAGA_ACTIONS.QUESTION.GET_RESPONSES, getResponses),
      takeLatest(SAGA_ACTIONS.QUESTION.SEND_COMMON_MSG, sendCommonQues),
      takeLatest(SAGA_ACTIONS.QUESTION.GET_COMMON_MSG, getCommonQues),

      //info
      takeLatest(SAGA_ACTIONS.INFO.TERMS, terms),
      takeLatest(SAGA_ACTIONS.INFO.BLOCK_INFO, blockInformation),
   ]);
}