/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React, { Component} from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import {Jumbotron} from 'reactstrap'
import { InputField } from '../../common/form-elements/elements';
import { required, passwordsMatch, passwordStrength } from '../../common/validators/form-validators';
import { SAGA_ACTIONS } from '../../config/config';
import { toastr } from 'react-redux-toastr';

class ResetPassword extends Component {
   state = {
      isTokenValid: true
   }

   componentDidMount() {
      localStorage.removeItem('eh-ui-auth-key')
      this.checkToken()
   }

   checkToken = () => {
      this.props.dispatch({
         type: SAGA_ACTIONS.USER.CHECK_RESET_LINK,
         payload: { token: this.props.match.params.token },
         callbackError: err => {
            this.setState({
               isTokenValid: false
            })
            toastr.error('Error', err.data.msg)
         }
      })
   }

   handleReset = (values) => {
      return new Promise((resolve, reject) => {
         this.props.dispatch({
            type: SAGA_ACTIONS.USER.RESET_PASSWORD,
            payload: { ...values, token: this.props.match.params.token },
            callbackSuccess: res => {
               resolve(toastr.success('Success', res.data.msg))
               this.props.history.push('/login')
            },
            callbackError: err => {
               reject(toastr.error('Error', err.data.msg))
            }
         })
      })
   }

   render() {
      const { handleSubmit, submitting } = this.props;
      return (
            <div>
               <div className="log-bg" />
               <div className="container-fluid">
                  <h1 className="logo"><img src="/images/logo.png" alt="motherload logo" /></h1>
                  <div className="box-log">
                     <div className="title-wrap ">
                        <h2 className="title">Reset Password</h2>
                     </div>
                     {this.state.isTokenValid ?
                        <form onSubmit={handleSubmit(this.handleReset)} name="resetPasswordForm">
                           <div className="form-group">
                              <label>Password</label>
                              <Field type="password" name="password" component={InputField} validate={[required, passwordStrength]} />
                           </div>
                           <div className="form-group">
                              <label>Confirm Password</label>
                              <Field type="password" name="confirm_password" component={InputField} validate={[required, passwordsMatch]} />
                           </div>
                           <small style={{ color: 'white' }}>
                           <b>Password must</b> <br />
                           1. Be more than 6 characters long <br />
                           2. Contain at least one Uppercase letter<br />
                           3. Contain at least one Lower case letter <br/>
                           4. Contain at least one numeric value <br />
                           5. Contain at least one special character  
                        </small>
                           <div className="form-group forgot-pass-wrap">
                              <div className="row">
                                 <div className="col-12 text-right">
                                    <button type="submit" className="btn btn-primary" disabled={submitting}>{submitting ? 'Submitting' : 'Submit'}</button>
                                 </div>
                              </div>
                           </div>
                        </form> : <Jumbotron style={{backgroundColor: '#e9ecef57'}}>
                           <h5 style={{ color: '#fff' }}>Invalid Link!</h5>
                        </Jumbotron>}
                     <div className="register-link">
                        Back To <Link to="/login">Login</Link>
                     </div>
                  </div>
               </div>
            </div>
      )
   }
}

const ResetPasswordForm = reduxForm({
   form: 'resetPasswordForm',
   destroyOnUnmount: true,
   enableReinitialize: true,
})(ResetPassword);


export default connect()(withRouter(ResetPasswordForm))