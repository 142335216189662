/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

/* eslint eqeqeq: 0 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SAGA_ACTIONS } from '../../../config/config'
import { toastr } from 'react-redux-toastr'
import RouteMap from '../routeMap'
import moment from 'moment'
import { Carousel, CarouselItem, CarouselControl, Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import BidModal from './bidModal'
import InfoModal from '../../infoModal'
import PublicChat from '../publicChat'
import { transport_option, setOther, is_item } from '../../../config/fieldsConfig'

class BiddingDetails extends Component {
   state = {
      shipment: {},
      bidderId: '',
      bid_status: '',
      isChecked: '',
      transport_option,
      questionModal: false,
      activeIndex: 0,
      sliderImage: [],
      bidFormModal: false,
      showConfirm: false,
      isAccept: '',
      infoModal: false,
      publicChatModal: false,
      otherDetails: [],
      catIds: [5, 6, 14, 15, 16],
      isWatching: false
   }

   componentDidMount() {
      this.setData()
   }

   setData = () => {
      this.getDetails().then(res => {
         let sliderImage = res.data.data && res.data.data.media_size_vehicle_small_image_url
         let other_details = res.data.data ? res.data.data.other_details : {}
         let shipment = res.data.data
         delete shipment[res.data.data.media_size_vehicle_small_image_url]
         delete shipment[res.data.data.other_details]
         this.setState({
            shipment,
            sliderImage,
            status: 0,
            isWatching: (res.data.data.watch) ? true : false
         })
         if (other_details) {
            setOther(other_details, res.data.data.category_id).then(res => {
               this.setState({ otherDetails: res })
            })
         }
      })
   }

   getDetails = () => {
      return new Promise((resolve, reject) => {
         this.props.dispatch({
            type: SAGA_ACTIONS.CARRIER.BID_DETAILS,
            payload: { id: this.props.match.params.id },
            callbackSuccess: res => {
               return resolve(res)
            },
            callbackError: err => {
               toastr.error('Could not get details')
               return reject(false)
            }
         })
      })
   }

   submitBid = (values) => {
      let payload = { ...values }

      if (values.expiration_date) {
         payload['expiration_date'] = moment(values.expiration_date).format('YYYY-MM-DD')
      }

      payload['shipment_id'] = this.state.shipment.id

      this.props.dispatch({
         type: SAGA_ACTIONS.CARRIER.SUBMIT_BID,
         payload,
         callbackSuccess: res => {
            toastr.success('Success', res.data.msg)
            setTimeout(() => {
               this.props.history.replace(`/update-bid/${res.data.data.unique_id}`)
            }, 500);
         },
         callbackError: err => {
            toastr.error('Error', err.data.msg)
         }
      })
   }

   handleChange = (e) => {
      this.setState({
         formValues: { ...this.state.formValues, [e.target.name]: e.target.value }
      })
   }

   handleChecked = (e) => {
      this.setState({
         isChecked: e.target.value, formValues: { ...this.state.formValues, expiration: '' }
      })
   }

   questionModal = (type) => {
      this.setState({ questionModal: type })
   }

   publicChatModal = () => {
      this.setState({ publicChatModal: !this.state.publicChatModal })
   }

   formModal = (type) => {
      this.setState({ bidFormModal: type })
   }

   next = () => {
      const nextIndex = this.state.activeIndex === this.state.sliderImage.length - 1 ? 0 : this.state.activeIndex + 1;
      this.setState({ activeIndex: nextIndex });
   }

   previous = () => {
      const nextIndex = this.state.activeIndex === 0 ? this.state.sliderImage.length - 1 : this.state.activeIndex - 1;
      this.setState({ activeIndex: nextIndex });
   }

   acceptRejectQuote = (type) => {
      this.props.dispatch({
         type: SAGA_ACTIONS.CARRIER.ACC_REJ_QUOTE,
         payload: { shipment_id: this.state.shipment.id, status: type },
         callbackSuccess: res => {
            toastr.success('Success', res.data.msg)
            setTimeout(() => {
               this.props.history.replace(`/update-bid/${res.data.data.unique_id}`)
            }, 500);
         },
         callbackError: err => {
            toastr.error('Error', err.data.msg)
         }
      })
   }

   showConfirm = (e) => {
      this.setState({ showConfirm: true, isAccept: e.target.value })
   }

   getInfo = (type) => {
      this.setState({ infoModal: type })
   }

   showSlideImage = () => {
      this.setState({ showSlideModal: true })
   }

   saveWatch = (id) => {
      this.props.dispatch({
         type: SAGA_ACTIONS.CARRIER.SAVE_WATCH,
         payload: { shipment_id: id },
         callbackSuccess: res => {
            toastr.success('Success', res.data.msg)
            this.setState({ isWatching: true })
         },
         callbackError: err => {
            toastr.error('Error', err.data.msg)
         }
      })
   }

   render() {
      const { shipment } = this.state

      return (
         <div>
            <div className="log-bg" />
            <div className="box-log pt-3 mb-3">
               <div className="accordion custom-accordion" id="accordionExample">
                  <h5 className='white-text'>{shipment.category && shipment.category.name}</h5>
                  <div className="info-btn text-right">
                     <button className="btn text-white watch pull-left" style={this.state.isWatching ? { cursor: 'not-allowed' } : {}} onClick={() => (!this.state.isWatching) ? this.saveWatch(shipment.id) : ''} type="button">{!this.state.isWatching ? 'Watch Shipment' : 'Watched'} {this.state.isWatching ? <img src="/images/tick.png" alt="Tick" /> : ''}</button>
                     <button className="btn white-text" onClick={() => this.getInfo(true)} type="button"><i className="fa fa-question-circle" /></button>
                  </div>
                  <div className="card">
                     <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                           <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              Routing &amp; Locations
                           </button>
                        </h2>
                     </div>

                     <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                           <div className="row-bids">
                              <span className="orange-text">Origin: </span><h5>{shipment.pickup_city}, {shipment.pickup_state}</h5>
                              <span className="orange-text">Origin Type: </span><h5>{shipment.pickup && shipment.pickup.location}</h5>
                              {/* Zip Code: <h5>{shipment.origin_zipcode}</h5> */}
                           </div>
                           <hr />
                           <div className="row-bids">
                              <span className="blue-text">Destination: </span><h5>{shipment.destination_city}, {shipment.destination_state}</h5>
                              <span className="blue-text">Destination Type: </span><h5>{shipment.destination && shipment.destination.location}</h5>
                              {/* Zip Code: <h5>{shipment.delivery_zipcode}</h5> */}
                           </div>
                           <hr />
                           <div className="row-bids">
                              Distance: <h5>{shipment.distance} mi</h5>
                           </div>
                           <div className="row-bids">
                              <div className="route-map-wrap">
                                 <RouteMap
                                    origin={{ lat: shipment.pickup_address_latitude, lng: shipment.pickup_address_longitude }}
                                    destination={{ lat: shipment.destination_address_latitude, lng: shipment.destination_address_longitude }}
                                    delivery_zipcode={{ delivery_zipcode: shipment.delivery_zipcode }}
                                    origin_zipcode={{ origin_zipcode: shipment.origin_zipcode }}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Shipment Listing
                           </button>
                        </h2>
                     </div>
                     <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div className="card-body">
                           <div className="row-bids">
                              <p><strong>Shipment Id:</strong> #{shipment.master_code}</p>
                           </div>
                           <div className="row-bids">
                              <p><strong>Date Posted:</strong> {moment(shipment.created_at).format('MM-DD-YYYY')}</p>
                           </div>
                           <div className="row-bids">
                              <p><strong>Ready Date:</strong> {moment(shipment.ready_dt).format('MM-DD-YYYY')}</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-header" id="headingThree">
                        <h2 className="mb-0">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              Shipment Pictures
                           </button>
                        </h2>
                     </div>
                     <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                        <div className="card-body">
                           <div className="row-bids slider">
                              {this.state.sliderImage.length ?
                                 <Carousel
                                    activeIndex={this.state.activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                    interval={false}
                                 >
                                    {this.state.sliderImage.length ? this.state.sliderImage.map((slide, i) => <CarouselItem
                                       key={i}
                                    >
                                       <div className="carousel-image-wrap" onClick={this.showSlideImage}>
                                          <img src={slide} alt={'carousel-image'} />
                                       </div>
                                    </CarouselItem>) : ''}
                                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                 </Carousel> : <strong>No image to show</strong>}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                     <div className="card-header" id="headingFour">
                        <h2 className="mb-0">
                           <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                              Shipment Information
                           </button>
                        </h2>
                     </div>
                     <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                        <div className="card-body">
                           <div className="row-bids-other">
                              {this.state.otherDetails.length ? this.state.otherDetails.map((item, i) => <div key={i}>
                                 <h5><span>{item.name}</span> <em>{item.value}</em></h5>
                              </div>) : null}
                              {shipment.dimension_weight && shipment.dimension_weight ? shipment.dimension_weight.map((obj, i) => <div key={i}>
                                 {shipment.category && (this.state.catIds.includes(shipment.category.id)) && <h5><span className="font-weight-bold">Package #{i + 1} </span> <em></em></h5>}
                                 {obj.title && <h5><span>Title</span><em>{obj.title}</em></h5>}
                                 {obj.item && <h5><span>Is your item</span><em>{is_item.find(temp => temp.id == obj.item).name}</em></h5>}
                                 {obj.length && <h5><span>Length (inches)</span><em>{obj.length}</em></h5>}
                                 {obj.width && <h5><span>Width (inches)</span> <em>{obj.width}</em></h5>}
                                 {obj.height && <h5><span>Height (inches)</span> <em>{obj.height}</em></h5>}
                                 {obj.weight && <h5><span>Weight (lbs)</span><em>{obj.weight}</em></h5>}
                                 {obj.inner_item_count && <h5><span>How many items?</span><em>{obj.inner_item_count}</em></h5>}
                                 {obj.note && <h5><span>Note</span><em>{obj.note}</em></h5>}
                              </div>) : null}
                           </div>
                           <div className="d-flex flex-column text-left">
                              <span className="spl-ins">Special Instructions:</span>
                              <h6 className="mt-1">{shipment.special_instruction ? shipment.special_instruction : 'No special instructions'}</h6>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               {((shipment.status === 1 || shipment.status === 2) && moment(shipment.expire_on).diff(new Date()) >= 0) ?
                  shipment.shipment_type == 2 ?
                     <div className="className=step02 trans-box transbox-margin text-white remove-min-max text-center">
                        <button className="btn btn-primary" onClick={() => this.formModal(true)} style={{ width: '40%' }}>Bid</button>
                     </div>
                     :
                     <div className="step02 trans-box transbox-margin text-white remove-min-max">
                        {shipment.shipment_type == 1 && <h4>Accept Rate : <span>${(parseFloat(shipment.shipment_payment.quotes_cost) + parseFloat(shipment.shipment_payment.upcharge_cost)).toFixed(2)}
                        </span></h4>}
                        <div className="mt-3">
                           <div className="d-flex justify-content-between">
                              <button className="btn btn-danger" onClick={this.showConfirm} value="2" style={{ padding: '0.375rem 16px' }}>Reject</button>
                              <button className="btn btn-success" onClick={this.showConfirm} value="1">Accept</button>
                           </div>
                        </div>
                     </div>
                  : null}
               <button className="btn btn-info mt-2 w-100" onClick={() => this.publicChatModal(true)}>Ask a question</button>
            </div>
            <BidModal isOpen={this.state.bidFormModal} onSubmit={this.submitBid} close={() => this.formModal(false)} />
            <InfoModal isOpen={this.state.infoModal} close={() => this.getInfo(false)} id={2} />
            {this.state.publicChatModal && <PublicChat close={() => this.publicChatModal(false)} ids={this.state.shipment.id} />}
            <ConfirmModal
               confAccept={() => this.acceptRejectQuote(this.state.isAccept)}
               isAccept={this.state.isAccept}
               close={() => this.setState({ showConfirm: false })}
               isOpen={this.state.showConfirm}
            />
            {this.state.showSlideModal && <SliderImg img={shipment && shipment.media_size_vehicle_image_url[this.state.activeIndex]} open={true} close={() => this.setState({ showSlideModal: false })} />}
         </div>
      )
   }
}

const mapStateToProps = state => {
   return {
      userId: state.user && state.user.user.id
   }
}

export default connect(mapStateToProps)(BiddingDetails)

const ConfirmModal = (props) =>
   <Modal isOpen={props.isOpen} centered>
      <ModalBody>
         <Row className="text-center text-white">
            <Col xs={12}>
               <h3 className="text-white">Are you sure to continue?</h3>
            </Col>
            <Col xs={6} className="mt-4 text-right">
               <Button onClick={props.confAccept} color="success" className="btn-lg">Yes</Button>
            </Col>
            <Col xs={6} className="mt-4 text-left">
               <Button onClick={props.close} color="danger" className="btn-lg">No</Button>
            </Col>
         </Row>
      </ModalBody>
   </Modal>

const SliderImg = (props) =>
   <Modal isOpen={props.open} centered size="md">
      <ModalHeader toggle={() => props.close()} className="text-white modal-cross">
         &nbsp;
   </ModalHeader>
      <ModalBody className="text-center">
         <div className="modal-img-wrap">
            <img src={props.img} />
         </div>
      </ModalBody>
   </Modal>