/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React, { Component } from 'react'
import { Form, Modal, ModalBody, Input, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux'
import { SAGA_ACTIONS } from '../../config/config'
import { toastr } from 'react-redux-toastr'
import { noteValidate } from '../../common/validators/form-validators'
import moment from 'moment'
import './askQuestion.css'

class AskQuestion extends Component {
   state = {
      question: '',
      showPrev: false,
      docSize: '',
      page: 1,
      ids: {},
      answer: []
   }

   componentDidMount() {
      this.getResponses()
   }

   getResponses = () => {
      this.props.dispatch({
         type: SAGA_ACTIONS.QUESTION.GET_RESPONSES,
         payload: { bid_id: this.props.ids.bid_id },
         callbackSuccess: res => {
            /* let temp = [...this.state.answer]
            temp.unshift(...res.data.data) */
            this.setState({
               answer: res.data.data,
               docSize: res.data.data.length
            }, () => {
               var objDiv = document.getElementById("messages");
               objDiv.scrollTop = objDiv.scrollHeight
            })
         },
         callbackError: err => {
            this.setState({
               //answer: [],
               docSize: ''
            })
         }
      })
   }

   submitQuestion = (e) => {
      e.preventDefault()
      let payload = {
         reviews: this.state.question, ...this.props.ids
      }

      if (noteValidate(this.state.question)) {
         payload['regex_match'] = 0
         payload['status'] = 1
      }
      else {
         payload['regex_match'] = 1
         payload['status'] = 0
      }

      this.props.dispatch({
         type: SAGA_ACTIONS.QUESTION.POST_QUESTION,
         payload,
         callbackSuccess: res => {
            let temp = [...this.state.answer]
            temp.push({ ...res.data.data })
            this.setState({
               answer: temp,
               question: ''
            })
            var objDiv = document.getElementById("messages");
            objDiv.scrollTop = objDiv.scrollHeight
         },
         callbackError: err => {
            toastr.error('Error', err.data.msg)
         }
      })
   }

   render() {
      const { user } = this.props
      return (
         <div>
            <Modal isOpen={true} className="ask-question-modal" centered>
               <ModalHeader toggle={this.props.close} className="text-white modal-cross">
                  Ask Question
               </ModalHeader>
               <ModalBody>
                  <div>
                     <div className="messaging mb-2">
                        <div className="inbox_msg">
                           <div className="mesgs">
                              <div className="type_msg mb-3">
                                 <div className="input_msg_write">
                                    <Form onSubmit={this.submitQuestion}>
                                       <Input type="textarea" onChange={(e) => this.setState({ question: e.target.value })} value={this.state.question} onKeyPress={this.handleKeyPress} className="write_msg" placeholder="Type your question"
                                       />
                                       <button className="msg_send_btn" type="submit"><i className="fa fa-paper-plane" aria-hidden="true" /></button>
                                    </Form>
                                 </div>
                              </div>
                              <div className="messages" id="messages">
                                 <div className="m-2 text-center">
                                    {this.state.showPrev ?
                                       <button className="previous-btn">Previous messages</button> : <div />}
                                 </div>
                                 {this.state.answer.length ?
                                    this.state.answer.map((data, i) => {
                                       return (
                                          <div key={i}>
                                             {
                                                data.from_userId === (user && user.id) ?
                                                   <div className="outgoing_msg">
                                                      <div className="sent_msg">
                                                         <span className="msg_status">{data.status == 0 ? 'Pending Approval' : data.status == 1 ? '' : 'Message Rejected'}</span>
                                                         <p>{data.reviews}</p>
                                                         <span className="time_date-sender">{moment.utc(data.created_at).local().format('hh:mm a | MMM DD, YYYY')}</span>

                                                      </div>
                                                   </div>
                                                   :
                                                   <div className="incoming_msg">
                                                      <div className="received_msg">
                                                         <span className="text-white">Message From <strong>{data.from_user.user_type == 1 ? 'Transporter' : 'Shipper'}</strong></span>
                                                         <div className="received_withd_msg">
                                                            <p>{data.reviews}</p>
                                                            <span className="time_date-receiver mb-3">{moment.utc(data.created_at).local().format('hh:mm a | MMM DD, YYYY')}</span></div>
                                                      </div>
                                                   </div>}
                                          </div>)
                                    }) : ''}
                              </div>

                              <small className="white-text font-weight-bold">
                                 Your question to shipper is now private and will not be made public. Do not discuss rates, fees or list contact information. Doing so will suspend your account.
                              </small>
                           </div>
                        </div>
                     </div>
                  </div>
               </ModalBody>
            </Modal>
         </div>
      )
   }
}

const mapStateToProps = state => {
   return {
      user: state.user && state.user.user
   }
}

export default connect(mapStateToProps)(AskQuestion)