/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

/* eslint eqeqeq: 0 */

import React, { Component } from 'react'
import { SAGA_ACTIONS } from '../../../config/config';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom'
import { Modal, ModalHeader, ModalBody, FormGroup, Label } from 'reactstrap';
import { reset } from 'redux-form';

class OTP extends Component {
   state = {
      OTP: '',
   }

   submitAllFormValues = (e) => {
      e.preventDefault()
      if (/^[0-9]*$/i.test(this.state.OTP)) {
         return new Promise((resolve, reject) => {
            let values = { ...this.props.registrationValues }

            let form = new FormData()
            let form_keys = Object.keys(values)
            let form_values = Object.values(values)

            for (let i = 0; i < form_keys.length; i++) {
               if (form_keys[i] == 'country_id' || form_keys[i] == 'state_id' || form_keys[i] == 'city_id') {
                  form.append(form_keys[i], form_values[i].value)
               }
               else if (form_keys[i] == 'zone_code') {
                  form.append('country_zone_code', form_values[i])
               }
               else {
                  form.append(form_keys[i], form_values[i])
               }
            }
 
            form.append('otp', this.state.OTP)

            this.props.dispatch({
               type: SAGA_ACTIONS.USER.REGISTER.REGISTER_USER,
               payload: form,
               callbackSuccess: res => {
                  toastr.success('Success', res.data.msg)
                  this.props.dispatch(reset('registrationForm'))
                  if(values.user_type == 1){
                     this.props.close(2)
                  }
                  else{
                     this.props.history.replace('/login')
                  }
                  resolve(true)
               },
               callbackError: err => {
                  toastr.error('Error', err.data.msg)
                  reject(false)
               }
            })
         })
      }
      else {
         toastr.error('Error', 'Please check the OTP')
      }
   }

   resendOTP = () => {
      const {registrationValues} = this.props
      let payload = {}
      if(registrationValues.otp_type == 1){
         payload['otp_type'] = 1;
         payload['phone']  = registrationValues.phone;
         payload['zone_code'] =  registrationValues.zone_code;
      }
      else{
         payload['otp_type'] = 2;
         payload['email'] = registrationValues.email
         payload['first_name'] = registrationValues.first_name
      }
      this.props.dispatch({
         type: SAGA_ACTIONS.USER.REGISTER.GET_OTP,
         payload,
         callbackSuccess: res => {
            toastr.success('Success', res.data.msg)
         },
         callbackError: err => {
            //otp error
            toastr.error('Eror', err.data.msg)
         }
      })
   }

   render() {
      return (
         <div>
            <Modal isOpen={true} centered>
               <ModalHeader className="text-white modal-cross" toggle={() => this.props.close(1)}>Signup</ModalHeader>
               <ModalBody>
                  <form onSubmit={this.submitAllFormValues}>
                     <FormGroup>
                        <Label>One time password (OTP)</Label>
                        <input type="text" className="form-control" onChange={(e) => this.setState({ OTP: e.target.value })} maxLength={6} />
                        <div className="resend"> <a href="#" onClick={this.resendOTP}><u>Resend OTP</u></a></div>
                     </FormGroup>
                     <div className="row">
                        <div className="col-12 text-center">
                           <button type="submit" className="btn btn-primary with-ico-right">Create Account </button>
                        </div>
                     </div>
                  </form>
               </ModalBody>
            </Modal>
         </div>
      )
   }
}

const mapStateToProps = (state) => {
   const registrationValues = state.user && state.user.registrationValues
   return {
      registrationValues
   }
}

export default connect(mapStateToProps)(withRouter(OTP))