/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React, { Component } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux'
import { SAGA_ACTIONS } from '../config/config'
import renderHTML from 'react-render-html'
import { toastr } from 'react-redux-toastr'

class InfoModal extends Component {
   state = {
      heading: '',
      content: '',
      isDisabled: false
   }

   componentDidMount() {
      this.props.dispatch({
         type: SAGA_ACTIONS.INFO.BLOCK_INFO,
         payload: { id: this.props.id },
         callbackSuccess: res => {
            this.setState({
               heading: res.data.data.heading,
               content: res.data.data.details,
               isDisabled: res.data.data.status == 0 ? true : false
            })
         },
         callbackError: err => {
            //toastr.error(err.data.msg)
         }
      })
   }

   render() {
      return (
         <Modal isOpen={this.props.isOpen && !this.state.isDisabled} className="text-white" centered>
            <ModalHeader toggle={this.props.close} className=" modal-cross">
               {this.state.heading || ''}
            </ModalHeader>
            <ModalBody>
               {renderHTML(this.state.content)}
            </ModalBody>
         </Modal>
      )
   }
}

export default connect()(InfoModal)