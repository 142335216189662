/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import React, { Component } from 'react'
import { CardBody, Card } from 'reactstrap'
import { connect } from 'react-redux'
import { SAGA_ACTIONS } from '../../../config/config'
import Pagination from 'react-js-pagination'

class MyFeedback extends Component {
   state = {
      feedbackList: [],
      page: 1,
      total: 0,
      per_page: 1,
      last_page: 1
   }

   componentDidMount() {
      this.getFeedback()
   }

   getFeedback = () => {
      this.props.dispatch({
         type: SAGA_ACTIONS.SHIPPER.CARRIER_REVIEW,
         payload: { page: this.state.page, carrier_id: this.props.userId },
         callbackSuccess: res => {
            this.setState({
               feedbackList: res.data.data.data,
               per_page: res.data.data.per_page,
               total: res.data.data.total,
               last_page: res.data.data.last_page
            })
         },
         callbackError: err => {
            this.setState({ feedbackList: [] })
         }
      })
   }

   handlePageChange = (page) => {
      this.setState({ page }, () => this.getFeedback())
   }

   render() {
      return (
         <div>
            <div className="log-bg" />
            <div className="box-log pt-3">
               <div className="step02 trans-box transbox-margin" style={{ minHeight: 0 }}>
                  {this.state.feedbackList.length ? this.state.feedbackList.map((item, i) => <Card key={i} className="mb-3">
                     <CardBody style={{ padding: '5px 5px 5px 10px' }} className="d-flex">
                        <img src="/images/avatar.png" style={{ width: '30px', height: '30px', marginRight: '15px' }} />
                        <h6 style={{ wordBreak: 'break-all' }}>{item.message}</h6>
                     </CardBody>
                  </Card>) : <h5 className="text-white">No Feedback</h5>}
               </div>
            </div>
            {this.state.last_page > 1 ? <div className="pagination mt-3">
               <Pagination
                  activePage={this.state.page}
                  itemsCountPerPage={this.state.per_page}
                  totalItemsCount={this.state.total}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
               />
            </div> : ''}
         </div>
      )
   }
}

const mapStateToProps = state => {
   return {
      userId: state.user && state.user.user.id
   }
}

export default connect(mapStateToProps)(MyFeedback)