/*********************************
 Author: Motherload 
 TradeMark:  Auto Up, LLC (d.b.a Motherload ™)
**********************************/

import { ACTIONS } from "../../config/config";
import moment from "moment";

const initialState = {
   isAuthenticated: false,
   token: null,
   user: null,
   registrationStep: 'step1',
   country: {},
   has_card: false,
   carrier_fees: 0,
};

const userReducer = (state = initialState, action) => {
   switch (action.type) {
      case ACTIONS.USER.LOGIN:
         return {
            ...state,
            isAuthenticated: true,
            token: action.payload.token,
         }
      case ACTIONS.USER.LOGOUT:
         return {
            ...state,
            isAuthenticated: false,
            token: null,
            user: null,
         }
      case ACTIONS.USER.ME:
         let isProfileComplete
         if (action.payload.user_type == 1 && action.payload.carrier_fees!=0) {
            /* if (!action.payload.media_size_document_url && !action.payload.mc) {
               isProfileComplete = false
            }
            else */ if(/*(action.payload.media_size_document_url || action.payload.mc) && */((moment(action.payload.plan_expire_on).diff(new Date(), 'days') < 0) && (!action.payload.card_status || !action.payload.plan_status))){
               isProfileComplete = false
            }
            /*else if((action.payload.media_size_document_url || action.payload.mc) && action.payload.card_status && (action.payload.plan_status == 0)){
               isProfileComplete = false
            }*/
            else{
               isProfileComplete = true
            } 
         }
         else{
            if(!action.payload.card_status){
               isProfileComplete = false
            }
            else{
               isProfileComplete = true
            }
         }

         return {
            ...state,
            user: action.payload,
            has_card: action.payload.card_status ? true : false,
            isProfileComplete,
            isAuthenticated: true
         }
      case ACTIONS.USER.REGISTER.REGISTER_USER:
         let temp = { ...state.registrationValues, ...action.payload }
         return {
            ...state,
            registrationValues: temp
         }
      case ACTIONS.USER.REGISTER.STEP:
         return {
            ...state, registrationStep: action.payload
         }
      case ACTIONS.USER.REGISTER.SET_COUNTRY:
         return {
            ...state,
            country: action.payload
         }
      case ACTIONS.USER.REGISTER.SET_STATE:
         return {
            ...state,
            state: action.payload
         }
      case ACTIONS.USER.REGISTER.SET_CITY:
         return {
            ...state,
            city: action.payload
         }
      case ACTIONS.USER.HAS_CARD:
         return {
            ...state,
            has_card: action.payload,
            isProfileComplete: action.payload
         }
      default:
         return state;
   }
}
export default userReducer;
